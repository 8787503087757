import React from 'react';
import { Controller, Control, FieldPath, RegisterOptions } from 'react-hook-form';
import styled, { css, CSSProp } from 'styled-components';

interface CustomInputProps<TFieldValues> {
  name: FieldPath<TFieldValues>; // Ensure 'name' is a key of the form's fields
  label?: string; // Make label optional
  placeholder: string;
  type?: 'text' | 'number' | 'email' | 'password';
  control: Control<TFieldValues>; // Ensure the 'control' matches the form's fields
  rules?: RegisterOptions<TFieldValues>;
  onError?:any;
  inputStyle?: CSSProp; // Allows styling for the input field
  overRideStyle?: CSSProp; // Allows styling for the input field
  labelStyle?: CSSProp; // Allows styling for the label
  errorStyle?: CSSProp; // Allows styling for the error message
  disabled?:boolean;
  min?:number
  value?:any, 
  className?: string
}

const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const InputLabel = styled.label<{ labelStyle?: CSSProp }>`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 4px;
  padding-left:.3rem;
  color: #212122;
  ${({ labelStyle }) => labelStyle};
`;

const InputField = styled.input<{hasError?:boolean;inputStyle?:CSSProp;overRideStyle?:CSSProp,disabled?:boolean}>`
  ${({ overRideStyle,inputStyle, hasError,disabled }) =>
    overRideStyle
      ? css`
          ${overRideStyle}
        `
      : css`
          padding: 0.7rem;
          border: ${hasError ? '1px solid #ff4d4f' : '1px solid #d9d9d9'} !important;
          border-radius: 4px;
          font-size: 14px;
          color: #333;
          background-color: ${disabled ? "#f5f5f5" : "#fff"};
          margin-bottom: 12px;
          min-height: 38px;
         
          &:focus {
            border:none !important;
            outline: ${hasError ? '1px solid #ff4d4f' : '1px solid #6F57E9'} !important;
          };
          ${inputStyle || ''} ;
        `};
`;


const ErrorMessage = styled.span<{ errorStyle?: CSSProp }>`
    height:auto;
    min-height:1rem;
    position: relative;
    display:inline-block;
    font-size: 12px;
    color: #ff4d4f;
    margin-bottom: 10px;
    margin-top:-5px;
    ${({ errorStyle }) => errorStyle};
`;

const CustomInputField = <TFieldValues extends Record<string, any>>({
  name,
  label,
  placeholder,
  type = 'text',
  control,
  rules,
  onError,
  inputStyle,
  overRideStyle,
  labelStyle,
  errorStyle,  
  disabled,
  min,
  value, 
  className
}: CustomInputProps<TFieldValues>) => {
  const isRequired = rules?.required !== undefined; // Check if the field is required
  return (
    <InputContainer>
      {label && ( // Render label only if it has a value
        <InputLabel htmlFor={String(name)} labelStyle={labelStyle}>
          {label} {isRequired && <span style={{ color: '#ff4d4f' }}>*</span>}
        </InputLabel>
      )}
     <Controller
  name={name}
  control={control}
  rules={rules}
  render={({ field, fieldState: { error } }) => {
    if (onError) onError({ error: !!error, value: error });
    return (
      <>
        <InputField
          id={String(name)}
          placeholder={placeholder}
          type={type}
          hasError={!!error}
          inputStyle={inputStyle}
          overRideStyle={overRideStyle}
          disabled={disabled || false}
          min={min}
          value={value !== undefined ? value : field.value} // Respect external value
          onChange={(e) => {
            field.onChange(e); // Notify react-hook-form of the change
          }}
          className={className}
        />
        {(error && error.message) && <ErrorMessage errorStyle={errorStyle}>{error && error.message}</ErrorMessage>}
      </>
        )}}
      />
    </InputContainer>
  );
};

export default CustomInputField;