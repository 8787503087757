import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import Summary from "./summaryContainer";
import DiscountComponent from "./CreateLinkDiscountComponent";
import UsageLimit from "./UsageLimit";
import Attribution from "./CreateLinkAttributionComponent";
import Script from "./CreateLinkScript";
import SelectProdutsModal from "./SelectProductsModal";
import ProductSelection from "./SelectProductForProductPreviewLink";
import SelectedProductsList from "./SelectedProductsItems";
import ProductPreviewModal from "./ProductPreviewModal";
import { createLink, fetchLinkDetailById } from "./configuration/script";
import { useMutation } from "react-query";
import { DataContext } from "context/DataProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ReturnPolicyComponent from "./ReturnPolicyInput";
import CustomInputField from "components/UIElements/CustomInputField";
import { notification } from "antd";
import Select, { GroupBase } from "react-select";
import { BackButtonIcon, TickIcon } from "images/icons";
import CustomSelect from "components/UIElements/customSelect";

const MainContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.6rem 0rem 0rem 0.6rem;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(170, 170, 170, 0.16),
    0px 10px 20px 0px rgba(194, 194, 194, 0.16);
`;

export const mainInputComponentStyle = {
  width: "32.4375rem",
  height: "3rem",
  flexShrink: 0,
  borderRadius: "0.5rem",
  border: "1px solid #D3D3D3",
  background: "#FFF",
};

const FormContainer = styled.div`
  display: flex;
  padding-top: 1.25rem;
  gap: 2.5rem;

  @media (max-width: 991px) {
    flex-wrap: wrap;    
}


`;

const LeftSection = styled.div`
  width: 100%;
  flex: 3;
`;

const CustomOption = (props: any) => {
  const { data, isSelected, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        width: "32.4375rem",
        height: "2.25rem",
        color: "#212121",
        fontSize: "0.75rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "112.4%" /* 0.843rem */,
      }}
      className={`flex items-center justify-between py-2 px-4 cursor-pointer hover:bg-selectHover hover:text-purpleText`}
    >
      <span style={{ flexGrow: 1 }}>{data.label}</span>
      {isSelected && <div>{TickIcon("#6F57E9")}</div>}
    </div>
  );
};

const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
      width: "32.4375rem",
      height: "3rem",
      flexShrink: 0,
      borderRadius: "0.5rem",
      background: "#FFF",
      border: state.isFocused ? "1px solid #6F57E9" : "1px solid #D3D3D3",
      boxShadow: "none",
      color: "#212121",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "112.4%" /* 0.843rem */,
      marginBottom: "10px",
      outline: "none",
      "&:hover": {
        borderColor: "#6F57E9",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#bfbfbf",
    fontSize: "14px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#333",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#d9d9d9",
    "&:hover": {
      color: "#6F57E9",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "rgba(111, 87, 233, 0.15)" // Background color when hovered/focused
      : "#FFF", // Default background color
    color: state.isFocused ? "#6F57E9" : "#000", // Text color
    padding: 10,
    cursor: "pointer",
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "32.4375rem",
    marginTop: "-20px", // Space between control and menu
    marginBottom: "0",
    zIndex: 9999, // Ensure it appears above other elements
  }),
};
const RightSection = styled.div`
  flex: 2;
  // display: flex;
  // flex-direction: column;
`;

const SectionCard = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  // width: 42.5625rem;
  width: 100%;
  height: 3rem;
  // align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  color: #212122;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.75rem; /* 100% */
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: rgba(183, 181, 188, 0.2);
  padding: 0.65rem 1.1rem;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  display: inline-flex;
  height: 1.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: ${(props) =>
    props.disabled ? "#D2D2D2" : "#6F57E9"}; /* Grey background when disabled */
  color: ${(props) =>
    props.disabled ? "#666666" : "#fff"}; /* Light grey text when disabled */
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; /* Show not-allowed cursor when disabled */
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "#D2D2D2"
        : "#5A45C9"}; /* No hover effect when disabled */
  }

  &:focus {
    background-color: ${(props) =>
      props.disabled
        ? "#D2D2D2"
        : "#5A45C9"}; /* No focus effect when disabled */
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  // width: 77.0625rem;
  height: 3.0625rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 2.5rem 1.25rem 0.675rem 1.25rem;
  background: rgba(81, 214, 191, 0.18);
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 200% */
`;

const ProductSelectionContainer = styled.div`
  display: flex;
  // width: 42.5625rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;
  margin-bottom: 20px;
`;

const SelectProductTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 112.4%; /* 0.843rem */
`;

const Option = styled.option`
  padding-left: 8px;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  value: ${(props) => props.value};
`;

const Switch = styled.input.attrs({ type: "checkbox" })`
    width: 2.5625rem;
    height: 1.1875rem;
    flex-shrink: 0;
    background-color: ${(props) => (props.checked ? "#12B87B" : "#A5A5A5")};
    border-radius: 20px;
    position: relative;
    appearance: none;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
    &:focus{
      border:none !important;
    }

  &::before {
    content: "";
    position: absolute;
    top: 2.5px;
    left: ${(props) => (props.checked ? "22px" : "3px")};
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;

const validationConfig = [
  {
    label: "Link Details",
    successRules: [(formValues: FormValues) => !!formValues.title],
    success: (formValues: FormValues) => formValues.title,
    error: "Link title is required for internal use",
  },
  {
    label: "Cart Details",
    successRules: [
      (formValues: FormValues) =>
        formValues.selectedProducts && formValues.selectedProducts.length > 0,
    ],
    success: (formValues: FormValues) =>
      `${formValues.selectedProducts.length} product(s) added`,
    error: "You need to select at least 1 product to add to the cart",
  },
];

export type utmCustomAttributes = {
  id: string;
  paramName: string;
  paramValue: string;
};

export type Variant = Partial<{
  label: string;
  price: number;
  qty: number;
  value: number;
  productId: number | string;
  sku: string;
  compareAtPrice: number;
  imgUrl: string;
  name: string;
}>;

export type Product = {
  id: string;
  name: string;
  sku?: string;
  price?: number;
  imgUrl?: string;
  qty?: number;
  type?: string;
  variants?: Variant[];
  compareAtPrice?: number;
  showDescription?: boolean;
  productDescription?: string | null;
  label?: string;
};

// Define Discount and DiscountConfig types
export type Discount = {
  id: string;
  discountCode: string;
  discountValue: string;
  discountType: string;
};

export type DiscountConfig = {
  automatic: Discount;
  couponEnabled: boolean;
  nonAutomatic: Discount[];
};
export type ReturnPolicyConfig = {
  value: string;
  applyToPreviousLinks: boolean;
};

export type UsageConfig = {
  usageLimit: Number;
};

export type AttributionConfig = {
  utmId: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerms: string;
  utmContent: string;
  customAttributeEnabled: boolean;
  customAttributes: utmCustomAttributes[];
};
export type ScriptConfig = {
  content: string;
};

// Define the type for your options
type OptionType = {
  value: string;
  label: string;
};

// Example options
const options: OptionType[] = [
  { value: "CHECKOUT_LINK", label: "Quick cart link" },
  { value: "PRODUCT_PAGE", label: "Product preview link" },
];

type ToggleValues = {
  returnPolicy: {
    status: boolean;
    config: ReturnPolicyConfig;
  };
  discount: {
    status: boolean;
    config: DiscountConfig;
  };
  usage: {
    status: boolean;
    config: UsageConfig;
  };

  attribution: {
    status: boolean;
    config: AttributionConfig;
  };
  script: {
    status: boolean;
    config: ScriptConfig;
  };
};

export type FormValues = ToggleValues & {
  linkType: string;
  title: string;
  selectedProducts: Product[];
};

const CreatePurchaseLinkContainer: React.FC = () => {
  const { sellerId, channel, sellerDomain: storeUrl } = useContext(DataContext);
  const [formDefaultValue, setFormDefaultValues] = useState({
    linkType: "CHECKOUT_LINK",
    title: "",
    returnPolicy: {
      status: false,
      config: {
        value: "",
        applyToPreviousLinks: false,
      },
    },
    discount: {
      status: false,
      config: {
        automatic: {
          id: "",
          discountCode: "",
          discountValue: "",
          discountType: "%",
        },
        couponEnabled: false,
        nonAutomatic: [],
      },
    },
    selectedProducts: [],
    usage: { status: false, config: {} },
    attribution: {
      status: false,
      config: {
        utmId: "",
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmTerms: "",
        utmContent: "",
        customAttributes: [],
      },
    },
    script: { status: false, config: {} },
  });
  const { id: editLinkId, hasItem } = useParams();
  const [isActive, setIsActive] = useState(true);

  useQuery<any>(["linkData", { id: editLinkId }], fetchLinkDetailById, {
    cacheTime: 0,
    staleTime: 0,
    onError: (error: any) => {
      error?.message && notification?.error({ message: error?.message });
    },
    onSuccess: (data) => {
      console.log("data is", data?.active);
      setIsActive(data?.active)
      setFormDefaultValues((prop) => {
        reset({ ...prop, ...data });

        return { ...prop, ...data };
      });
    },
    enabled: !!editLinkId, // Query only runs if editLinkId is available
  });
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue,
    setFocus,
    formState: { isDirty, errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValue,
  });

  const fieldRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  
  const scrollToFirstError = () => {
    let firstErrorField:any = Object.keys(errors)?.[0];
  
    if(firstErrorField.includes('usage')){
      firstErrorField = 'usage.config.usageLimit';
    }else if(firstErrorField.includes('attribution')){
      firstErrorField = 'attribution.config.utmId';
    } else if(firstErrorField.includes('discount')){
      firstErrorField = 'discount.config.automatic.discountCode';
    }

    if (firstErrorField) {
      const errorElement = document.getElementById(firstErrorField);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setFocus(firstErrorField);
    }
  };
  const selectedProducts = watch("selectedProducts");
  console.log(selectedProducts, 'checking selected product')


  const [toggleFields, setToggleFields] = useState<(keyof ToggleValues)[]>([
    "discount",
    "usage",
    "attribution",
  ]);

  const linkType = watch("linkType");
  const navigate = useNavigate();

  useEffect(() => {
    if (!editLinkId) {
      const linkPreference = localStorage?.getItem("linkPreference") || null;
      if (linkPreference) {
        setValue("linkType", linkPreference);
      }
    }
  }, []);

  useEffect(() => {
    const temp = [...toggleFields];

    // //add return policy
    // if (linkType == "PRODUCT_PAGE") {
    //   if (!temp?.includes("returnPolicy")) {
    //     temp?.unshift("returnPolicy")
    //     if(!editLinkId) setValue("returnPolicy", {
    //       status: false,
    //       config: {
    //         value: "",
    //         applyToPreviousLinks: false
    //       }
    //     })
    //   }
    // } else if (linkType == "CHECKOUT_LINK") {
    //   if (temp?.includes("returnPolicy")) {
    //     temp?.shift()
    //     setValue("returnPolicy", {
    //       status: false,
    //       config: {
    //         value: "",
    //         applyToPreviousLinks: false
    //       }
    //     })
    //   }
    // }
    // setToggleFields(temp)
  }, [linkType]);

  const watchedFields = toggleFields?.reduce((acc: any, field) => {
    acc[field] = watch(field);
    return acc;
  }, {} as ToggleValues);
  const formValues = watch();

  const [createdLink, setCreatedLink] = useState("");
  const [showLinkCreatedModal, setShowLinkCreatedModal] = useState(false);
  const [openProductSelectionSection, setOpenProductSelectionSection] =
    useState(false); 

  const renderDetailsCard = (option: keyof ToggleValues) => {
    switch (option) {
      // case "returnPolicy":
      //   return <ReturnPolicyComponent
      //     control={control}
      //     name="returnPolicy.config"
      //   />
      case "discount":
        return (
          <DiscountComponent
            data={getValues("discount")?.config}
            control={control}
          />
        );
      case "usage":
        return (
          <UsageLimit data={getValues("usage")?.config} control={control} />
        );
      case "attribution":
        return (
          <Attribution
            data={getValues("attribution")?.config}
            control={control}
            watch={watch}
          />
        );
      // case "script":
      //   return <Script data={getValues("script")?.config} control={control} />;

      default:
        return null;
    }
  };

  const { mutate, isLoading } = useMutation(createLink, {
    onSuccess: (data) => {
      setCreatedLink(data?.end_point); // Use the created link's endpoint
      if (data?.end_point) setShowLinkCreatedModal(true); // Show modal if endpoint exists
    },
    onError: (error: any) => {
      error?.message && notification?.error({ message: error?.message });
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // Validate rules
    const failedRules = validationConfig?.filter(
      (config) => !config?.successRules?.every((rule) => rule(data))
    );

    // If any rules fail, log the errors and stop the API call
    if (failedRules?.length > 0) {
      failedRules?.forEach((rule) =>
        notification?.error({ message: rule?.error })
      );
      return;
    }

    // If all rules pass, proceed with the API call
    let _tmp = { ...data, domain: storeUrl };
    if (editLinkId) {
      mutate({ ..._tmp, id: editLinkId, active: isActive});
    } else {
      mutate({ ..._tmp });
    }
  };

  return (
    <MainContainer>
      <div style={{ display: "flex" }}>
        {!!(editLinkId || (hasItem == "1")) && (
          <div
            className="font-bold text-xl bg-white rounded-lg"
            style={{ padding: "5px", cursor: "pointer" }}
            onClick={() => navigate("/purchaseLink/links")}
          >
            {BackButtonIcon()}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="font-bold text-xl bg-white rounded-lg">
            {editLinkId ? ` Update Link` : "Create new link"}
          </div>
          {editLinkId && 
          <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
            <span style={{ fontWeight: 600 }}>Active</span>
            <Switch
              checked={isActive}
              onChange={(e) => setIsActive(e?.target?.checked)}
            />
          </div>
          }
        </div>
      </div>
      <FormContainer>
        <LeftSection>
          <SectionCard>
            <SectionTitle>Link Details</SectionTitle>
            <div
              style={{
                padding: "20px 20px 0px 20px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <form>
                <div
                  style={{
                    marginBottom: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ marginBottom: "5px" }}>
                    <label>Link Type</label>
                    <span style={{ color: "#ff4d4f", marginLeft: "5px" }}>
                      *
                    </span>
                  </div>
                  <Controller
                    name="linkType"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        defaultValue={linkType}
                        isSearchable={false}
                        options={options}
                        onChange={(selectedOption: any) => {
                          if (linkType != selectedOption?.value)
                            setValue("selectedProducts", []);
                          return field.onChange(selectedOption?.value);
                        }}
                        style={customSelectStyles}
                        placeholder="Select a link type"
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CustomInputField
                    name="title"
                    label="Title"
                    placeholder="Enter title"
                    control={control}
                    rules={{
                      required: "Title is required",
                      minLength: {
                        value: 5,
                        message: "Title must be at least 5 characters",
                      },
                      maxLength: {
                        value: 45,
                        message: "Title cannot be more than 45 characters",
                      },
                    }}
                    inputStyle={{
                      ...mainInputComponentStyle,
                      marginBottom: "5px",
                    }}
                    errorStyle={{
                      position: "relative",
                      bottom: "0px",
                      marginTop: "0px",
                    }}
                  />
                  <div>
                    <small style={{ color: "#888" }}>
                      For internal use only
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </SectionCard>

          <SectionCard>
            <SectionTitle>Cart Details</SectionTitle>
            <div style={{ backgroundColor: "#fff", borderRadius: "8px" }}>
              <ProductSelectionContainer>
                <SelectProductTitleContainer style={{padding: 20}}>
                  <span>Select the product(s) you want added to the cart</span>
                  <StyledButton
                    onClick={() => {
                      setOpenProductSelectionSection(true);
                    }}
                  >
                    Select Product
                  </StyledButton>
                </SelectProductTitleContainer>
                {openProductSelectionSection && (
                  <SelectProdutsModal
                    isModalOpen={openProductSelectionSection}
                    toggleModal={setOpenProductSelectionSection}
                    control={control}
                    name={"selectedProducts"}
                    linkType={getValues("linkType")}
                  />
                )}
                {getValues("linkType") === "CHECKOUT_LINK" &&
                  selectedProducts?.length > 0 && (
                    <SelectedProductsList
                      control={control}
                      name={"selectedProducts"}
                      items={selectedProducts}
                    />
                  )}
                {getValues("linkType") === "PRODUCT_PAGE" &&
                  selectedProducts?.length > 0 && (
                    <ProductSelection
                      control={control}
                      name={"selectedProducts"}
                      items={selectedProducts}
                      linkType = "PRODUCT_PAGE"
                    />
                  )}
              </ProductSelectionContainer>

              {toggleFields?.map((option) => (
                <React.Fragment key={option}>
                  <SectionTitle>
                    <span>
                      {option?.charAt(0)?.toUpperCase() + option?.slice(1)}
                    </span>
                    <Controller
                      name={`${option}.status`}
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field?.value}
                          onChange={(e) => field?.onChange(e?.target?.checked)}
                        />
                      )}
                    />
                  </SectionTitle>
                  {watchedFields[option]?.status && renderDetailsCard(option)}
                </React.Fragment>
              ))}
            </div>
          </SectionCard>
        </LeftSection>

        <RightSection>
          <Summary formValues={formValues} />
        </RightSection>
      </FormContainer>

      <ButtonContainer>
        <StyledButton 
          type="button" 
          onClick={handleSubmit(onSubmit, scrollToFirstError)}
          disabled = {isLoading}
        >
          {editLinkId ? "Update" : "Create"}
        </StyledButton>
        <StyledButton
          disabled={!isDirty}
          style={{ background: "#FFF" }}
          type="button"
          onClick={() => reset(formDefaultValue)}
        >
          <span style={{ color: "#4C535F" }}>Reset</span>
        </StyledButton>
      </ButtonContainer>
      <Footer>
        <span>
          Looking for assistance or best practices? Reach out to us here—we’re
          happy to help -{" "}
          <a
            href="#"
            style={{
              color: "#6F57E9",
              fontWeight: "700",
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationSkipInk: "none",
              textDecorationThickness: "auto",
              textUnderlineOffset: "auto",
              textUnderlinePosition: "from-font",
            }}
          >
            Contact Us
          </a>
        </span>
      </Footer>
      <div>
        <ProductPreviewModal
          isOpen={showLinkCreatedModal}
          link={createdLink}
          onClose={() => {
            navigate("/purchaseLink/links");
            setShowLinkCreatedModal(false);
          }}
          isActive = {isActive}
        />
      </div>
    </MainContainer>
  );
};

export default CreatePurchaseLinkContainer;
