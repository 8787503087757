import React, { useEffect, useState } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { Discount, DiscountConfig } from '.';
import CustomInputField from 'components/UIElements/CustomInputField';
import Select, { GroupBase } from 'react-select'
import CustomSelect from 'components/UIElements/customSelect';
import { isError } from 'react-query';


const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  font-family: Arial, sans-serif;
`;

const SectionTitle = styled.div`
  margin-bottom: .09rem;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0125rem;
`;

const InfoIcon = styled.span`
  background-color: #ccc;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  color: white;
  cursor: pointer;
`;

const FieldContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const DiscountTypeContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 8px;
  width: 245px;
  margin-bottom: 0px;
`;

const Button = styled.button`
  background-color:#D3D3D3;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  font-weight: 800;
  line-height: 25px;
  font-size: 18px;
  justify-content: center;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top: 4px;
  align-self: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
`;

const ToggleLabel = styled.label`
  font-weight: bold;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  width: 41px;
  height: 19px;
  flex-shrink: 0;
  background-color: ${(props) => (props.checked ? '#12b87b' : '#a5a5a5')};
  border-radius: 20px;
  position: relative;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 1.5px;
    left: ${(props) => (props.checked ? '22px' : '1.5px')};
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;

const DiscountComponent = ({ data, control }: { data: DiscountConfig; control: any }) => {
  const options = [{ label: "%", value: "%" }, { label: "₹", value: "₹" }]
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'discount.config.nonAutomatic',
    keyName: 'id' // Targets nonAutomatic within discount
  });
  const [discountTypeError, setDiscounTypeError] = useState(null)
  const [errorKey, setErrorKey] = useState<String[]>([])

  const inputFieldStyle = (key: string) => {
    let isError = discountTypeError
    if (!errorKey.includes(key)) {
      isError = false
    }
    return css`
      width: 15rem;
      height: 2.6rem;
      flex-shrink: 0;
      flex-shrink: 0;
      padding:10px;
      padding-right:3.87rem;
      border-width:1px 1px 1px 1px !important;
      border-style: solid !important;
      border-color: ${isError ? '#ff4d4f' : '#d9d9d9'} !important;
      border-radius: 8px;
      font-size: 14px;
      color: #333;   
        &:focus {
          border:none !important;
          outline-width: ${isError ? '1px 1px 1px 1px' : '1px 1px 1px 1px'} !important;
          outline-style: solid !important;
          outline-color: ${isError ? '#ff4d4f' : '#6F57E9'} !important;
        };   
   `
  }


  const selectStyle = {
    container: (provided: any) => ({
      ...provided,
      width: "6rem",
      position: "absolute",
      right: "-1.4rem",
      top: "1.75rem",

    }),
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#FFF",
      borderColor: state.isFocused ? "#6F57E9" : "#D3D3D3",
      borderStyle: "solid",
      boxShadow: "none",
      fontSize: "0.75rem",
      fontWeight: "400",
      width: "4rem",
      position: "absolute",
      borderWidth: "0px 0px 0px 1px",
      borderRadius: "0px",
      "&:hover": {
        borderColor: "#6F57E9",
      },

    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#bfbfbf",
      fontSize: "14px",

    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#333",

    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#d9d9d9",
      "&:hover": {
        color: "#6F57E9",
      },
      width: "30px",

    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "rgba(111, 87, 233, 0.15)" : "#FFF",
      color: state.isFocused ? "#6F57E9" : "#000",
      cursor: "pointer",

    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      width: "4.2rem",
      position: "absolute",
      top: "1.9rem",
      height: "5rem"

    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: "10px 0", // Add padding at the top and bottom of the options
      maxHeight: "200px", // Set a max height for scrollable options
      overflowY: "auto", // Enable vertical scrolling if content overflows
      borderRadius: "8px", // Round the edges of the menu list
    }),
  }




  const selectContainerStyle = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    right: 0,
    top: 0,
  }

  useEffect(() => {
    if (data.couponEnabled && fields.length === 0) {
      append({
        id: '',
        discountCode: '',
        discountValue: '',
        discountType: '%',
      });
    }
  }, [data.couponEnabled]);

  return (
    <Container>
      {/* Automatic discount section */}
      <SectionTitle>
        Auto-apply discount <InfoIcon>i</InfoIcon>
      </SectionTitle>
      <FieldContainer >
        <CustomInputField
          name="discount.config.automatic.discountCode"
          label="Discount name"
          placeholder="Enter discount code"
          control={control}
          inputStyle={{
            padding: '10px',
            borderRadius: '8px',
            width: "11.3125rem",
            height: "2.6rem",
            marginBottom: '0px',
          }}
          errorStyle={{
            bottom: '-10px',
            width: '200px',
          }}
          rules={{
            required: data?.automatic?.discountValue ? 'Discount name is required' : false,
          }}
        />
        <DiscountTypeContainer>
          <CustomInputField
            name="discount.config.automatic.discountValue"
            label="Discount Type"
            placeholder="Enter discount value"
            type="number"
            min={0}
            className='removeToggle'
            control={control}
            overRideStyle={inputFieldStyle(`discount.config.automatic.discountValue`)}
            onError={(error: any) => {
              const temp = [...errorKey]
              if (error.value) {
                if (!temp.includes(`discount.config.automatic.discountValue`)) {
                  temp.push(`discount.config.automatic.discountValue`)
                setErrorKey(temp); 
                }
                setDiscounTypeError(error.value)
              }else {
                if (temp.includes(`discount.config.automatic.discountValue`)) setErrorKey(temp.filter(item => item !== `discount.config.automatic.discountValue`));
              }
            }}
            errorStyle={{
              bottom: '-10px',
              width: "11.3125rem",
            }}
            rules={{
              required: data?.automatic?.discountValue ? 'Discount name is required' : false,
              validate: (value) => {
                if (data?.automatic?.discountCode) {
                  if (value <= 0) {
                    return 'Invalid value';
                  }
                  if (!Number.isInteger(Number(value))) {
                    return 'Invalid value';
                  }
                }
                return true; // Validation passed
              }
            }}
          />
          <Controller
            name="discount.config.automatic.discountType"
            control={control}
            defaultValue={data?.automatic?.discountType || "%"}
            render={({ field }) => (

              <CustomSelect  {...field} defaultValue={field.value ? field.value : "%"} isSearchable={false} options={options} onChange={(selectedOption: any) => field.onChange(selectedOption.value)} style={selectStyle} placeholder='' selectContainerStyle={selectContainerStyle} />

            )}
          />
        </DiscountTypeContainer>
      </FieldContainer>

      {/* Toggle for enabling/disabling non-automatic discount section */}
      <ToggleContainer>
        <ToggleLabel>Enable additional discount codes</ToggleLabel>
        <Controller
          name="discount.config.couponEnabled"
          control={control}
          defaultValue={data.couponEnabled}
          render={({ field }) => (
            <Switch
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <InfoIcon>i</InfoIcon>
      </ToggleContainer>

      {/* Non-Automatic Discounts Section */}
      {data.couponEnabled && (
        <div style={{ display: 'flex' }}>
          <div>
            {fields.map((item: Discount, index) => (
              <FieldContainer key={item?.id}>
                <CustomInputField
                  name={`discount.config.nonAutomatic[${index}].discountCode`}
                  label="Discount name"
                  placeholder="Enter discount code"
                  control={control}
                  inputStyle={{
                    padding: '10px',
                    borderRadius: '8px',
                    width: "11.3125rem",
                    height: "2.6rem",
                    marginBottom: '0px',
                  }}
                  errorStyle={{
                    bottom: '-10px',
                    width: '200px',
                  }}
                  rules={{
                    required: 'Discount name is required',
                  }}
                />
                <DiscountTypeContainer>
                  <CustomInputField
                    name={`discount.config.nonAutomatic[${index}].discountValue`}
                    label="Discount Type"
                    className='removeToggle'
                    placeholder="Enter discount value"
                    control={control}
                    type="number"
                    min={0}
                    overRideStyle={inputFieldStyle(`discount.config.nonAutomatic[${index}].discountValue`)}
                    onError={(error: any) => {
                      const temp = [...errorKey]
                      if (error.value) {
                        if (!temp.includes(`discount.config.nonAutomatic[${index}].discountValue`)) {
                          temp.push(`discount.config.nonAutomatic[${index}].discountValue`)
                          setErrorKey(temp);
                        }
                        setDiscounTypeError(error.value)
                      }else {  
                        if (temp.includes(`discount.config.nonAutomatic[${index}].discountValue`)) setErrorKey(temp.filter(item => item !== `discount.config.nonAutomatic[${index}].discountValue`));
                      }
                    }}
                    errorStyle={{
                      bottom: '-10px',
                      width: "11.3125rem",
                    }}
                    rules={{
                      required: 'Discount Type is required',
                      validate: (value) => {
                        if (value <= 0) {
                          return 'Invalid value';
                        }
                        if (!Number.isInteger(Number(value))) {
                          return 'Invalid value';
                        }
                        return true; // Validation passed
                      }
                    }}
                  />
                  <Controller
                    name={`discount.config.nonAutomatic[${index}].discountType`}
                    control={control}
                    // defaultValue={data?.automatic?.discountType || "%"}
                    defaultValue={data?.automatic?.discountType || "%"}
                    render={({ field }) => (
                      <>
                      {console.log(field, 'adkfjakfajfakfbkabfabksf')}
                      <CustomSelect 
                        {...field} 
                        defaultValue={field.value || "%"} 
                        isSearchable={false} 
                        options={options} 
                        onChange={(selectedOption: any) => field.onChange(selectedOption.value)} 
                        style={selectStyle} 
                        selectContainerStyle={selectContainerStyle} 
                        placeholder='' 
                      />
                      </>

                    )}
                  />
                </DiscountTypeContainer>
                
                {/* {(index != fields.length - 1) ?  */}
                {/* {!index === 0  ? */}
                {index !== 0 ? (
                  <Button onClick={() => remove(index)}  disabled={fields.length === 0} >
                    -   
                  </Button>
                ) : null}

                {index === 0 ? (
                  <Button
                    onClick={() =>
                      append({
                        id: '',
                        discountCode: '',
                        discountValue: '',
                        discountType: '',
                      })
                    }
                  >
                    +
                  </Button>
                ) : null}

              </FieldContainer>
            ))}
          </div>
          <div
            style={{
              alignSelf: 'flex-end',
              marginLeft: '20px',
              // marginBottom: '3.8rem',
            }}
          >

          </div>
        </div>
      )}
    </Container>
  );
};

export default DiscountComponent;