import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { getGeneralDetails, updateSellerCheckoutConfig } from "components/Settings/GeneralSettings/script";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notification } from "antd";

// Define the ShippingConfig type
export type ShippingConfig = {
  standard_shipment_charges: number;
  serviceability_hide_edd: boolean;
  default_from_pincode: number;
  show_return_policy_text: boolean;
  return_policy_text: string;
};

// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Label = styled.label`
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const CheckboxLabel = styled.span`
  margin-left: 8px;
  font-weight: normal;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 18px;
  height: 18px;
  accent-color: #6f57e9; /* Checkbox color */
`;

const SubmitButton = styled.button`
  background-color: #6f57e9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #5a45c9;
  }
`;

const Shipping = ({setIsDrawerOpen}:any) => {
  // Watch the `show_return_policy_text` value
  const { data:configData, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ShippingConfig>({
    defaultValues: {
      standard_shipment_charges: 0,
      serviceability_hide_edd: false,
      default_from_pincode: 0,
      show_return_policy_text: false,
      return_policy_text: "",
    },
  });

  const show_return_policy_text = watch("show_return_policy_text");

  useEffect(()=>{
    if(configData){
    setValue("standard_shipment_charges", configData?.standard_shipment_charges || 0)
    setValue("default_from_pincode", configData?.default_from_pincode || 0)
    setValue("serviceability_hide_edd", configData?.serviceability_hide_edd ||false)
    setValue("return_policy_text", configData?.return_policy_text || "")
    setValue("show_return_policy_text", configData?.show_return_policy_text || false )
    }
  },[configData])
  const queryClient = useQueryClient();
  const [savingSettings, setSavingSettings] = useState(false)
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!
      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      setSavingSettings(false)
      // An error happened!
      setIsDrawerOpen(false)
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      queryClient.invalidateQueries('generalSettings');
      setSavingSettings(false)
      setIsDrawerOpen(false);
      // navigate('/settings/shipping');
    },
  });


  const onSubmit: SubmitHandler<ShippingConfig> = (data) => {
    try{
    setSavingSettings(true)
    mutation.mutate(data);
    setIsDrawerOpen(false)
    }catch(e){}
    finally{
      setIsDrawerOpen(false);
      // props.onClose()
      setSavingSettings(false);
    }
  };

  return (
    <Container>
      {/* Title */}
      <Title>Shipping Setting</Title>

      {/* Form */}
      <form 
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} 
        >
        {/* Flat Charges Input */}
        <Label htmlFor="standard_shipment_charges">Flat charges *</Label>
        <Controller
          name="standard_shipment_charges"
          control={control}
          rules={{ required: "Flat charges are required" }}
          render={({ field }) => (
            <Input
              id="standard_shipment_charges"
              placeholder="Enter amount"
              className="removeToggle"
              type="number"
              {...field}
              style={{
                WebkitAppearance: "none", // Chrome, Safari, Edge
                MozAppearance: "textfield", // Firefox
              }}
            />
          )}
        />
        {errors.standard_shipment_charges && <p>{errors.standard_shipment_charges.message}</p>}

        {/* Show EDD Checkbox */}
        <CheckboxContainer>
          <Controller
            name="serviceability_hide_edd"
            control={control}
            render={({ field }) => (
              <Checkbox
                id="serviceability_hide_edd"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
          <CheckboxLabel>Show EDD (Estimated delivery date)</CheckboxLabel>
        </CheckboxContainer>

        {/* Default Warehouse Pincode Input */}
        <Label htmlFor="default_from_pincode">
          Enter default warehouse pincode *
        </Label>
        <Controller
          name="default_from_pincode"
          control={control}
          rules={{
            required: "Default warehouse pincode is required",
          }}
          render={({ field }) => (
            <Input
              className="removeToggle"
              id="default_from_pincode"
              placeholder="Enter default warehouse pincode"
              type="number"
              {...field}
            />
          )}
        />
        {errors.default_from_pincode && (
          <p>{errors.default_from_pincode.message}</p>
        )}

        {/* Show Return Policy Checkbox */}
        <CheckboxContainer>
          <Controller
            name="show_return_policy_text"
            control={control}
            render={({ field }) => (
              <Checkbox
                id="show_return_policy_text"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
          <CheckboxLabel>Show return policy</CheckboxLabel>
        </CheckboxContainer>

        {/* Conditional Return Policy Input */}
        {show_return_policy_text && (
          <>
            <Label htmlFor="return_policy_text">Return policy *</Label>
            <Controller
              name="return_policy_text"
              control={control}
              rules={{ required: "Return policy is required" }}
              render={({ field }) => (
                <Input
                  id="return_policy_text"
                  placeholder="Enter return policy"
                  type="text"
                  {...field}
                />
              )}
            />
            {errors.return_policy_text && <p>{errors.return_policy_text.message}</p>}
          </>
        )}

        {/* Submit Button */}
        <SubmitButton 
          type="submit"
          disabled = {mutation.isLoading} 
          >
            Save
          </SubmitButton>
      </form>
    </Container>
  );
};

export default Shipping;