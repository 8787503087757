import { toast } from 'react-toastify';
import axiosInstance from '../../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';
import { ToastErrorCross, ToastSuccessTick } from 'images/icons';


export const fetchOrdersData = async ({ queryKey }: any) => {
    const [unusedArgument, 
      { pageNumber, fromDate, toDate, searchTerm, searchKey, rtoFilter, deliveryFilter, paymentFilter, source, loggedInFromAdmin ,headless,linkId}
    ] = queryKey
    console.log("fromDate",fromDate,toDate)
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/orders`,
      {
        params: {
          page: pageNumber - 1,
          from: fromDate,
          to: toDate,
          source:source,
          ...(searchTerm && {key: searchKey}),
          ...(searchTerm && {value: searchTerm}),
          ...(deliveryFilter.length && { 'addFilter.dMethod': deliveryFilter.join(',') }),
          ...(paymentFilter.length && { 'addFilter.pMode': paymentFilter[0] }),
          ...(rtoFilter.length && { 'addFilter.rtoPredict': rtoFilter[0] }),
          ...(headless&&{isHeadless:true}),
          ...(linkId&&{link_id:linkId}),
        }
      }
    )
    return response.data.data;
}

export const handleSingleDownload = async (rowId: string, fromDate: string, toDate: string, loggedInFromAdmin: any) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
        params: {
          from: fromDate,
          to: toDate,
          value: rowId
        }
      }
    )
    downloadFile(response.data, 'orderdata.csv')
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  } catch (error) {
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
  }

}

export const handleMultipleDownload = async  (fromDate: string, toDate: string, keys: string,loggedInFromAdmin : any,setIsDataDownloading:any,isHeadless?:boolean,linkId?:string|number) => {
 try{
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: keys,
              ...(isHeadless&&{isHeadless:true}),
              ...(linkId&&{link_id:linkId}),
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')  
  toast.success('Download successful! Your file is ready', {
    icon: ToastSuccessTick
  });
  setIsDataDownloading(false)
 }catch(error){
  toast.error('Download failed. Please try again', {
    icon: ToastErrorCross
  })
  setIsDataDownloading(false)
 }
  
}

export const handleAllDownload = async (
  fromDate: string, toDate: string, searchKey: string, searchTerm:
    string, rtoFilter: string[], deliveryFilter: string[], paymentFilter: string[], source: string,loggedInFromAdmin : any,setIsDataDownloading:any,isHeadless?:boolean,linkId?:string|number
) => {
  try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=ORDER`,
        {
            params: {
                from: fromDate,
                to: toDate,
                source: source,
                ...(searchTerm && {key: searchKey}),
                ...(searchTerm && {value: searchTerm}),
                ...(deliveryFilter.length && { 'addFilter.dMethod': deliveryFilter.join(',') }),
                ...(paymentFilter.length && { 'addFilter.pMode': paymentFilter[0] }),
                ...(rtoFilter.length && { 'addFilter.rtoPredict': rtoFilter[0] }),
                ...(isHeadless&&{isHeadless:true}),
                ...(linkId&&{link_id:linkId}),
            }
        }
    )
    downloadFile(response.data, 'orderdata.csv')
    toast.success('Download successful! Your file is ready', {
        icon: ToastSuccessTick
      });
      setIsDataDownloading(false)

  }catch(error){
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }
  
}