import CustomDatePicker from "components/UIElements/customDatePicker";
import { ExportIcon } from "images/icons";
import moment from "moment";
import React, { FC, CSSProperties } from "react";
import styled from "styled-components";

// Styled wrapper for the table and download button
const TableWrapper = styled.div`
    position:relative;
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    padding: 1rem;
    height: 2rem;
    margin-left: 1rem;
    position:relative;
  }

  .loader {
    /* Customize your loader style here */
  }
`;



interface DownloadButtonProps {
  selectedDateRange: any;
  setSelectedDateRange: any;
  containerClassNames?:string;
  containerStyle?: CSSProperties; // Optional style prop for the container
  children: React.ReactNode;
}

const WithCustomDatePicker: React.FC<DownloadButtonProps> = ({
    selectedDateRange,setSelectedDateRange ,containerClassNames,containerStyle ,children
}) => {
  return (
    <TableWrapper>
      {/* Header with Download Button */}
      <div
        className={containerClassNames}
        style={containerStyle} // Dynamically apply styles
      >
        <CustomDatePicker selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
      </div>

      {children}
    </TableWrapper>
  );
};

export default WithCustomDatePicker;