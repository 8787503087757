import React, { useContext, useState } from 'react';
import { Row, Col } from 'antd';

import {
  FlexColContainer,
  PickrrHeading,
  PickrrButton,
} from '../../../UIElements';
import {
  ContentContainer,
  ModalHeader,
  ModalFooter,
  SubText,
  MenuLinks,
  Container,
} from './style';
import ServiceType from './ServiceType';
import OrderType from './OrderType';
import Product from './Product';
import PickupPincode from './PickupPincode';
import DestinationPincode from './DestinationPincode';
import WeightRange from './WeightRange';
import InvoiceValue from './InvoiceValue';
import RiskProfile from './RiskProfile';
import Zone from './Zone';
import Lane from './Lane';
import PhoneNumber from './PhoneNumber';
import CodBlock from './CodBlock';
import ProductTags from './ProductTags';
import ProductSearch from 'components/Settings/Discount/CreateDiscountModal/Configuration/ProductSearch';
import { DataContext } from 'context/DataProvider'
import UTMList from './UTMList';
import CustomAttributes from './CartAttributes';
import CartAttributes from './CartAttributes';

const AddCriteria = ({
  setModalVisible,
  serviceType,
  setServiceType,
  orderType,
  setOrderType,
  productList,
  setProductList,
  pickupPincodeList,
  setPickupPincodeList,
  destinationPincodeList,
  setDestinationPincodeList,
  weightList,
  setWeightList,
  invoiceValueList,
  setInvoiceValueList,
  zoneList,
  setZoneList,
  laneList,
  setLaneList,
  riskProfile,
  setRiskProfile,
  riskProfileVeryHigh,
  setRiskProfileVeryHigh,
  phoneNumberList,
  setPhoneNumberList,
  pickupError,
  destinationError,
  setCodValueBlockList,
  codValueBlockList,
  codCountBlockList,
  setCodCountBlockList,
  ruleName,
  productTagsList,
  setProductTagsList,
  utmList,
  setUtmList,
  productCollectionList,
  setProductCollectionList, 
  selectedRiskTags,
  setSelectedRiskTags, 
  riskType,
  setRiskType,
  cartAttributesList,
  setCartAttributesList

}) => {
  const [selected, setSelected] = useState('product');
  const { sellerId, sellerDomain, channel } =
  useContext(DataContext);
  const getComponent = {
    serviceType: (
      <ServiceType serviceType={serviceType} setServiceType={setServiceType} />
    ),
    orderType: <OrderType orderType={orderType} setOrderType={setOrderType} />,
    product: (
      <Product productList={productList} setProductList={setProductList} />
    ),
    pickupPincode: (
      <PickupPincode
        pickupPincodeList={pickupPincodeList}
        setPickupPincodeList={setPickupPincodeList}
        pickupError={pickupError}
      />
    ),
    destinationPincode: (
      <DestinationPincode
        destinationPincodeList={destinationPincodeList}
        setDestinationPincodeList={setDestinationPincodeList}
        destinationError={destinationError}
      />
    ),
    weightRange: (
      <WeightRange weightList={weightList} setWeightList={setWeightList} />
    ),
    invoiceValue: (
      <InvoiceValue
        invoiceValueList={invoiceValueList}
        setInvoiceValueList={setInvoiceValueList}
      />
    ),
    zone: <Zone zoneList={zoneList} setZoneList={setZoneList} />,
    lane: <Lane laneList={laneList} setLaneList={setLaneList} />,
    riskProfile: (
      <RiskProfile 
        riskProfile={riskProfile} 
        setRiskProfile={setRiskProfile} 
        riskProfileVeryHigh={riskProfileVeryHigh}
        setRiskProfileVeryHigh={setRiskProfileVeryHigh}
        selectedRiskTags = {selectedRiskTags}
        setSelectedRiskTags = {setSelectedRiskTags}
        riskType = {riskType}
        setRiskType = {setRiskType}
      />
    ),
    phoneNumber: (
      <PhoneNumber
        phoneNumberList={phoneNumberList}
        setPhoneNumberList={setPhoneNumberList}
      />
    ),
    cod_value_block:(<CodBlock label={"COD"} codList={codValueBlockList} setList={setCodValueBlockList} key_list='value' placeholder={"Value"}/>),
    cod_count_block:(<CodBlock label={"COD"} codList={codCountBlockList} setList={setCodCountBlockList} key_list='count' placeholder={"Count"}/>),
    product_tags: (<ProductTags productTagsList={productTagsList} setProductTagsList={setProductTagsList} />),
    product_collections: (
      <ProductSearch
      selectedItems={productCollectionList}
      setSelectedItems={setProductCollectionList}
      type={"collection"}
      discountType={""}
      sellerConfigValues={{
        seller_id: sellerId,
        domain_name: sellerDomain,
        channel: channel
      }}
      bundleKey = {Math.random() * 1001}
    />
    ),
    utm: (<UTMList utmList={utmList} setUtmList={setUtmList} />),
    cartAttributes: (<CartAttributes cartAttributesList={cartAttributesList} setCartAttributesList={setCartAttributesList}/>)
    
  };

  const handleAdd = () => {
    setWeightList((prev) =>
      prev.filter((w) => {
        if (w.min === null || w.max === null) {
          return false;
        }
        return w;
      })
    );
    setInvoiceValueList((prev) =>
      prev.filter((inv) => {
        if (inv.min === null || inv.max === null) {
          return false;
        }
        return inv;
      })
    );
    setLaneList((prev) =>
      prev.filter((lane) => {
        if (!lane.origin_cities.length || !lane.destination_cities.length) {
          return false;
        }
        return lane;
      })
    );
    setModalVisible(false);
  };

  return (
    <Container>
      <Row gutter={16}>
        <Col span={6} style={{ borderRight: '1px solid #C6C8E3' }}>
          <FlexColContainer>
            <MenuLinks
              checked={selected === 'product' ? 'true' : 'false'}
              onClick={() => setSelected('product')}
            >
              Product SKU
            </MenuLinks>
            <MenuLinks
              checked={selected === 'product_tags' ? 'true' : 'false'}
              onClick={() => setSelected('product_tags')}
            >
              Product Tags
            </MenuLinks>
            <MenuLinks
              checked={selected === 'product_collections' ? 'true' : 'false'}
              onClick={() => setSelected('product_collections')}
            >
              Product Collections
            </MenuLinks>
            <MenuLinks
              checked={selected === 'utm' ? 'true' : 'false'}
              onClick={() => setSelected('utm')}
            >
              UTM
            </MenuLinks>
            <MenuLinks
              checked={selected === 'pickupPincode' ? 'true' : 'false'}
              onClick={() => setSelected('pickupPincode')}
            >
              Pickup Pincode
            </MenuLinks>
            <MenuLinks
              checked={selected === 'destinationPincode' ? 'true' : 'false'}
              onClick={() => setSelected('destinationPincode')}
            >
              Destination Pincode
            </MenuLinks>
            <MenuLinks
                checked={selected === 'weightRange' ? 'true' : 'false'}
                onClick={() => setSelected('weightRange')}
              >
                Weight Range
              </MenuLinks>
            <MenuLinks
              checked={selected === 'invoiceValue' ? 'true' : 'false'}
              onClick={() => setSelected('invoiceValue')}
            >
              Invoice Value
            </MenuLinks>
            {/* <MenuLinks
              checked={selected === 'zone' ? 'true' : 'false'}
              onClick={() => setSelected('zone')}
            >
              Zone
            </MenuLinks> */}
            <MenuLinks
              checked={selected === 'lane' ? 'true' : 'false'}
              onClick={() => setSelected('lane')}
            >
              Lane
            </MenuLinks>
            <MenuLinks
              checked={selected === 'riskProfile' ? 'true' : 'false'}
              onClick={() => setSelected('riskProfile')}
            >
              Risk Profile
            </MenuLinks>
            <MenuLinks
              checked={selected === 'phoneNumber' ? 'true' : 'false'}
              onClick={() => setSelected('phoneNumber')}
            >
              Phone Number
            </MenuLinks>
           {ruleName =='cod_disable'&& <MenuLinks
              checked={selected === 'cod_count_block' ? 'true' : 'false'}
              onClick={() => setSelected('cod_count_block')}
            >
             COD Order Count 
            </MenuLinks>}
            {ruleName =='cod_disable'&& <MenuLinks
              checked={selected === 'cod_value_block' ? 'true' : 'false'}
              onClick={() => setSelected('cod_value_block')}
            >
              COD Order Value
            </MenuLinks> }
            <MenuLinks
              checked={selected === 'cartAttributes' ? 'true' : 'false'}
              onClick={() => setSelected('cartAttributes')}
            >
              Cart Attributes
            </MenuLinks>
          </FlexColContainer>
        </Col>
        <Col span={18} style={{ padding: '0 24px' }}>
          {getComponent[selected]}
        </Col>
      </Row>
    </Container>
  );
};

export default AddCriteria;
