import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { AttributionConfig, mainInputComponentStyle, utmCustomAttributes } from '.';
import CustomInputField from 'components/UIElements/CustomInputField';


const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  font-family: Arial, sans-serif;
`;

const Label = styled.label`
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 8px;
  margin-right:10px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
  width: 41px;
  height: 19px;
  top:5px;
  flex-shrink: 0;
  background-color: ${(props) => (props.checked ? '#12B87B' : '#A5A5A5')};
  border-radius: 20px;
  position: relative;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 1.5px;
    left: ${(props) => (props.checked ? '22px' : '1.5px')};
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;


const CustomAttributesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color:#D3D3D3;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  font-weight: 800;
  line-height: 25px;
  font-size: 18px;
  justify-content: center;
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top: 8px;
`;

const Attribution = ({ data, control, watch }: { data: AttributionConfig; control: any, watch:any }) => {
  const [customAttributeEnabled, setCustomAttributeEnabled] = useState(data?.customAttributes?.length > 0);

  const { append ,replace} = useFieldArray({
    control,
    name: "attribution.config.customAttributes",
    keyName: "id",
  });

  useEffect(() => {
    if (customAttributeEnabled && data?.customAttributes?.length === 0) {
      append({id:1, paramName: '', paramValue: '' });
    }
  }, [customAttributeEnabled, data?.customAttributes?.length, append]);

  const addCustomAttribute = () => {
    append({id:data?.customAttributes?.length+1, paramName: '', paramValue: '' });
  };

  const deleteCustomAttribute = (itemIndex:Number) => {
    replace(data.customAttributes.filter((item,index)=>index!=itemIndex));
  };

  const utmId = watch("attribution.config.utmId");
  const utmSource = watch("attribution.config.utmSource");
  const utmMedium = watch("attribution.config.utmMedium");
  const utmCampaign = watch("attribution.config.utmCampaign");
  const utmTerms = watch("attribution.config.utmTerms");
  const utmContent = watch("attribution.config.utmContent");
  
  const haveInput = utmId || utmSource || utmMedium || utmCampaign || utmTerms || utmContent;



  return (
    <Container>
      {/* UTM Fields */}
      <CustomInputField
                name="attribution.config.utmId"
                label="UTM ID"
                placeholder="Enter UTM Id"
                control={control}
                rules={{
                  required: haveInput ? undefined : 'UTM Id is required'
                }}
                inputStyle={mainInputComponentStyle}
              />
      <CustomInputField
                name="attribution.config.utmSource"
                label="UTM Source"
                placeholder="Enter UTM Source"
                control={control}
                rules={{
                  required: haveInput ? undefined :  'UTM Source is required'
                }}
                inputStyle={mainInputComponentStyle}
              />

      <CustomInputField
                name="attribution.config.utmMedium"
                label="UTM Medium "
                placeholder="Enter UTM Medium "
                control={control}
                rules={{
                  required: haveInput ? undefined : 'UTM Medium  is required'
                }}
                inputStyle={mainInputComponentStyle}
              />
      
      <CustomInputField
                name="attribution.config.utmCampaign"
                label="UTM Campaign "
                placeholder="Enter UTM Campaign "
                control={control}
                rules={{
                  required: haveInput ? undefined : 'UTM Campaign  is required'
                }}
                inputStyle={mainInputComponentStyle}
              />
         <CustomInputField
                name="attribution.config.utmTerms"
                label="UTM Terms "
                placeholder="Enter UTM Terms "
                control={control}
                rules={{
                  required: haveInput ? undefined : 'UTM Terms  is required'
                }}
                inputStyle={mainInputComponentStyle}
              />
        <CustomInputField
                name="attribution.config.utmContent"
                label="UTM Content "
                placeholder="Enter UTM Content "
                control={control}
                rules={{
                  required: haveInput ? undefined : 'UTM Content  is required'
                }}
                inputStyle={mainInputComponentStyle}
              />
      {/* Custom Attribute Toggle */}
      <Label>Custom Attribute</Label>
      <Controller
        name="attribution.config.customAttributeEnabled"
        control={control}
        defaultValue={customAttributeEnabled}
        render={({ field }) => (
          <ToggleSwitch
            checked={field.value}
            onChange={(e) => {
              field.onChange(e.target.checked);
              setCustomAttributeEnabled(e.target.checked);
            }}
          />
        )}
      />

      {/* Custom Attributes Inputs */}
      {customAttributeEnabled && (
        <>
          {data.customAttributes.map((item:utmCustomAttributes, index) => (
            <CustomAttributesContainer key={item.id}>
              <div style={{ width: '200px' }}>
              <CustomInputField
                name={`attribution.config.customAttributes[${index}].paramName`}
                label="Key "
                placeholder="Enter Key"
                control={control}
                labelStyle={{
                  "margin-top": "6px",
                  "margin-bottom": "8px",
                }}
                inputStyle={{
                  "width": "100%",
                  "padding": "10px",
                  "font-size": "16px",
                  "border-radius": "8px",
                  "margin-bottom": "4px",
                }}
              />
              </div>
              <div style={{ width: '200px' }}>
              <CustomInputField
                name={`attribution.config.customAttributes[${index}].paramValue`}
                label="Value "
                placeholder="Enter Value"
                control={control}
                labelStyle={{         
                  "margin-top": "6px",
                  "margin-bottom": "8px",
                }}
                inputStyle={{
                  "width": "100%",
                  "padding": "10px",
                  "font-size": "16px",
                  "border-radius": "8px",
                  "margin-bottom": "4px",
                }}
              />
              </div>
              
              {/* {index === data?.customAttributes?.length - 1 ?  */}
              {index === 0 ?
                <div style={{marginTop: "22px"}}>
                  <Button onClick={addCustomAttribute}>+</Button>
                </div>
                : ''
              }
                {/* :  */}
                {index !== 0 ? 
                <div style={{marginTop: "22px"}}>
                  <Button onClick={()=>deleteCustomAttribute(index)}>-</Button>
                </div>
                : ''}
              {/* // } */}
            </CustomAttributesContainer>
          ))}
        </>
      )}
    </Container>
  );
};

export default Attribution;