import React, { useEffect, useState, useContext,ChangeEvent } from "react";
import styled from "styled-components";
import CustomReactTable from "UiComponents/Table";
import { CustomDownloadButton, FlexRowTw } from "pages/Reports/styles";
import { CustomReactInput } from "UiComponents/Select";
import { ExportIcon } from "images/icons";
import { DatePicker, Tooltip } from "antd";
import { useQuery } from "react-query";
import { fetchLinks } from "./configuration/script";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import WithSideDrawer from "HOC/WithSideDrawerHoc";
import Shipping from "./CreateLinkShipping";
import ConfigurableCard from "./ConfigurableCards";
import CreateLinkLayoutComponent from "./Layout";
import WithCustomDatePicker from "HOC/WithCustomDatePicker";
import { TextStyle } from "pages/Orders/OrdersTab/styles";
import useQRCode from "hooks/useQrCode";
import { DataContext } from "context/DataProvider";
import { getGeneralDetails } from "components/Settings/GeneralSettings/script";
// import { FetchPaymentConfig, UpdatePaymentGateway } from "../script";
import { FetchPaymentConfig } from "components/Settings/Payments/script";
const { RangePicker } = DatePicker

const Container = styled.div`
width: 100%;
height: 100%;
flex-shrink: 0;
border-radius: 0.375rem 0rem 0rem 0.375rem;
background: #FFF;
box-shadow: 0px 0px 30px 0px rgba(170, 170, 170, 0.16), 0px 10px 20px 0px rgba(194, 194, 194, 0.16);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3rem;
  color: #000;
font-size: 1.1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
`;

const Span = styled.span`
color: #6B7280;
font-size: .75rem;
font-style: normal;
font-weight: 400;
line-height: 1rem; /* 133.333% */`;


const LinkStatus = styled.span<{ active: boolean }>`
color:${(props) => props.active ? "#238017" : "rgba(198, 94, 19, 0.80)"};
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 1.25rem; /* 166.667% */
 padding-left: .3rem; 

   &::before {
    content: "•";
  margin-right: .3rem;
    width:".25rem"
    height:".25rem";
    transform: translateY(-50%);
    border-radius: 50%; /* Make it a circle */
  }
`;

const Title = styled.button`
color: #6B7280;
font-size: .75rem;
font-style: normal;
font-weight: 700;
white-space: nowrap;
line-height: 1rem; /* 133.333% */`;

const ButtonCreate = styled.button`
display: flex;
width: 8rem;
height: 2.1875rem;
padding: 1.125rem;
justify-content: center;
align-items: center;
gap: 0.25rem;
flex-shrink: 0;
border-radius: 0.5rem;
background: #6F57E9;
color: #FFF;
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: normal;
  cursor: pointer;
`;


const ButtonDownload = styled.button`
display: flex;
width: 7.4375rem;
height: 2.25rem;
align-items: center;
flex-shrink: 0;
border-radius: 0.5rem;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(67, 90, 111, 0.47);
color: #282828;
font-size: 0.8125rem;
font-style: normal;
font-weight: 400;
line-height: 1rem; /* 123.077% */
cursor: pointer;
`;

const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 1.25rem;
  @media (max-width: 991px) {
    flex-wrap:wrap;
    
  }
`;

const editLink = <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
  <path d="M2.49231 13.6175H6.01477C6.1241 13.6181 6.23249 13.5967 6.33371 13.5543C6.43493 13.512 6.52699 13.4496 6.60462 13.3708L12.3535 7.47483L14.7129 5.10964C14.7908 5.03055 14.8526 4.93645 14.8948 4.83278C14.9369 4.7291 14.9587 4.6179 14.9587 4.50559C14.9587 4.39327 14.9369 4.28207 14.8948 4.17839C14.8526 4.07472 14.7908 3.98062 14.7129 3.90153L11.1905 0.251661C11.1132 0.171918 11.0213 0.108625 10.9201 0.0654313C10.8189 0.022238 10.7103 0 10.6006 0C10.4909 0 10.3824 0.022238 10.2811 0.0654313C10.1799 0.108625 10.088 0.171918 10.0108 0.251661L7.668 2.65938L1.90246 8.55532C1.82546 8.63482 1.76455 8.7291 1.72321 8.83276C1.68186 8.93642 1.66091 9.04741 1.66154 9.15938V12.7667C1.66154 12.9924 1.74907 13.2088 1.90487 13.3683C2.06066 13.5279 2.27197 13.6175 2.49231 13.6175ZM10.6006 2.05533L12.9517 4.46305L11.772 5.67116L9.42092 3.26344L10.6006 2.05533ZM3.32308 9.5082L8.24954 4.46305L10.6006 6.87077L5.67415 11.9159H3.32308V9.5082ZM15.7846 15.3191H0.830769C0.610436 15.3191 0.399126 15.4087 0.243327 15.5683C0.0875272 15.7278 0 15.9442 0 16.1699C0 16.3955 0.0875272 16.6119 0.243327 16.7714C0.399126 16.931 0.610436 17.0206 0.830769 17.0206H15.7846C16.0049 17.0206 16.2163 16.931 16.3721 16.7714C16.5279 16.6119 16.6154 16.3955 16.6154 16.1699C16.6154 15.9442 16.5279 15.7278 16.3721 15.5683C16.2163 15.4087 16.0049 15.3191 15.7846 15.3191Z" fill="#979798" />
</svg>

const copyLink = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
  <path d="M10.9815 4.10938H1.90741C1.40626 4.10938 1 4.51564 1 5.01678V14.0909C1 14.592 1.40626 14.9983 1.90741 14.9983H10.9815C11.4826 14.9983 11.8889 14.592 11.8889 14.0909V5.01678C11.8889 4.51564 11.4826 4.10938 10.9815 4.10938Z" stroke="#979798" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M5.6665 2.81481V1.90741C5.6665 1.40626 6.07277 1 6.57391 1H15.648C16.1491 1 16.5554 1.40626 16.5554 1.90741V10.9815C16.5554 11.4826 16.1491 11.8889 15.648 11.8889H14.7406" stroke="#979798" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="66.67 66.67" />
</svg>

const otherActions = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M10.7861 3.375C10.7861 2.92745 10.6182 2.49822 10.3193 2.18176C10.0204 1.86529 9.61507 1.6875 9.19238 1.6875C8.76969 1.6875 8.36432 1.86529 8.06543 2.18176C7.76655 2.49822 7.59863 2.92745 7.59863 3.375C7.59863 3.82255 7.76655 4.25178 8.06543 4.56824C8.36432 4.88471 8.76969 5.0625 9.19238 5.0625C9.61507 5.0625 10.0204 4.88471 10.3193 4.56824C10.6182 4.25178 10.7861 3.82255 10.7861 3.375ZM10.7861 9C10.7861 8.55245 10.6182 8.12322 10.3193 7.80676C10.0204 7.49029 9.61507 7.3125 9.19238 7.3125C8.76969 7.3125 8.36432 7.49029 8.06543 7.80676C7.76655 8.12322 7.59863 8.55245 7.59863 9C7.59863 9.44755 7.76655 9.87678 8.06543 10.1932C8.36432 10.5097 8.76969 10.6875 9.19238 10.6875C9.61507 10.6875 10.0204 10.5097 10.3193 10.1932C10.6182 9.87678 10.7861 9.44755 10.7861 9ZM10.7861 14.625C10.7861 14.1774 10.6182 13.7482 10.3193 13.4318C10.0204 13.1153 9.61507 12.9375 9.19238 12.9375C8.76969 12.9375 8.36432 13.1153 8.06543 13.4318C7.76655 13.7482 7.59863 14.1774 7.59863 14.625C7.59863 15.0726 7.76655 15.5018 8.06543 15.8182C8.36432 16.1347 8.76969 16.3125 9.19238 16.3125C9.61507 16.3125 10.0204 16.1347 10.3193 15.8182C10.6182 15.5018 10.7861 15.0726 10.7861 14.625Z" fill="#929292" />
</svg>


const TableContainer = styled.div`
  background-color: white;
  border-radius: .5rem;
  padding: .7rem;
  box-shadow: 0 .12rem .3rem rgba(0, 0, 0, 0.1);
`;

type DrawerComponentType = React.FC;

let DrawerComponent: any;


type cardsConfiguration = {
  icon: React.ReactNode;
  title: string;
  description: string;
  status: "In progress" | "Completed" | string | any;
  buttonText: string;
  showNotificationBadge?: boolean;
  notificationContent?: string;
  onClick: () => void;
}

const CheckoutLinks = () => {
  const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchKey, setSearchKey] = useState<string>('ALL')
  const [pageSize, setPageSize] = useState(10);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [totalPages,setTotalPages]=useState(0)
  const navigate = useNavigate()
  const {downloadQRCode}=useQRCode()
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    start: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const handleDownloadQrCode=async (link:string)=>{
    downloadQRCode(link);
  }
  const { data: generalSettingData, isLoading:generalSettingLoader, isSuccess} = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const {sellerConfig, sellerId} = useContext(DataContext);

  const { data : paymentData, isLoading: paymentLoading } = useQuery(
    ['userDetails', {sellerId}],
    FetchPaymentConfig
  );

  // {console.log()}

  
  // console.log(sellerConfig, 'checking seller config')
  const isShippingCompelete = 
    generalSettingData?.standard_shipment_charges >=0 &&
    generalSettingData?.default_from_pincode &&
    generalSettingData?.serviceability_hide_edd &&
    generalSettingData?.return_policy_text 

  const preLoginBanner =  
    !!(generalSettingData?.banner_details?.pre_login?.text &&
    sellerConfig?.banner_details?.pre_login?.text_color &&
    generalSettingData?.banner_details?.pre_login?.background_color);
    
  const postLoginBanner = 
    !!(generalSettingData?.banner_details?.post_login?.text &&
    sellerConfig?.banner_details?.post_login?.text_color &&
    generalSettingData?.banner_details?.post_login?.background_color);
    
  const isBrandingCompeleted = preLoginBanner || postLoginBanner;
    
  const isPaymentCompeleted = paymentData?.sellerPaymentConfiguration?.other_payment_methods_display_order?.length;



  const cardsConfiguration: cardsConfiguration[] = [
    {
      icon: <div style={{ position: "relative" }}><div><svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.7rem" viewBox="0 0 27 24" fill="none">
        <path d="M25.8491 1.95411C25.995 1.92775 26.1475 1.95411 26.2738 2.0251C26.4895 2.10825 26.642 2.30498 26.642 2.53619L26.7662 18.6763C26.7771 18.8751 26.6725 19.0718 26.4764 19.1793L19.3967 23.1119C19.2965 23.1829 19.1723 23.2234 19.0372 23.2234C19.0045 23.2234 18.9719 23.2214 18.9414 23.2153L0.568791 21.3129C0.252924 21.3007 0.000230288 21.0574 0.000230288 20.7592V4.34531C-0.0063049 4.12627 0.126577 3.91331 0.357487 3.82002L9.57428 0.0476405C9.67012 0.00707729 9.77904 -0.009148 9.89232 0.00504913L25.8491 1.95411ZM19.6363 6.86834V21.6861L25.5746 18.3883L25.4591 3.45089L19.6363 6.86834ZM18.4403 22.0451V7.02451L10.6047 6.09359L10.3476 13.0644L7.59629 11.3202L4.84498 12.7683L5.41354 5.47703L1.19617 4.97607V20.2623L18.4403 22.0451ZM17.2008 2.01698L11.4216 5.07139L18.9392 5.96378L24.2087 2.87287L17.2008 2.01698ZM6.14984 4.44469L11.7527 1.35175L9.90539 1.12662L2.7755 4.04514L6.14984 4.44469Z" fill="#757575" />
      </svg></div>
        <div style={{
          width: "1rem",
          height: "1rem",
          position: "absolute",
          zIndex: 1,
          left: "1rem",
          top: ".65rem",
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width=".8rem" height=".8rem" viewBox="0 0 15 15" fill="none">
            <path d="M2.62982 5.90621C3.74362 5.90621 4.19899 5.11855 3.64517 4.15243C3.32518 3.59246 3.51594 2.87864 4.07592 2.55866L5.14049 1.94945C5.62662 1.66023 6.25429 1.83253 6.54351 2.31867L6.6112 2.43558C7.16502 3.4017 8.07575 3.4017 8.63573 2.43558L8.70342 2.31867C8.98033 1.83253 9.608 1.66023 10.0941 1.94945L11.1587 2.55866C11.7187 2.87864 11.9094 3.59861 11.5895 4.15859C11.0295 5.11855 11.4848 5.90621 12.5986 5.90621C13.2386 5.90621 13.7678 6.42926 13.7678 7.07539V8.15842C13.7678 8.79839 13.2448 9.3276 12.5986 9.3276C11.4848 9.3276 11.0295 10.1153 11.5895 11.0814C11.9094 11.6414 11.7187 12.3552 11.1587 12.6752L10.0941 13.2844C9.608 13.5736 8.98033 13.4013 8.69111 12.9151L8.62342 12.7982C8.0696 11.8321 7.15887 11.8321 6.59889 12.7982L6.5312 12.9151C6.24198 13.4013 5.61432 13.5736 5.12818 13.2844L4.06361 12.6752C3.50363 12.3552 3.31287 11.6352 3.63286 11.0814C4.19284 10.1153 3.73747 9.3276 2.62367 9.3276C1.9837 9.3276 1.45449 8.80455 1.45449 8.15842V7.07539C1.46064 6.43542 1.9837 5.90621 2.62982 5.90621ZM7.61423 9.61682C8.71573 9.61682 9.61415 8.7184 9.61415 7.6169C9.61415 6.51541 8.71573 5.61699 7.61423 5.61699C6.51274 5.61699 5.61432 6.51541 5.61432 7.6169C5.61432 8.7184 6.51274 9.61682 7.61423 9.61682Z" fill="#8E7BF1" />
          </svg></div></div>,
      title: "Shipping settings",
      description: "Customise delivery charges and estimated delivery dates to suit your customers' needs.",
      status: `${isShippingCompelete ? 'Completed' : 'In progress'}`,
      buttonText: `${isShippingCompelete ? "Update" : "Set up"}`,
      onClick: () => {
        setIsDrawerOpen(true)
        DrawerComponent = Shipping
      }
    },
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.7rem" viewBox="0 0 21 22" fill="none">
        <g clip-path="url(#clip0_8001_181818)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8125 8.375H21V21.5H11.8125V8.375ZM14.4375 11V18.875H18.375V11H14.4375ZM0 18.875H9.1875V21.5H0V18.875ZM0 8.375H9.1875V11H0V8.375ZM0 13.625H9.1875V16.25H0V13.625ZM0 0.5H21V5.75H10.5H0V0.5Z" fill="#757575" />
          <rect x="12.8125" y="9.375" width="7.1875" height="11.125" stroke="#8E7BF1" stroke-width="2" />
        </g>
        <defs>
          <clipPath id="clip0_8001_181818">
            <rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>,
      title: "Personalised branding",
      description: "Add your logo, theme color, and banners to highlight deals or special promotions.",
      status: `${isBrandingCompeleted ? 'Completed' : 'In progress' }`,
      buttonText: `${isBrandingCompeleted ? "Update" : "Set up"}`,
      notificationContent:'Pending',
      onClick: () => {
        setIsDrawerOpen(true)
        DrawerComponent = CreateLinkLayoutComponent
      }
    },
    {
      icon:
        <div style={{ position: "relative" }}><div><svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.7rem" viewBox="0 0 27 27" fill="none">
          <g clip-path="url(#clip0_8001_181831)">
            <path d="M3.28511 10.208C5.58037 10.208 6.51876 8.60317 5.37747 6.63478C4.71806 5.49387 5.11117 4.03952 6.26514 3.38757L8.45895 2.14636C9.46074 1.5571 10.7542 1.90815 11.3502 2.89861L11.4897 3.13682C12.631 5.10521 14.5078 5.10521 15.6617 3.13682L15.8012 2.89861C16.3719 1.90815 17.6653 1.5571 18.6671 2.14636L20.8609 3.38757C22.0149 4.03952 22.408 5.50641 21.7486 6.64732C20.5946 8.60317 21.533 10.208 23.8283 10.208C25.1471 10.208 26.2377 11.2737 26.2377 12.5901V14.7967C26.2377 16.1006 25.1598 17.1788 23.8283 17.1788C21.533 17.1788 20.5946 18.7836 21.7486 20.752C22.408 21.8929 22.0149 23.3473 20.8609 23.9992L18.6671 25.2404C17.6653 25.8297 16.3719 25.4786 15.7759 24.4882L15.6364 24.25C14.4951 22.2816 12.6183 22.2816 11.4643 24.25L11.3248 24.4882C10.7288 25.4786 9.43538 25.8297 8.43359 25.2404L6.23978 23.9992C5.08581 23.3473 4.6927 21.8804 5.35211 20.752C6.50608 18.7836 5.56769 17.1788 3.27243 17.1788C1.95361 17.1788 0.863047 16.1131 0.863047 14.7967V12.5901C0.875727 11.2862 1.95361 10.208 3.28511 10.208ZM13.5567 17.7681C15.8266 17.7681 17.678 15.9376 17.678 13.6934C17.678 11.4492 15.8266 9.61871 13.5567 9.61871C11.2868 9.61871 9.43538 11.4492 9.43538 13.6934C9.43538 15.9376 11.2868 17.7681 13.5567 17.7681Z" fill="#757575" />
          </g>
          <defs>
            <clipPath id="clip0_8001_181831">
              <rect width="26.7939" height="26.4907" fill="white" transform="matrix(-1 0 0 1 26.7938 0.5)" />
            </clipPath>
          </defs>
        </svg></div>
          <div style={{
            position: "absolute",
            zIndex: 1,
            left: ".53rem",
            top: ".53rem",
          }}><svg xmlns="http://www.w3.org/2000/svg" width=".7rem" height=".7rem" viewBox="0 0 7 8" fill="none">
              <path d="M1.01953 3.91496H5.85732M3.43842 1.52344V6.30649" stroke="#8E7BF1" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </svg></div> </div>,
            title: "Payment & Analytics:",
            description: "Integrate payment methods and set up Google and Meta pixel tracking to optimize performance.",
            status: isPaymentCompeleted ? "Completed" : "In progress" ,
            buttonText: isPaymentCompeleted ? "Update" : "Set up",
            showNotificationBadge: true,
            onClick: () => {navigate('/settings/payments')}
    }

  ]


  // ['ordersData', { pageNumber, fromDate, toDate, searchTerm, ...(searchTerm && { searchKey }), rtoFilter, paymentFilter, deliveryFilter, source, loggedInFromAdmin }]
  const {
    data,
    isLoading,
  } = useQuery<any>(
    ['linksData', { currentPage, pageSize,fromDate: moment(selectedDateRange.start).format("YYYY-MM-DD"), toDate: moment(selectedDateRange.end).format("YYYY-MM-DD"), searchTerm:debouncedSearchTerm, searchKey }],
    fetchLinks,
  )
  
  const [paginatedData, setPaginatedData] = useState([]);
  
  useEffect(() => {
    const filteredData = data?.data || [];
    const totalPageData  =  filteredData?.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    setPaginatedData(totalPageData);
    
  }, [data])
  
  
  
  // console.log(filteredData, 'asdkhasbkjdfasjkfdnakjfsdkdhafsk')


  // const paginatedData = filteredData?.slice(
  //   (currentPage - 1) * pageSize,
  //   currentPage * pageSize
  // );
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)

  // useEffect(() => {
    // console.log(data, 'akjsdfnasjdnjafdnjasfdn')
    // setTotalPages(data?.total_links || 0);
  // }, [])

 // Debounce logic
 useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedSearchTerm(searchTerm);
  }, 1000);

  return () => clearTimeout(handler); // Cleanup timeout on dependency change
}, [searchTerm]);



  const col: object[] = [
    {
      Header: 'Title',
      disableSortBy: false,
      tooltip: true,
      accessor: 'title',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <Title >{value}</Title>
        )

      }
    },

    {
      Header: 'Date & Time',
      disableSortBy: false,
      tooltip: true,
      accessor: 'date',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <TextStyle>
            <div className='w-max'>
              {value ? moment(value).format('Do MMMM, h:mm a') : '-'}
            </div>
          </TextStyle>
        )

      }
    },
    {
      Header: 'Link type',
      disableSortBy: false,
      tooltip: true,
      accessor: 'type',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <TextStyle >{String(value).replace(/_/g, " ")}</TextStyle>
        )

      }
    },
    {
      Header: 'Status',
      disableSortBy: false,
      tooltip: true,
      accessor: 'active',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <LinkStatus active={value ? true : false}>{value ? "Active" : "Inactive"}</LinkStatus>
        )

      }
    },
    {
      Header: 'Products',
      disableSortBy: false,
      tooltip: true,
      accessor: 'product_count',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <TextStyle>{`${value} Product`}</TextStyle>
        )

      }
    },
    //  {
    //   Header: 'Clicks',
    //   disableSortBy: false,
    //   tooltip: true,
    //   accessor: 'clicks',
    //   Cell: ({ cell: { value }, row }: any) => {
    //     //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
    //     return (
    //       <Span >{value}</Span>
    //     )

    //   }
    // },
    {
      Header: 'Views',
      disableSortBy: false,
      tooltip: true,
      accessor: 'views',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <Span >{value}</Span>
        )

      }
    },
    {
      Header: 'Orders',
      disableSortBy: false,
      tooltip: true,
      accessor: 'orders',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <Link  style={{color:"#7d5cff"}}to={`/purchaseLink/orders?linkId=${row.original.id}`}>{value}</Link>
        )

      }
    },
    {
      Header: 'Click Rate',
      disableSortBy: false,
      tooltip: true,
      accessor: 'click_rate',
      Cell: ({ cell: { value }, row }: any) => {
        // const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <Span >{value?.toFixed(2) ?? 0 } %</Span>
        )

      }
    },
    {
      Header: 'Revenue',
      disableSortBy: false,
      tooltip: true,
      accessor: 'revenue',
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <Span >{value?.toFixed(2)}</Span>
        )

      }
    },
    {
      Header: 'Action',
      disableSortBy: true,
      tooltip: true,
      Cell: ({ cell: { value }, row }: any) => {
        //const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            {row.original.id && 
              <div style={{ cursor: "pointer" }} onClick={() => {navigate(`/purchaseLink/edit/${row.original.id}`)}}>
                {editLink}
              </div>
            }
            <div style={{ cursor: "pointer" }} onClick={() => {
              if(row?.original?.active){
                navigator.clipboard.writeText(row.original.end_point);
                alert('Link copied to clipboard!');
              }
            }}><Tooltip
            color="#FFF"
            overlayStyle={{
              width: "6rem",
              filter: "drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.20))",
            }}
            overlayInnerStyle={{
              maxHeight: "0.275rem", // Set tooltip content height
              display: "flex", // Align content within height
              alignItems: "center", // Vertically center content
            }}
            placement="bottomRight"
            title={
              <p
                style={{
                  color: "#000",
                  fontSize: "0.5rem",
                  fontStyle: "normal",
                  fontWeight: "200",
                  textAlign: 'center',
                  margin: 0, // Remove margin to respect height
                }}
              >
                {row?.original?.active ? "Copy purchase link" : "link is not active"}
              </p>
            }
          >
            {copyLink}
          </Tooltip>
          </div>
            
            <div style={{ cursor: "pointer" }} >
              <Tooltip
              color="#FFF"
              overlayStyle={{
                width: "6rem",
                filter: "drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.20))",
              }}
              overlayInnerStyle={{
                maxHeight: "0.275rem", // Set tooltip content height
                display: "flex", // Align content within height
                alignItems: "center", // Vertically center content
              }}
              placement="bottomRight"
              title={
                <p
                  style={{
                    color: "#000",
                    fontSize: "0.5rem",
                    fontStyle: "normal",
                    textAlign:'center',
                    fontWeight: "200",
                    margin: 0, // Remove margin to respect height
                    cursor: row?.original?.active ? "pointer" : 'not-allowed'
                  }}
                  onClick={()=> {row?.original?.active && handleDownloadQrCode(row.original.end_point)}}
                >
                  {row?.original?.active ? "Download Qr Code" : "link is not active"}
                </p>
              }
            >
              {otherActions}
            </Tooltip>
            </div>

          </div>
        )

      }
    },
  ]





  return (
    <Container>
      <TableContainer>
        <Header>
          <span>Checkout Links</span>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* <ButtonDownload><span style={{ margin: "5px" }}><ExportIcon /></span>Download All</ButtonDownload> */}
            <ButtonCreate onClick={() => {
              if(paginatedData?.length > 0){
                navigate('/purchaseLink/create/1')
              }else {
                navigate('/purchaseLink/create/0')
              }
            }}>Create Link</ButtonCreate>
          </div>
        </Header>

        {!(isPaymentCompeleted && isBrandingCompeleted && isShippingCompelete) ? 
        <SettingsContainer>
          {cardsConfiguration.map((config) => {
            return <ConfigurableCard {...config} />
          })}
        </SettingsContainer>
        : ''} 


        <FlexRowTw className='justify-between'>
          <FlexRowTw>
          <CustomReactInput addOnBefore={[
              { value: 'ALL', label: 'ALL' },
              { value: 'CHECKOUT_LINK', label: 'CHECKOUT LINKS' },
              { value: 'PRODUCT_PAGE', label: 'PRODUCT LINKS' }
            ]} 
              defaultValue={searchKey} 
              onChangeHandler={(e: any) => { setSearchTerm(e.currentTarget.value) }} 
              onPressEnterHandler={(e: any) => { 
                if(e.currentTarget.value) setDebouncedSearchTerm(e.currentTarget.value)
                setSearchTerm(e.currentTarget.value) 
              }} 
              onSearchKeyChange={(value: any) => setSearchKey(value)} 
              searchKey={searchKey} 
              // inputValue={"abc"} 
              setSearchTerm={setSearchTerm} 
            />
          </FlexRowTw>
          {/* <div className='flex gap-2'> */}
          {/* <CustomButton className='pl-2 w-20' onClick={() => { setDisplayFilter(!displayFilter) }}><div className='flex'><FilterIcon /><span>Filter</span></div></CustomButton> */}
          {/* {displayFilter && <div className='absolute w-96 top-96 right-4 rounded-lg'><Filter filterData={Filterdata} setFilter={setFilter} setDisplayFilter={setDisplayFilter} /></div>} */}
          {/* <CustomButton className="pl-2 w-20" onClick={() => { setDisplaySort(!displaySort) }}><div className='flex pl-2'><div className='mr-2 mt-1'><UpDownIcon /></div><span>Sort</span></div></CustomButton> */}
          {/* {displaySort && <div className='absolute top-96 right-6'>
                                    <SortByCard TagsValue={TagsValue} setDisplaySort={setDisplaySort} setSortValue={setSortValue} sortValue={sortValue} setDefaultSortBy={setDefaultSortBy} defaultSortBy={defaultSortBy} displayFilter={setDisplayFilter} />
                                </div>} */}
          {/* </div> */}
        </FlexRowTw>
        <WithCustomDatePicker containerStyle={{
          display: "inline-block",
          position: "absolute",
          right: "0",
          top: "-3.4rem",
          marginRight: "1rem",
        }}
         selectedDateRange={[selectedDateRange.start, selectedDateRange.end]} setSelectedDateRange={(dateRange: any) => {
          setSelectedDateRange({ start: dateRange[0], end: dateRange[1] })
        }}>
          <div style={{ margin: "10px" }}>
            <CustomReactTable
              loading={isLoading}
              data={paginatedData || []}
              pageNumber={currentPage}
              columns={col}
              setPageNumber={setCurrentPage}
              totalPages={data?.total_links}
              checkboxes={false}
              onSelectAll={() => { }}
              pageSize={pageSize}
              selectedRowsKeys={selectedRowKeys}
              defaultSortBy={defaultSortBy}
              handleRowSelect={() => { }}
              isChecked={false}
              loadingRows={10}
              setHoveredRowIndex={setHoveredRowIndex}
              newState={""}
            />
          </div>
        </WithCustomDatePicker>
      </TableContainer>
      <WithSideDrawer isOpen={isDrawerOpen} onClose={() => { setIsDrawerOpen(false) }} title={""} >
        {DrawerComponent && <DrawerComponent setIsDrawerOpen={setIsDrawerOpen} />}
      </WithSideDrawer>
    </Container>
  );
};

export default CheckoutLinks;