import { productSearch } from 'pages/EditOrder/script';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components';
import { DataContext } from 'context/DataProvider';
import { Product, Variant } from '.';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Tooltip } from 'antd';
import { NoDataTableImage } from 'images/icons';
import withCustomScroll from 'components/UIElements/WithCustomScroll';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  max-width: 1000px;
  max-height: 350px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FFF;
  background-color: #fff;
  width: 80%;
  padding: 20px;
  position: relative;
  overflow-y:hidden;
  max-height: 90%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  &:hover {
    color: #e74c3c;
  }
`;

const SearchInputDiv = styled.div`
  display: inline-flex;
  padding: 12px 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #EAEAEA;
  background: var(--Light-Solid-Color-Extra-Card-Background, #FFF);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  color: #8B909A;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  margin-bottom: 20px;
  span{
    margin-right: 4px;
  }

`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 220px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      -webkit-focus-ring-color: none !important;
    }
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow: hidden;
`;

const TableHeader = styled.th`
  color: #6B7280;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  text-align: left;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1;

`;

const TableRow = styled.tr`
  display:block;/* Ensure rows are displayed as table-row */
  table-layout: fixed; /* Prevent layout shifts */
  width: 100%; /* Match the table width */
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const TableData = styled.td`
  padding: 10px;
  color: #555;
  vertical-align: top;
  padding: 10px;
  color: #555;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  display: flex;
  width: 19px;
  height: 19px;
  padding: 6px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #929292;
  accent-color: #6F57E9;
  margin-left:25px;`;


const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectButton = styled.button`
display: flex;
width: 95px;
height: 35px;
padding: 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #6F57E9;
background-color: ${(props) => (props.disabled ? '#cccccc' : '#6F57E9')}; /* Grey background when disabled */
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor: pointer;
`;


const ProductImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 10px;
`;
const TBody=styled.tbody``;
const TbodyWithCustomScroll=withCustomScroll(TBody)

type ProductTableModalProps = {
  isOpen: boolean;
  onClose: () => void;
  control: any;
  name: string;
  isProductPreviewLink:boolean;
};

type SelectedVariant = { productId: number|string; variantId?: number|string };

const ProductTableModal: React.FC<ProductTableModalProps> = ({ isOpen, onClose, control, name ,isProductPreviewLink }) => {
  const prevSelectedProducts = useRef("");
  const { sellerId, channel, sellerDomain: storeUrl, sellerConfig } = useContext(DataContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
  const [selectedProducts, setSelectedProducts] = useState<SelectedVariant[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [checkedProducts,setCheckedProducts]=useState<Product[]>([]);
  const watchedFieldValue = useWatch({ control, name });

  useEffect(() => {
    if (watchedFieldValue?.length > 0) {
      const initialSelected = watchedFieldValue.reduce((acc:SelectedVariant[],product:Product)=>{
         product.variants.forEach((variant)=>(acc.push({variantId:variant.value,productId:product.id})))
         return acc
      },[])
      setCheckedProducts(watchedFieldValue)
      setSelectedProducts(initialSelected);
    }
  }, []);

  const { replace } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearchTerm(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

 

  const submitSelectedProducts=(selectedProducts:SelectedVariant[],tempProducts:Product[])=>{

    //filter variants from seletedProducts which are already present in checkedProducts
    const tempSelectedProducts:SelectedVariant[]=selectedProducts.filter((item)=>!tempProducts.find((product)=>product.id==item.productId&&product.variants.find((variant:any)=>variant.value==item.variantId)))
   for (const selectedItem of tempSelectedProducts) {
      for (const product of products) {
        if(product.id===selectedItem.productId){
          let selectedProduct=tempProducts.find((item)=>item.id===selectedItem.productId)
          if(!selectedProduct) {
            selectedProduct={...product,showDescription:false,productDescription:"",variants:[]}
            tempProducts.push(selectedProduct)
          }
            if(product?.variants) for (const variant of product.variants) {
              if (variant.value === selectedItem.variantId) {
               selectedProduct.variants.push({...variant,qty:1,compareAtPrice:variant.price})
              }
            }
        }
       
      }
    }
    setCheckedProducts(tempProducts)
  }


  

  const { data: searchedProduct } = useQuery(
    ['productDetails', { searchTerm: debouncedSearchTerm, sellerId, storeUrl, channel ,searchType:"product"}],
    productSearch,
    {
      cacheTime: 0,
      staleTime:0,
    }
  );
  useEffect(() => {
    if(!searchedProduct?.products) return
    if (searchedProduct?.products?.length == 0) return
    setProducts(modifySearchedProducts(searchedProduct?.products));
  }, [searchedProduct]);

  useEffect(() => {
    if (prevSelectedProducts.current !== JSON.stringify(selectedProducts)) {
      prevSelectedProducts.current = JSON.stringify(selectedProducts);
      if(products.length>0)
        setTimeout(() => {
          submitSelectedProducts(selectedProducts,checkedProducts);
        }, 200);
    }
  }, [selectedProducts]);

  const modifySearchedProducts=(productsDataFromApi:Product[])=>{
    const modifiedData=productsDataFromApi.reduce((acc:any,product:Product)=>{
      const temp=acc.find((item:any)=>item.id==product.id)
      if(temp){
        const variant={...product,...product.variants[0]}
        delete variant.variants
        temp.variants.push(variant)
      }else {
        const temporary={...product}
        delete temporary.variants
        product.variants[0]={...temporary,...product.variants[0]}
        acc.push(product)
      }
      return acc
    },[])
    return modifiedData

  }

 

 

  const addSelectedProduct=(productId: number|string, variantId: number|string)=>{
    const variantSelected=productId&&variantId;
    const productSelected=!variantSelected;
  
    setSelectedProducts((prevSelected) => {
      // Find the product in the products array using its ID
      const product = products.find((product) => product.id === productId);
      // If the product or its variants are not found, return the current selection
      if (!product) {
        return prevSelected;
      }

   // Check if the product is already selected
      if (productSelected) {
        if(isProductPreviewLink){
          setCheckedProducts([])
          return [
            ...product.variants.map((variant) => ({
              productId,
              variantId: variant.value, // Select each variant by its value
            })),
          ];
        }
        const productAlreadySelected = prevSelected.some((item) => item.productId === productId);
        const allVariantsOfProductAlreadySelected=product.variants.length===prevSelected.filter((item)=>item.productId === productId).length
        if (productAlreadySelected&&allVariantsOfProductAlreadySelected) {
          return prevSelected.filter((item) => item.productId !== productId);
        }
       {
          if (productAlreadySelected) {
            // If the product is already selected, remove all its variants from the selection
            return [...prevSelected.filter((item) => item.productId !== productId), ...product.variants.map((variant) => ({
              productId,
              variantId: variant.value, // Select each variant by its value
            }))];
          }else {
            // If the product is not selected, add all its variants to the selection
            return [
              ...prevSelected,
              ...product.variants.map((variant) => ({
                productId,
                variantId: variant.value, // Select each variant by its value
              })),
            ];
          }
        }   
      }
      else if(variantSelected){
        if(isProductPreviewLink){  
          const variantOfAlreadySelectedProduct=prevSelected.some((item) => item.productId === productId)
          if(variantOfAlreadySelectedProduct) {
            return[...prevSelected,{productId,variantId}]
          }else {
          setCheckedProducts([])
          return [{productId,variantId}]
          }
      }
        const variantAlreadySelected = prevSelected.some(
          (item) => item.productId === productId && item.variantId === variantId
        );
        if(variantAlreadySelected) return prevSelected.filter((item) => (item.productId !== productId || item.variantId !== variantId))
        const variantsOfOtherProductsSelected=prevSelected.some((item)=>(item.productId !== productId))
        if(!variantsOfOtherProductsSelected) return [...prevSelected,{productId,variantId}]
        return[...prevSelected,{productId,variantId}]
      }
    });
  }
 

 
  const removeDeSelectedProduct=(productId: number|string, variantId:number|string )=>{
   
    const variantSelected=productId&&variantId;
    const productSelected=!variantSelected;
    let temp=[]
    if(productSelected) {
      temp=watchedFieldValue.filter((product:Product)=>product.id!=productId)
    }
    if(variantSelected) temp=watchedFieldValue.map((product:Product)=>{
       product.variants=product.variants.filter((variant)=>variant.value!=variantId)
       return product
    })
    
    temp=temp.filter((product:Product)=>product.variants.length>0)
    console.log("removing product",productId, variantId,temp)
    setSelectedProducts(selectedProducts.filter((item)=>
      {
        if(!variantId){
          return item.productId!=productId
        }else return item.productId!=productId||item.variantId!=variantId
      }))
    setCheckedProducts(temp)
  }

  const toggleProductSelection = (productId: number|string, variantId: number,event:ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked) addSelectedProduct(productId,variantId)
    else removeDeSelectedProduct(productId,variantId)
  
  };
  if (!isOpen) return null;
  return (
    <Overlay>
      <ModalContainer>
        <Header>
          <span style={{
  color: "#000",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "0.2px",
}}>Select Products</span>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        <SearchInputDiv >
          <span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
<g clip-path="url(#clip0_7393_111304)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.91401 1.5C6.89745 1.50009 5.89566 1.74327 4.9922 2.20927C4.08875 2.67527 3.30983 3.35056 2.72044 4.17882C2.13104 5.00707 1.74827 5.96426 1.60404 6.97053C1.45981 7.97681 1.55832 9.00298 1.89134 9.96344C2.22436 10.9239 2.78224 11.7908 3.51844 12.4918C4.25463 13.1928 5.14779 13.7076 6.12339 13.9932C7.099 14.2788 8.12877 14.327 9.12677 14.1337C10.1248 13.9404 11.0621 13.5112 11.8605 12.882L14.5995 15.621C14.741 15.7576 14.9304 15.8332 15.1271 15.8315C15.3237 15.8298 15.5118 15.7509 15.6509 15.6119C15.7899 15.4728 15.8688 15.2847 15.8705 15.0881C15.8722 14.8914 15.7966 14.702 15.66 14.5605L12.921 11.8215C13.662 10.8815 14.1234 9.7518 14.2523 8.56179C14.3813 7.37179 14.1726 6.16952 13.6502 5.09257C13.1277 4.01562 12.3127 3.10752 11.2982 2.47217C10.2838 1.83683 9.11098 1.49992 7.91401 1.5ZM3.03901 7.875C3.03901 6.58207 3.55262 5.34209 4.46686 4.42785C5.3811 3.51361 6.62108 3 7.91401 3C9.20694 3 10.4469 3.51361 11.3612 4.42785C12.2754 5.34209 12.789 6.58207 12.789 7.875C12.789 9.16793 12.2754 10.4079 11.3612 11.3221C10.4469 12.2364 9.20694 12.75 7.91401 12.75C6.62108 12.75 5.3811 12.2364 4.46686 11.3221C3.55262 10.4079 3.03901 9.16793 3.03901 7.875Z" fill="#929292"/>
</g>
<defs>
<clipPath id="clip0_7393_111304">
<rect width="18" height="18" fill="white" transform="translate(0.0390625)"/>
</clipPath>
</defs>
</svg></span>
        <SearchInput
          type="text"
          placeholder="Search products"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        </SearchInputDiv>
        <p>Search products as created on {sellerConfig?.platform_details?.channel}</p>
        <Table>
          <thead style={{display: "block",maxHeight:"100px" , overflowY:"scroll"}}>
            <tr style={{background: "#F3F3F3"}}>
              <TableHeader style={{width:'60px'}}><span style={{
  display: "flex",
  width: "19px",
  height: "19px",
  padding: "6px 10px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",
  flexShrink: 0,
  borderRadius: "4px",
  border: "1px solid #929292",
  marginLeft:"25px",
}}></span></TableHeader>
              <TableHeader style={{width:'475px'}}>Product Name</TableHeader>
              <TableHeader style={{width:'250px'}}>SKU</TableHeader>
              <TableHeader style={{width:'250px'}}>Price</TableHeader>
            </tr>
          </thead>
          <TbodyWithCustomScroll style={{display: "block",maxHeight:"20rem" , overflowY:"scroll"}}>
            {products.length>0?products.map((product) => (
              <TableRow key={product.id}>
                <TableData style={{width:'60px'}}>
                  {<Checkbox
                    checked={selectedProducts.some((item) => item.productId === product.id && product.variants.length===selectedProducts.filter((temp)=>temp.productId===product.id).length)}
                    onChange={(e) => toggleProductSelection(product.id,null,e)}
                  />}
                </TableData>
                <TableData style={{width:'475px'}}>
                  <Tooltip title={product.name}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ProductImage src={product.imgUrl} alt={product.name} />
                      <span style={{marginLeft:"5px"}}>{product.name}</span>
                    </div>
                  </Tooltip>
                  {product?.variants?.length>0&&<div style={{ marginTop: '10px', paddingLeft: '30px' }}>
                    {product.variants.map((variant:any) => (
                      <div key={variant.value} style={{ display: 'flex', alignItems: 'center',marginBottom: "10px" }}>
                        <Checkbox
                          checked={selectedProducts.some(
                            (item) =>
                              item.productId === product.id && item.variantId === variant.value
                          )}
                          disabled = {isProductPreviewLink}

                          onChange={(e) => toggleProductSelection(product.id,variant.value,e)}
                          style={{ marginRight: '10px' }}
                        />
                       <div style={{ display: 'flex', alignItems: 'center' ,width:'30px'}}>
                      {variant.imgUrl&&<ProductImage src={variant.imgUrl} />}
                       </div>
                        <span style={{marginLeft:"5px"}}>{variant.label}</span>
                      </div>
                    ))}
                  </div>}
                </TableData>
                <TableData style={{width:'250px'}}><div>{product.sku}
                <div style={{height:"30px"}}></div>
                {product?.variants?.length>0&&<div style={{ marginTop: '10px' }}>
                    {product.variants.map((variant) => (
                      <div key={variant.value} style={{ display: 'flex', alignItems: 'center',marginBottom: "10px"  }}>
                        <span>{variant?.sku}</span>
                      </div>
                    ))}
                  </div>}</div>
                </TableData>
                <TableData style={{width:'250px'}}>
                <div style={{height:"30px"}}></div>
                {product?.variants?.length>0&&<div style={{ marginTop: '10px' }}>
                    {product.variants.map((variant) => (
                      <div key={variant.value} style={{ display: 'flex', alignItems: 'center',marginBottom: "10px"  }}>
                        <span>₹{variant?.price}</span>
                      </div>
                    ))}
                  </div>}
                </TableData>
              </TableRow>
            )):NoDataTableImage("1000") }
          </TbodyWithCustomScroll>
        </Table>
       

        <Footer>
          <span>{selectedProducts?.length} products selected</span>
          <div>
            <SelectButton onClick={()=>{
              replace(checkedProducts)
              onClose();
            }} disabled={selectedProducts?.length===0}>Select</SelectButton>
          </div>
        </Footer>
      </ModalContainer>
    </Overlay>
  );
};

export default ProductTableModal;