import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { mainInputComponentStyle, UsageConfig } from '.';
import CustomInputField from 'components/UIElements/CustomInputField';


const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  font-family: Arial, sans-serif;
`;



const UsageLimit = ({ data, control }: { data: UsageConfig; control: any }) => {
  return (
    <Container>
      <CustomInputField
        name="usage.config.usageLimit"
        label="Usage limit"
        type="number"
        min={1}
        placeholder="Enter maximum number of purchases"
        control={control}
        rules={{
          required: 'Usage Limit is required', 
          min: {
            value: 1,
            message: 'Usage Limit must be at least 1',
          },
        }}
        inputStyle={mainInputComponentStyle}
      />
    </Container>
  );
};

export default UsageLimit;