import React from 'react'
import { WeightRangeContainer } from './style'
import { FlexColContainer, PickrrButton, PickrrHeading } from 'components/UIElements';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';

const CartAttributes = ({cartAttributesList, setCartAttributesList}) => {

    const handleAdd = () => {
        setCartAttributesList((prev) => [
          ...prev,
          {
            key: null,
            value: null,
          },
        ]);
      };
    
    const handleChangeKey = (id, event) => {
        setCartAttributesList((prev) =>
          prev.map((w, index) => {
            if (index === id) {
              return {
                ...w,
                key: event.target.value,
              };
            }
            return w;
          })
        );
      };

    const handleChangeValue =  (id, event) => {
        setCartAttributesList((prev) =>
          prev.map((w, index) => {
            if (index === id) {
              return {
                ...w,
                value: event.target.value,
              };
            }
            return w;
          })
        );
      };

      const handleDelete = (id) => {
        setCartAttributesList((prev) => prev.filter((s, index) => index !== id));
      };

  return (
    <WeightRangeContainer>
        <FlexColContainer>
            <PickrrHeading size={5}>Cart Attributes</PickrrHeading>
            {cartAttributesList.map((s, index)=>(
                <Row gutter={16} className="mb-16" key={index}>
                <Col span={7}>
                  <Input
                    className="mr-12 w-full rounded-lg"
                    size="large"
                    placeholder="Key"
                    value={s.key}
                    onChange={(value) => handleChangeKey(index, value)}
                  />
                </Col>
                <Col span={7}>
                  <Input
                    className="mr-12 w-full rounded-lg"
                    size="large"
                    placeholder="Value"
                    value={s.value}
                    onChange={(value) => {
                     handleChangeValue(index, value);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    size="large"
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => handleDelete(index)}
                  />
                </Col>
              </Row>
            ))}
            <PickrrButton color="outline" onClick={handleAdd}>
                {cartAttributesList.length ? 'Add More' : 'Add'}
            </PickrrButton>
        </FlexColContainer>
    </WeightRangeContainer>
  )
}

export default CartAttributes