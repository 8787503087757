import { useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import QRCode from "react-qr-code";

const useQRCode = () => {
  const svgRef = useRef(null);

  const downloadQRCode = (link) => {
    try {
      if (!link) throw new Error("Invalid link");

      // Generate SVG markup for the QR Code
      const svg = renderToStaticMarkup(<QRCode value={link} />);
      const blob = new Blob([svg], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);

      // Trigger download
      const linkElement = document.createElement("a");
      linkElement.href = url;
      linkElement.download = "qr-code.svg";
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);

      // Revoke URL object after download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading QR code:", error.message);
    }
  };

  return {
    svgRef,
    downloadQRCode,
  };
};

export default useQRCode;