import { notification } from 'antd';
import axiosInstance from '../../HTTP/Http';

export const productSearch = async({queryKey} : any) => {
    const[unusedArgument, {searchTerm, sellerId, storeUrl, searchType, channel, productId}] = queryKey 
  try{
    if((searchTerm || productId) && storeUrl){
        const response = await axiosInstance.get(`${process.env.REACT_APP_CATALOGUE_SERVICE}/api/ve1/catalog-service/search`,
        {params : {
            sellerId: sellerId,
            storeUrl:storeUrl,
            searchType: searchType,
            channel: channel,
            ...(productId && {id: productId}),
            ...(searchTerm && {query: searchTerm}),
        }
        }
        ) 
        return response.data;
    }
  }catch(error){
    notification.error({message:error?.response?.data?.message ? error?.response?.data?.message : 'Product Not Found'})
  }
}

export const getOrderDetails = async ({queryKey} : any) => {
    const [unusedArgument,{orderId,loggedInFromAdmin}] = queryKey;
    if(orderId){
    const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/order/get?id=${orderId}`)
    return response
}
}

export const EditOrderUpdate = async (postData : any) => {
    const response = await axiosInstance.put(`${process.env.REACT_APP_AGGREGATOR_EDGE}/api/ve1/aggregator-service/order/modify`,
        postData
    )
        return response?.data?.orderDetails
}