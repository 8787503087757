import React, { useEffect, useState } from "react";
import { notification, Button, Form } from "antd";
import {
  ModalLeftSide,
  ModalLeftSideHeading,
  StepsText,
  CheckboxIcon,
  StepsContainer,
  StepsFlexBox,
  ModalRightSide,
  DiscountHeading,
  ContentContainer,
  ButtonContainer,
  NextButton,
  ModalContainer,
  StatusContainer,
  RestButton,
} from "./styled";
import { createDiscount, getEditCouponDetails, updateDiscount } from "./script";
import { SpaceBetweenContainer } from "components/UIElements";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import Configuration from "./Configuration/Index";
import DiscountType from "./DiscountType";
import Functionality from "./Functionality";
import DiscountValidity from "../DiscountValidity";
import CriteriaTypes from "./CriteriaTypes";
import DiscountSummary from './DiscountSummary'

import {
  discountTypeData,
  discountConfigData,
  discountFunctionalityData,
  discountValidityData,
  setFormValues,
  customerCriteriaData,
  minimumCartTotalData,
  productCriteriaData,
  quantityCriteriaData,
  usageCriteriaData,
  showErrorMessage,
  bankOfferCriteriaData,
  maximumCartTotalData,
  utmCriteriaData,
  freebieModeData
} from "./util";
import {
  discountSteps,
  topHeading,
  errorMessage
} from "./mapping";

import { useMutation, useQueryClient, useQuery } from "react-query";
import { IDateTime } from "../data";
import moment from "moment";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import PhoneNumber from "components/Settings/CreateRuleModal/AddCriteria/PhoneNumber";

const CreateDiscountModal = ({
  handleCancel,
  setIsSuccessModalOpen,
  couponId,
  editKey,
  couponKey,
  sellerConfigValues
}: any) => {
  const [data, setData] = useState<any>({});
  const [current, setCurrent] = useState<number>(0);
  const [currentMenu, setCurrentMenu] = useState<Number>(1);
  const [value, setValue] = useState<string>("");
  const [discountType, setDiscountType] = useState("prdAmt");
  const [freebieValue,setFreebieValue]=useState<string>("product")
  const [freebieList,setFreebieList]=useState<any>([])
  const [discountMethod, setDiscountMethod] =
    useState<string>("automaticDiscount");
  const[isDynamic,setIsDynamic]=useState<any>(false)

  const[tagsString,setTagsString]=useState("")
  const [configCustomerTypes, setConfigCustomerTypes] = useState<any>({
    buysType: "",
    getsType: "",
  });
  // useEffect(()=>{
  //   console.log('the data is ',data)
  // },[data])

  const [selectTypes, setSelectTypes] = useState<any>({
    bxgyType: "quantity",
    tieredType: null,
    enableLimit: false,
    criteriaType: "",
    configType: "flat",
    haveTiers: false,
    bxgyGetsDiscountType: '',
    bundleType: 'product',
    bundleConfigType: 'percent',
    offerBundleDiscountType: 'offerDiscount'
  });

  const [customerType, setCustomerType] = useState<string>();
  const [criteriaData, setCriteriaData] = useState({});
  const [criteriaStatus, setCriteriaStatus] = useState(false);
  const [customerBuysItems, setCustomerBuysItems] = useState<any>([]);
  const [customerGetsItems, setCustomerGetsItems] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [bundleItem, setBundleItem] = useState<any>([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<any[]>([]);
  const[selectedPinCode,setSelectedPinCode]=useState<any[]>([])
  const[orderCount,setOrderCount]=useState<number>()
  const [discountConfig, setDiscountConfig] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const[tieredFlag,setTieredFlag]=useState<boolean>(false)
  const [dateTime, setDateTime] = useState<IDateTime>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    beginData: "",
  });


  const [criteriaUpdate, setCriteriaUpdate] = useState<any>({
    minimumCart: "",
    minimumQty: "",
    maxUsage: "",
    perCustomerUsage: "",
    maximumCart:"",
    utmFilter : [{}], 
    itemLevel: false,
    paymentMethodsCriteria: false 
  });

  const [discountSummary, setDiscountSummary] = useState<any>({
    couponCode:'',
    typeOfDiscount: '',
    typeOfMethod: '',
    configurationValue: {
      value: '',
      bxgy:'',
      tired:'',
      bundle:''
    },
    minimumPurchase:'',
    minimumQuantity:'',
    customerType: '',
    usageLimitCustomer: ''

  })

  const [activeKey, setActiveKey] = useState<any>([1]);
  const [activeSwitch, setActiveSwitch] = useState<any>({
    visibility: true,
    combination: false,
    discountlink: false,
    caping: false,
    bulkgenerate: false,
    paymentmodes: false
  });


  interface ICardDetails {
    cardType: string | null;
    bankList: string | null;
    network: string | null;
    iin: string | number | null;
    paymentMethod: string | null,
    bankImg?: string | null
  }

  const [cardDetails, setCardDetails] = useState<ICardDetails>({
    paymentMethod: "",
    cardType: "",
    bankList: "",
    network: "",
    iin: "",
    bankImg: "",
  });

  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields();
    setValue("");
    setData({});
    setSelectedPhoneNumbers([]);
    setDiscountMethod("automaticDiscount");
    setCustomerBuysItems([]);
    setCustomerGetsItems([]);
    setBundleItem([]);
    setIsDynamic(false)
    setConfigCustomerTypes({
      ...configCustomerTypes,
      buysType: "",
      getsType: "",
    });
    setSelectTypes({
      ...selectTypes,
      bxgyType: "quantity",
      tieredType: null,
      enableLimit: false,
      criteriaType: "",
      configType: "flat",
      bundleConfigType: "percent",
      bundleType: 'product'
    });
    setProductList([]);
    setCustomerType("");
    setCriteriaData({});
    setCriteriaStatus(false);
    setCurrentMenu(1);
    setCurrent(0);
    setIsLoading(false);
    setCardDetails({
      paymentMethod: "",
      cardType: "",
      bankList: "",
      network: "",
      iin: "",
    })
  };

  //edit data
  const { data: couponsEditData, isLoading: couponsEditLoading, refetch } = 
    useQuery(
      ["editCoupon", { couponId }],
      getEditCouponDetails,
      {enabled: false}
    );

  // getting the edit data;
  useEffect(() => {
    if(couponId && couponKey){
      refetch() 
    }
  }, [couponId, couponKey])
 
  const getConfigType = (conf: any) => {
    const { type, aftersellPercentage, aftersellFlat ,discountPercentage,discountFlat,freebieFlat} = conf;
    // console.log("===================",type,aftersellFlat,aftersellPercentage,freebieFlat,freebiePercentage)
    if (type) {
     return conf.type;
    }


    if (freebieFlat && freebieFlat>0){
      return 'freebieFlat'
    }
    
    if ((aftersellFlat && aftersellFlat > 0)||(discountFlat && discountFlat>0)) {
      return 'flat';
    }
    if ((aftersellPercentage && aftersellPercentage > 0)||(discountPercentage && discountPercentage>0)) {
      return 'percent';
    }
    
 }

  useEffect(() => {
    // console.log(couponsEditData,'------->This is couponsEditData')
    if (couponsEditData?.couponConfig) {
      setFormValues(couponsEditData.couponConfig, form, couponsEditData);
      const discountConfig = couponsEditData?.couponConfig?.discountConfig;
      const discountType = couponsEditData?.couponConfig?.discountType;
      setDiscountType(discountType);
      setTieredFlag(couponsEditData?.couponConfig?.discountConfig?.tiers?.[0]?.itemLevel)
      setCriteriaUpdate(
        {
          ...criteriaUpdate,
            minimumCart: couponsEditData?.couponConfig?.discountCriteria?.minCartTotal,
            maximumCart: couponsEditData?.couponConfig?.discountCriteria?.maxCartTotal,
            minimumQty: couponsEditData?.couponConfig?.discountCriteria?.minQtyProduct,
            itemLevel:couponsEditData?.couponConfig?.discountCriteria?.itemLevel,
            maxUsage: couponsEditData?.couponConfig?.discountCriteria?.discountUsage?.usageLimit,
            perCustomerUsage: couponsEditData?.couponConfig?.discountCriteria?.discountUsage?.customerLimit, 
            utmFilter : couponsEditData?.couponConfig?.discountCriteria?.utmFilter ?? [{}],
            paymentMethodsCriteria: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodsCriteria?.length > 0 ? true : false,
            //exception case
            freebieText : couponsEditData?.couponConfig?.discountConfig?.freebieText
        }
      )

      setCardDetails({
        paymentMethod: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.paymentMethod,
        cardType: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.cardConfig?.type?.length > 1 
        ? 'both' 
        :  couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.cardConfig?.type,
        bankList: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.cardConfig?.banks?.[0] 
        ? couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.cardConfig?.banks?.[0] 
        : couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.issuer?.[0],
        network: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.cardConfig?.network,
        iin: "",
        bankImg: couponsEditData?.couponConfig?.discountCriteria?.paymentMethodFilter?.imageUrl
      })

      setSelectTypes({
        ...selectTypes,
        configType: getConfigType(discountConfig),
        bundleConfigType: discountConfig?.type,
        bundleType: discountConfig?.productFilter?.productType,
        ...(discountType === "tiered" && {
          tieredType: discountConfig?.discountCond,
          haveTiers: true,
        }),
        ...(discountType === "bxgy" && {
          bxgyType: discountConfig?.discountCond,
          enableLimit: discountConfig?.enableLimit,
          bxgyGetsDiscountType: discountConfig?.customerGets?.bxgyPercent ? 'bxgyPercent' : 'bxgyFlat'
        }),
        ...(discountType === "prdAmt" && {
          enableLimit: discountConfig?.enableLimit,
        }),

        ...(discountType === "bundle" && {
          offerBundleDiscountType:  discountConfig?.type === "flatValue" ? "offerDiscountFlat" : 'offerDiscount'
        })

      });

      setDiscountMethod(
        couponsEditData?.type === "fomo"
        ? "fomo"
        : couponsEditData?.couponConfig?.discountMethod?.automaticDiscount
          ? "automaticDiscount"
          : "couponCode"
      );
      if (discountType == "aftersell") {
        setCustomerBuysItems(discountConfig?.productList);
        setCustomerGetsItems(discountConfig?.aftersellList);
        setConfigCustomerTypes({
          ...configCustomerTypes,
          buysType: discountConfig?.productType,
        });
      }

      if (discountType === "bxgy") {
        setCustomerBuysItems([...discountConfig?.customerBuys?.searchList]);
        setCustomerGetsItems([...discountConfig?.customerGets?.searchList]);

        setConfigCustomerTypes({
          ...configCustomerTypes,
          buysType: discountConfig?.customerBuys?.productType,
          getsType: discountConfig?.customerGets?.productType,
        });
      }

      if (discountType === "bundle") {
        setBundleItem([...discountConfig?.productFilter?.searchList]);
        setIsDynamic(discountConfig?.dynamicBundle)
      }

      if (
        couponsEditData?.couponConfig?.discountCriteria?.productFilter?.productType
        // couponsEditData?.couponConfig?.discountCriteria?.productFilter
        //   ?.searchList?.length > 0
      ) {
        setValue(
          couponsEditData?.couponConfig?.discountCriteria?.productFilter
            ?.productType
        );
        couponsEditData?.couponConfig?.discountCriteria?.productFilter
          ?.searchList && couponsEditData?.couponConfig?.discountCriteria?.productFilter
          ?.searchList?.length > 0 && setProductList([
          ...couponsEditData?.couponConfig?.discountCriteria?.productFilter
            ?.searchList,
        ]);

        couponsEditData?.couponConfig?.discountCriteria?.productFilter
          ?.tagsString  && setTagsString(
          couponsEditData?.couponConfig?.discountCriteria?.productFilter
            ?.tagsString,
        );

      }

      setCustomerType(
        couponsEditData?.couponConfig?.discountCriteria?.customerFilter
          ?.customerType
      );
      if(couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerType==="customer_with_x_orders"){
        setOrderCount(couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.orderCount)
      }
      else if(
        couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerType==="customer_in_pincode"
      ){
        const customerPinCodes=
          couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.pincodeList?.map(
            (number:string)=>{
              return {
                number:number,
                id:Math.floor(Math.random()*1000)
              };
            }
          );
          setSelectedPinCode([...customerPinCodes])
      }

      else if (
        couponsEditData?.couponConfig?.discountCriteria?.customerFilter
          ?.customerType==="specificCustomers"
      ) {
        const customerPhones =
          couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerList?.map(
            (number: string) => {
              return {
                number: number,
                id: Math.floor(Math.random() * 1000),
              };
            }
          );
        setSelectedPhoneNumbers([...customerPhones]);
      }
      if (couponsEditData?.couponConfig?.discountConfig?.freebies?.length>0){
        setFreebieList(couponsEditData?.couponConfig?.discountConfig?.freebies )
      }
    }
  }, [couponsEditData, couponsEditLoading]);

  const queryClient = useQueryClient();

  const createCoupon = useMutation(createDiscount, { 
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error: any) => {
      notification.error({ message: error?.response?.data?.message });
      setIsLoading(false);
    },
    onSuccess: () => {
      setIsSuccessModalOpen(true);
      handleCancel();
      reset();
      setIsLoading(false);
      queryClient.invalidateQueries(["fetchSuggestedDiscountData"]);
      queryClient.invalidateQueries(["fetchDiscountData"]);
      // notification.success({ message: "Discount code successfully configured" });
    },
  });

  const updateCoupon = useMutation(updateDiscount, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error: any) => {
      notification.error({ message: error?.response?.data?.message });
      setIsLoading(false);
    },
    onSuccess: () => {
      setIsSuccessModalOpen(true);
      handleCancel();
      reset();
      setIsLoading(false);
      queryClient.invalidateQueries(["fetchSuggestedDiscountData"]);
      queryClient.invalidateQueries(["fetchDiscountData"]);
      // notification.success({ message: "Discount code successfully configured"});
    },
  });



  const editDiscountType = couponsEditData?.couponConfig?.discountType;
  const editDiscountMethod =
    couponsEditData?.couponConfig?.discountMethod.automaticDiscount;
    
  // edit discount type
  const DISCOUNT_TYPE = 
    (data?.discountType || 
      editDiscountType)  !== ("bxgy" || "shipping");
  
  const IS_AUTOMATIC_DISCOUNT =
    data?.discountMethod?.automaticDiscount 
    ? data?.discountMethod?.automaticDiscount 
    : editDiscountMethod
 
  const IS_CONFIG_TYPE_FLAT = 
    (data?.discountConfig?.type ||
      couponsEditData?.couponConfig?.discountConfig?.type) === 'flat';
  
  const SHIPPING_OR_BXGY =
    (data?.discountType || editDiscountType) === "bxgy" ||
    (data?.discountType || editDiscountType) === "shipping";
  
const UPSELLING_OR_FLAT =
  discountType === "aftersell" && selectTypes.configType=='flat';
  
  let isShowingFunctionality:boolean;
  
  // if (
  //   DISCOUNT_TYPE &&
  //   IS_AUTOMATIC_DISCOUNT &&
  //   IS_CONFIG_TYPE_FLAT
  // ) {
  //   isShowingFunctionality = true;
  // } else if (
  //   SHIPPING_OR_BXGY &&
  //   IS_AUTOMATIC_DISCOUNT
  // ) {
  //   isShowingFunctionality = true;
  // } else if (UPSELLING_OR_FLAT) {
  //   isShowingFunctionality = true;
  // }

   if (UPSELLING_OR_FLAT) {
    isShowingFunctionality = true;
  }

  
    const createCouponHandler = (temp: any) => {
     
      const finalData = {
        service: "fastrr",
        type:
            discountType === "aftersell"
            ? discountType
            : discountType === "bankOffer"
            ? 'discount' 
            : discountMethod === "fomo"
            ? "fomo"
            : discountMethod === "automaticDiscount"
            ? "automatic"
            : "discount",
        sellerId: sellerConfigValues?.seller_id,
        couponConfig: {
          ...data,
          ...temp,
          ...(isShowingFunctionality && {
            discountFunctionality: {
              visibility: { active: false },
              combination: { active: false },
              capping: { active: false },
              discountLink: { active: false },
              bulkGenerate: { active: false },
              paymentModes: []
            },
          }),
        },
        ...(couponId && { id: couponId }),
        active: true,
        visibility: false,
        visibilityOrder: -1,
      };

      let updateFinalData = {
        service: "fastrr",
        type:
          discountType == "aftersell"
            ? discountType
            : discountMethod === "fomo"
            ? "fomo"
            : discountMethod === "automaticDiscount"
            ? "automatic"
            : "discount",
        sellerId: sellerConfigValues?.seller_id,
        couponConfig: {
          ...data,
          ...temp,
          ...(isShowingFunctionality && {
            discountFunctionality: {
              visibility: { active: false },
              combination: { active: false },
              capping: { active: false },
              discountLink: { active: false },
              bulkGenerate: { active: false },
              paymentModes: []
            },
          }),
        },
        ...(couponId && { id: couponId }),
        active: true,
        visibility: false,
        visibilityOrder: -1,
      };

      if (couponId) {
        updateFinalData.couponConfig.discountConfig = {
          ...couponsEditData?.couponConfig?.discountConfig,
          ...updateFinalData.couponConfig.discountConfig,
        };
      }
      if (
        couponId &&
        Object?.keys(couponsEditData?.couponConfig?.discountCriteria)?.length >
          0
      ) {
        let modifyCriteria = {
          // product
          ...(updateFinalData?.couponConfig.discountCriteria?.productFilter && {
            productFilter:
              updateFinalData?.couponConfig.discountCriteria?.productFilter,
          }),

          // Minimum Cart Total
          //   ...((data?.discountCriteria?.minCartTotal ||
          //     couponsEditData?.couponConfig?.discountCriteria?.minCartTotal) &&
          //   {minCartTotal: data?.discountCriteria?.minCartTotal
          //   ? data?.discountCriteria?.minCartTotal
          //   : couponsEditData?.couponConfig?.discountCriteria?.minCartTotal
          // }),

          ...(criteriaUpdate?.minimumCart && {
            minCartTotal: criteriaUpdate?.minimumCart,
          }),

          ...(criteriaUpdate?.maximumCart && {
            maxCartTotal: criteriaUpdate?.maximumCart,
          }),
          // Customer
          ...((data?.discountCriteria?.customerFilter ||
            couponsEditData.couponConfig?.discountCriteria?.customerFilter) && {
            customerFilter: {
              ...((data?.discountCriteria?.customerFilter?.customerType ||
                couponsEditData?.couponConfig?.discountCriteria?.customerFilter
                  ?.customerType) && {
                customerType: data?.discountCriteria?.customerFilter
                  ?.customerType
                  ? data?.discountCriteria?.customerFilter?.customerType
                  : couponsEditData?.couponConfig?.discountCriteria
                      ?.customerFilter?.customerType,
              }),

              ...(!data?.discountCriteria?.customerFilter?.customerType &&
                 (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerList)&&
              {
                customerList:couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerList,
                excludeCustomer:couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.excludeCustomer
              }),
              ...(!data?.discountCriteria?.customerFilter?.customerType && 
                (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.orderCount)&&
              {
                orderCount:couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.orderCount
              }),

              ...(!data?.discountCriteria?.customerFilter?.customerType && 
                (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.pincodeList)&&
              {
                pincodeList:couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.pincodeList
              }),

              ...(data?.discountCriteria?.customerFilter?.customerType
                 && (data?.discountCriteria?.customerFilter?.customerList ||
                 (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerList && 
                  data?.discountCriteria?.customerFilter
                ?.customerType === "specificCustomers")) &&
                {
                  customerList : [...(data?.discountCriteria?.customerFilter?.customerList ?? couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.customerList)],
                  excludeCustomer:data?.discountCriteria?.customerFilter?.excludeCustomer ?? couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.excludeCustomer
                }),
              ...(data?.discountCriteria?.customerFilter?.customerType 
                && (data?.discountCriteria?.customerFilter?.orderCount >= 0 || 
                  (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.orderCount >= 0 &&
                     data?.discountCriteria?.customerFilter?.customerType === "customer_with_x_orders")) &&
                {
                  orderCount : data?.discountCriteria?.customerFilter?.orderCount ?? couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.orderCount
                }) ,
              ...(data?.discountCriteria?.customerFilter?.customerType 
                && (data?.discountCriteria?.customerFilter?.pincodeList ||
                   (couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.pincodeList && 
                    data?.discountCriteria?.customerFilter
                ?.customerType === "customer_in_pincode")) &&
                {
                  pincodeList : [...(data?.discountCriteria?.customerFilter?.pincodeList?? couponsEditData?.couponConfig?.discountCriteria?.customerFilter?.pincodeList)]
                })
            },
          }),

          ...((criteriaUpdate?.maxUsage ||
            criteriaUpdate?.perCustomerUsage) && {
            discountUsage: {
              ...(criteriaUpdate?.maxUsage && {
                usageLimit: criteriaUpdate?.maxUsage,
              }),
              ...(criteriaUpdate?.perCustomerUsage && {
                customerLimit: criteriaUpdate?.perCustomerUsage,
              }),
            },
          }),

          ...((criteriaUpdate?.minimumQty || criteriaUpdate?.itemLevel) && {
            minQtyProduct: criteriaUpdate?.minimumQty,
            itemLevel:criteriaUpdate?.itemLevel
          }),

          ...((data?.discountCriteria?.paymentMethodFilter || 
            couponsEditData.couponConfig?.discountCriteria?.paymentMethodFilter )&& {
              paymentMethodFilter: data?.discountCriteria?.paymentMethodFilter 
              ? data?.discountCriteria?.paymentMethodFilter
              : couponsEditData.couponConfig?.discountCriteria?.paymentMethodFilter,
          }),

          //utm parameters
          ...(criteriaUpdate?.utmFilter && {
            utmFilter: criteriaUpdate?.utmFilter?.filter((utm : any) => {
              if (utm.source || utm.medium || utm.campaign)
                return { source: utm.source, medium: utm.medium, campaign: utm.campaign };
            }),
          }),

          ...(criteriaUpdate?.paymentMethodsCriteria && {
            paymentMethodsCriteria: ["PREPAID"]
          }),
        };

        updateFinalData = {
          ...updateFinalData,
          couponConfig: {
            ...updateFinalData.couponConfig,
            discountCriteria: { ...modifyCriteria },
          },
        };
      }

      setIsLoading(true);
      if (couponId) {
        updateCoupon.mutate({
          ...updateFinalData,
        });
      } else {
        createCoupon.mutate({
          ...finalData,
        });
      }
    };


  const handleFormSubmit = (currentMenuKey?: any) => {
    form
      .validateFields()
      .then((values) => {
        setDiscountType(values?.type ? values?.type : discountType);

        // if(discountType === "bankOffer"){
        //   form.setFieldsValue({discountCriteria: 7})
        //   setCurrent(7)
        // }


        if (current === 0) {
          const temp = discountTypeData(values);
          setData({ ...data, ...temp, discountType: values?.type });
        }

        if (current === 1) {
          const temp: any = discountConfigData(
            values,
            discountType,
            customerBuysItems,
            customerGetsItems,
            selectTypes,
            bundleItem,
            discountSummary,
            setDiscountSummary,
            freebieList,
            tieredFlag,
            setTieredFlag,
          );
          setData({ ...data, ...temp });
        }

        if (current === 2) {
          if (currentMenu == 1) {
            const temp = productCriteriaData(values, productList, value,tagsString);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if (currentMenu == 2) {
            const temp = minimumCartTotalData(values);



            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if (currentMenu == 3) {
            const temp = customerCriteriaData(values, selectedPhoneNumbers,selectedPinCode,orderCount);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if (currentMenu == 4) {
            const temp = usageCriteriaData(values);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp},
            });
          }
          if (currentMenu == 5) {
            const temp = quantityCriteriaData(values);
            setCriteriaData({ ...criteriaData, ...temp});
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp},
            });
           
          }
          if (currentMenu == 7) {
            const temp = bankOfferCriteriaData(values, cardDetails?.bankImg);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if (currentMenu == 8) {
            const temp = maximumCartTotalData(values);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if (currentMenu == 6) {
            const temp = utmCriteriaData(values);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
          if(currentMenu == 9) {
            const temp = freebieModeData(values);
            setCriteriaData({ ...criteriaData, ...temp });
            setData({
              ...data,
              discountCriteria: { ...criteriaData, ...temp },
            });
          }
        }

        if (current === 3) {
          const temp = discountFunctionalityData(values);
          if (Object.keys(data?.discountCriteria).length === 0 || 
              ((couponsEditData && couponId) && Object?.keys(couponsEditData?.couponConfig?.discountCriteria)?.length === 0)) {
            setData({
              ...data,
              ...temp,
              discountCriteria: {},
            });
          } else {
            setData({ ...data, ...temp });
          }
        }

        if (current === 4) {
          const temp = discountValidityData(values);
          createCouponHandler(temp);
          // setData({ ...data, ...temp });
        }
        nextStepHandler(currentMenuKey);
      })
      .catch((errorInfo) => {
        notification.error({
          message: errorInfo?.errorFields?.map((value: any, index: any) => (
            <div>
              {index + 1}. {value?.errors?.[0]}
            </div>
          )),
        });
      });
  };
  
  const criteriaProps: any = {
    value,
    setValue,
    selectedPhoneNumbers,
    setSelectedPhoneNumbers,
    customerType,
    setCustomerType,
    productList,
    setProductList,
    discountType,
    currentMenu,
    setCurrentMenu,
    setSelectTypes,
    selectTypes,
    handleSubmit: handleFormSubmit,
    discountMethod,
    sellerConfigValues,
    criteriaUpdate,
    setCriteriaUpdate,
    cardDetails, 
    setCardDetails,
    form,
    selectedPinCode,
    setSelectedPinCode,
    tagsString,
    setTagsString,
    setOrderCount,
    orderCount,
    setData,
    data
  };


  const handleNotificationError=()=>{
    notification.error({
      message: "please select at least one product",
    });
  };
  
  const configProps: any = {
    discountType,
    customerBuysItems,
    setCustomerBuysItems,
    customerGetsItems,
    setCustomerGetsItems,
    configCustomerTypes,
    setConfigCustomerTypes,
    selectTypes,
    setSelectTypes,
    bundleItem,
    setBundleItem,
    form,
    sellerConfigValues,
    freebieValue,
    setFreebieValue,
    freebieList,
    setFreebieList,
    tieredFlag,
    setTieredFlag,
    isDynamic,
    setIsDynamic
  };

  const functionalityDatas: any = couponId
    ? couponsEditData?.couponConfig
    : data;

  const currentStep: any = {
    0: (
      <DiscountType
        setDiscountType={setDiscountType}
        setDiscountMethod={setDiscountMethod}
        reset={reset}
        form={form}
        discountType={discountType}
      />
    ),
    1: <Configuration {...configProps} />,
    2: <CriteriaTypes {...criteriaProps} />,
    3: (
      <Functionality
        data={functionalityDatas}
        discountMethod={discountMethod}
        discountType={discountType}
        selectTypes={selectTypes}
        discountConfig = {discountConfig} 
        form = {form}
        couponId = {couponId}
        sellerConfigValues = {sellerConfigValues}
        activeKey  = {activeKey}
        setActiveKey = {setActiveKey}
        activeSwitch = {activeSwitch} 
        setActiveSwitch = {setActiveSwitch}
      />
    ),
    4: <DiscountValidity dateTime={dateTime} setDateTime={setDateTime} />,
  };


  const nextStepHandler = (currentMenuKey?: any) => {
    const userLimit = Number(form?.getFieldsValue()?.usageLimit);
    const customerLimit = Number(form?.getFieldsValue()?.customerLimit);

    if (current === 2 && currentMenuKey <= 9 && !criteriaStatus) {
      if (userLimit < customerLimit) {
        showErrorMessage(errorMessage.userLimit)
      } else {
        setCurrentMenu(currentMenuKey);
      }
    }else if(current === 2 && discountType === "bankOffer"){
      if((criteriaUpdate.minimumCart && criteriaUpdate.maximumCart) 
      && (criteriaUpdate.minimumCart > criteriaUpdate.maximumCart)){
        showErrorMessage(errorMessage.maxCartTotal)
      }else {
        setCurrent(current + 1);
      }
    } else if (
      current === 0 &&
      (discountType === "shipping" ||
       editDiscountType === "shipping")
     ) {
        setCurrent(current + 2)
      } else if (current === 2 && isShowingFunctionality) {
        if (userLimit < customerLimit){
          showErrorMessage(errorMessage.userLimit)
        }else if (value && currentMenu == 1 && productList.length <= 0) {
          showErrorMessage(errorMessage.criteriaProductLimit)
        }else {
          setCurrent(current + 2);
        }
      }
     else {
      if (current === 1 && discountType === "bundle" && selectTypes.bundleType === "product") {
        (bundleItem?.length > 1 || (isDynamic && bundleItem?.length>0))
          ? setCurrent(current + 1)
          : showErrorMessage(errorMessage.bundleProductSearch);
      }
      //  else if (
      //   current === 1 &&
      //   discountType === "tiered" &&
      //   !selectTypes.haveTiers
      // ) {
      //   notification.error({ message: "please make least one tiered" });
      // }
      else if (current===1 && discountType=="freebie"){
        freebieList.length>0?setCurrent(current+1):notification.error({
          message:"Select atleast one product to add as a Freebie"
        })
        
      }
       else if (
         current === 1 &&
         (discountType === "bxgy" || discountType === "aftersell")
       ) {
         customerBuysItems.length > 0 && customerGetsItems.length > 0
           ? setCurrent(current + 1)
           : notification.error({
               message:
                 "Select least one product/collection for both customer buys and gets",
             });
       } else if (
         (current === 2 &&
        // removing because of amount of product currentMenu == 1 &&
         (discountType === "prdAmt" || discountType=='tiered'))
       ) {
            if (productList.length > 0 || tagsString.length > 0) {
              setCurrent(current + 1);
            } else {
              handleNotificationError();
            }       
        } 

       else if (
        current === 2 &&
        discountType === "bankOffer"
        // currentMenu === 7 
      ) {
        cardDetails?.paymentMethod 
          ? setCurrent(current + 1)
          : notification.error({
              message: "please select the values",
            });
      }
  
       else if (
         current === 2 &&
         Number(form?.getFieldsValue()?.usageLimit) <
           Number(form?.getFieldsValue()?.customerLimit)
       ){
         showErrorMessage(errorMessage.userLimit);
       }else {
         current <= 3 && setCurrent(current + 1);
       }
    }
  };

  const backStepHandler = () => {
    if (current === 2 && (discountType === "shipping" || editDiscountType === "shipping")) {
      setCurrent(current - 2);
    } else if (current === 4 && isShowingFunctionality) {
      setCurrent(current - 2);
    } else if (current >= 0) {
      setCurrent(current - 1);
    }
  };

  return (
    <ModalContainer key={couponKey}>
      <ModalLeftSide>
        <ModalLeftSideHeading>Create Discount</ModalLeftSideHeading>
        <StepsContainer>
          {discountSteps &&
            discountSteps.map((value: string, index: number) => {
              return (
                <StepsFlexBox
                  key={index}
                  style={{
                    display:
                      ((discountType === "shipping" || editDiscountType === "shipping") && index === 1) ||
                      (isShowingFunctionality && index === 3)
                        ? "none"
                        : "flex",
                  }}
                >
                  <CheckboxIcon
                    color={
                      current > index
                        ? "#2D7BE4"
                        : current === index && "#85B2EF"
                    }
                  />
                  <StepsText>{value}</StepsText>
                </StepsFlexBox>
              );
            })}
        </StepsContainer>

        <DiscountSummary 
          data = {data}
          discountConfig = {discountConfig} 
          setDiscountConfig = {setDiscountConfig} 
          editData = {couponsEditData}
          couponId = {couponId}
          current = {current}
        />  

      </ModalLeftSide>

      <ModalRightSide>
        <SpaceBetweenContainer>
          <DiscountHeading>{topHeading[current]}</DiscountHeading>
          {/*//@ts-ignore */}
          <CloseOutlined
            onClick={handleCancel}
            style={{ color: "#000B34", fontSize: 20 }}
          />
        </SpaceBetweenContainer>

        {/* content */}
        <ContentContainer>
          <Form form={form} layout="vertical">
            {currentStep[current]}
          </Form>
        </ContentContainer>

        {/* button buttons */}
        <ButtonContainer>
          {current > 0 && (
            <Button
            /* @ts-ignore */
              icon={<LeftOutlined />}
              type="text"
              onClick={() => {
                if (current > 2) {
                  setCriteriaStatus(false);
                }
                backStepHandler();
              }}
            >
              Back
            </Button>
          )}
          <NextButton
            loading={isLoading}
            onClick={() => {
              if (current === 2) {
                if (!value) {
                  setCriteriaStatus(true);
                }
              }
              handleFormSubmit();
            }}
          >
            Next
          </NextButton>
        </ButtonContainer>
      </ModalRightSide>
    </ModalContainer>
  );
};

export default CreateDiscountModal;
