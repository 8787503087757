import React from 'react';
import { Link } from 'react-router-dom';
import { SubMenuInterface } from './data';
const SubMenu :React.FC<SubMenuInterface>= ({subkey, selectedSubKey, hideSideBar}) => {
    {
        return <div className='mt-1'>
            <ul className="ps-8">
                <button className={` ${selectedSubKey == subkey.name ? 'bg-subkey rounded-lg ' : ""}  transition-width hover:bg-subkey hover:rounded-lg`}>
                    <Link to={subkey.path} className="hover:text-white">
                        <button type="button" className={`${buttonClass} ${hideSideBar ? 'w-16 px-5' : 'w-40 px-2.5'}`}>
                            <span className={`max-h-6  ease-in-out duration-500 ${hideSideBar ? "opacity-0 " : "text-clip overflow-hidden visible text-white"}`}>
                                {subkey.name}
                            </span>
                        </button>
                    </Link>
                </button>
            </ul>
        </div>

    }
}

export default SubMenu;
const buttonClass = 'flex justify-between text-start  items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg ease-in-out duration-500 h-8'
    

    
