import React from "react";
import styled from "styled-components";
import { FormValues } from ".";

const SummaryContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  // width: 30rem;
  // min-width: 30rem;
  width: 100%;
  height: 36.375rem;
  flex-shrink: 0;
`;

const Title = styled.h4`
  font-weight: bold;
  margin-bottom: 16px;
`;

const BoldSectionTitle = styled.h5`
  font-weight: bold;
  margin: 16px 0 8px;
`;

const SectionTitle = styled.h5`
  font-weight: normal;
  margin: 8px 0;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 12px 0;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 20px;
  list-style: none;
`;

const ListItem = styled.li`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  &::before {
    content: "✓";
    color: #4caf50; /* Green color for check mark */
    margin-right: 8px;
    font-weight: bold;
  }

  &.error {
    &::before {
      content: "•";
    }
  }
`;

const summaryConfig = {
  mandatory: [
    {
      label: "Link Details",
      successRules: [(formValues: FormValues) => !!formValues.title],
      success: (formValues: FormValues) => formValues.title,
      error: "Link title is required for internal use",
    },
    {
      label: "Cart Details",
      successRules: [
        (formValues: FormValues) =>
          formValues.selectedProducts && formValues.selectedProducts.length > 0,
      ],
      success: (formValues: FormValues) =>
        `${formValues.selectedProducts.length} product(s) added`,
      error: "You need to select at least 1 product to add to the cart",
    },
  ],
  recommended: [
    {
      label: "Discount",
      successRules: [
        (formValues: FormValues) =>
          !!(
            formValues.discount?.config?.automatic &&
            formValues.discount?.config?.automatic?.discountCode
          ),
      ],
      success: (formValues: FormValues) =>
        `Auto apply discount ${formValues.discount?.config?.automatic?.discountCode || ""} - ${
          formValues.discount?.config?.automatic?.discountValue || ""
        }%`,
      error: "No discount details",
    },
    {
      label: "Usage",
      successRules: [
        (formValues: FormValues) => {
          if(formValues.usage.status) return !!formValues.usage?.config?.usageLimit
          else return true
        }
      ],
      success: (formValues: FormValues) =>
        `${formValues.usage?.config?.usageLimit || 0} purchases`,
      error: "You need to define the maximum number of purchases",
    },
    {
      label: "Return Policy",
      successRules: [
        (formValues: FormValues) => !!formValues.returnPolicy?.config?.value,
      ],
      success: () => "Show return policy",
      error: "No return policy specified",
    },
  ],
  optional: [
    {
      label: "Attribute",
      successRules: [(formValues: FormValues) => !!formValues.attribution?.status],
      success: () => "Attributes added",
      error: "No attribute",
    }
  ],
};

const Summary = ({ formValues }: { formValues: FormValues }) => {
  const renderSection = (sectionConfig: typeof summaryConfig.mandatory, sectionTitle: string) => (
    <div>
      <BoldSectionTitle>{sectionTitle}</BoldSectionTitle>
      {sectionConfig.map((config, index) => {
        const isSuccess = config.successRules.every((rule: any) => rule(formValues));

        return (
          <div key={index}>
            <SectionTitle>{config.label}</SectionTitle>
            <List>
              {isSuccess ? (
                <ListItem>
                  {config.success(formValues)}
                </ListItem>
              ) : (
                <ListItem className="error">{config.error}</ListItem>
              )}
            </List>
          </div>
        );
      })}
    </div>
  );

  return (
    <SummaryContainer>
      <Title>Summary</Title>
      {renderSection(summaryConfig.mandatory, "Mandatory")}
      <Divider />
      {renderSection(summaryConfig.recommended, "Recommended")}
      <Divider />
      {renderSection(summaryConfig.optional, "Optional")}
    </SummaryContainer>
  );
};

export default Summary;