import GTM from "components/Gtm";
import { DataContext } from "context/DataProvider";
import { RechargeIcon, RefundIcon } from "images/icons";
import { RefundButtonTw } from "pages/EditOrderNew/styled";
import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { Tooltip as ReactTooltip } from 'react-tooltip'

type UpdateBooleanState = (data: boolean) => void;
interface NavBarComponentProps {
    title: any;
    setIsViewPricingModalVisible: UpdateBooleanState;
    walletBalance: any;
    setWalletModalVisible: UpdateBooleanState;
    isCollapse: boolean;
    walletDataLoading: boolean;
    walletDetails: any;
    platFormDetails: any;
    sellerConfig: any
}

const NavBar: React.FC<NavBarComponentProps> = ({ title, setIsViewPricingModalVisible, walletBalance, setWalletModalVisible, isCollapse, walletDataLoading, walletDetails, platFormDetails, sellerConfig }) => {
    //  ${isCollapse?'ml-44':'ml-20'}
    const queryClient = useQueryClient();
    const shiprocketToken= localStorage.getItem('ShiprocketToken')
    const cid = localStorage.getItem('cid')
    const {sellerDomain} = useContext(DataContext)

    return <>
        <div className='flex w-screen justify-between bg-white pr-3 pt-6 h-16' >
            <div className={`font-bold text-xl leading-4  ease-in-out duration-300 ml-28`}>
                {title}
                {/* Dashboard */}
            </div>
            {!sellerConfig?.is_native_shopify_functions_checkout &&
                <div className='flex gap-3'>
                    <button className="-mt-4 -ml-3 flex flex-wrap gap-2 pl-3.5 pr-3.5 pt-2.5 pb-2.5  text-rechargeText
                font-medium rounded-lg text-sm px-5 py-2.5 mb-2 justify-center items-center" data-tooltip-id="copilot" data-tooltip-place="bottom-start"
                onClick={()=>{
                    GTM.sendCopilotEvent({domainName:sellerDomain,srCompanyId:cid})
                    window.open(`https://copilot.shiprocket.in/login?source=checkout&token=${shiprocketToken}`)}}
                >

                     <span><img src="https://sr-sidebar.shiprocket.in/assets/images/Copilot.svg" alt=""  height='30' width='30'/></span>
                    <span style={{fontWeight:'600'}}>Shiprocket Copilot</span>
                
                    </button>

                    <ReactTooltip  id="copilot" opacity={1} style={{ background: "#F6F6F6", width:'240px',display:'flex',flexWrap:'wrap',alignContent: 'left', borderRadius: '4px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", color:"black",zIndex:'10',marginTop:'-10px' }}>
                        <div> A brand new AI assistant to help you with queries related to your checkout and products of shiprocket ecosystem.</div>
                    </ReactTooltip>
                
                    <span className="flex gap-1 text-sm leading-4 font-medium"><RechargeIcon /><span className="text-black">{walletDataLoading ?
                        <div role="status" className="max-w-sm animate-pulse text-blue-600">
                            <div className="h-2.5 bg-skeletonGrey rounded w-20 p-2">
                            </div>
                        </div> : `₹${walletBalance}`}</span> </span>
                    <span className="cursor-pointer" onClick={() => { queryClient.invalidateQueries(["WalletData"]) }}>
                        <RefundIcon />
                    </span>
                    <button type='button' className="flex h-8 -mt-2 flex-wrap gap-2 focus:outline-none text-rechargeText bg-recharge font-medium rounded-lg text-sm px-2.5 pt-1.5 mb-2" onClick={() => { setWalletModalVisible(true) }}>Recharge wallet</button>
                    <div className="h-5 border-t sm:border-t-0 sm:border-s border-borderGrey"></div>
                    <button type="button" className="-mt-3 -ml-3 h-10 flex flex-wrap gap-2 pl-3.5 pr-3.5 pt-2.5 pb-2.5  text-black  
                font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => { setIsViewPricingModalVisible(true) }} ><span className="-ml-1 text-rechargeText">₹ Pricing</span></button>

                </div>
            }

        </div>
    </>
}
export default NavBar