import React, { useEffect, useState, useContext, ChangeEvent } from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import moment from 'moment'
import { useQuery } from 'react-query'

import useDeepCompareEffect from 'use-deep-compare-effect'
import { DataContext } from 'context/DataProvider'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomReactTable from 'UiComponents/Table'

import { AOVCard, AmountOfCard, SortByCard, TotalCard, WalletCard } from 'UiComponents/Cards'
import { CustomReactInput } from 'UiComponents/Select'
import { DownIconTable, ExportIcon, FilterIcon, RefundIcon, UpDownIcon } from 'images/icons'
import { Ndata } from 'UiComponents/SideBar/data'
import Filter from 'UiComponents/CustomFilter/Filter'
import { SendNotifications } from 'UiComponents/Notifications/TableNotification'
import FilterNew from 'pages/AbandonedCart/FilterNew'

import { RefundButtonTw } from 'pages/EditOrderNew/styled'
import "../../../pages/Loader.css"
import RefundModal from 'pages/Orders/OrdersTab/Refund/RefundModal'
import { IRefundDetails, ITableRowData } from 'pages/Finance/data'
import WithDownloadButton from 'HOC/WithCustomDownloadButton'
import { fetchOrdersData, handleAllDownload, handleSingleDownload, handleMultipleDownload } from 'pages/Orders/OrdersTab/script'
import { TextStyle, CustomButton, CustomDownloadButton, FlexRowTw, PaymentStatusTag, TableContainerTw, TagsStyle } from 'pages/Orders/OrdersTab/styles'
import styled from 'styled-components'
import WithCustomDatePicker from 'HOC/WithCustomDatePicker'

const TabContainer = styled.div`
  display: flex;
  gap: 40px;
  border-bottom: 2px solid #ddd;
  margin: 20px 0px;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  font-size: 16px;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? "#6f57e9" : "#555")};
  border-bottom: ${(props) =>
    props.isActive ? "3px solid #6f57e9" : "3px solid transparent"};
  padding: 10px 0;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #6f57e9;
  }
`;


const PurchaseLinkOrders = ({ source, activeTab, setActiveTab,location,linkId }: any) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<any>('')
  const [tableData, setTableData] = useState<ITableRowData[]>([])
  const [viewMoreState, setViewMoreState] = useState<any>()
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [rtoFilter, setRTOFilter] = useState<string[]>([])
  const [deliveryFilter, setDeliveryFilter] = useState<string[]>([])
  const [paymentFilter, setPaymentFilter] = useState<string[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [searchKey, setSearchKey] = useState<string>('platform')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0)
  const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
  //refund
  const {  channel } = useContext(DataContext)
  const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);
  const [modeFilter, setModeFilter] = useState<boolean>(false)
  const [showDeliveryFilter, setShowDeliveryFilter] = useState<boolean>(false)
  const modeKeys = ['COD', 'Prepaid']
  const deliveryMethodKeys = ['Rush', 'Standard', 'Express']
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)
  const { state } : any = useLocation();
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    start: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const Paymentmapping: any = {
    'COD': 'cod',
    'Prepaid': 'prepaid',
  }
  const DeliveryMapping: any = {
    'Rush': 'Rush',
    'Standard': 'Standard',
    'Express': 'Express'
  }

  const addOnBefore = [
    { value: 'platform', label: 'Order ID' },
    { value: 'phone', label: 'Phone Number' },
    { value: 'fastrr', label: 'Fastrr Order ID' },
    { value: 'order', label: 'Platform ID' }
  ];

  const getModifiedAddOnBefore = (channel: any) => {
    if (channel === 'woocommerce') {
      return [
        { value: 'order', label: 'Platform ID' },
        { value: 'phone', label: 'Phone Number' },
        { value: 'fastrr', label: 'Fastrr Order ID' }
      ];
    }

    return addOnBefore;
  }

  const modifiedAddOnBefore = getModifiedAddOnBefore(channel);

  const locationState   = location.state;
  
  useEffect(() => {

    if (locationState) {
      setSelectedDateRange((prev:any) => ({start: state.fromDate, end: state.toDate}))
      setDeliveryFilter(locationState?.deliveryFilter)
      setPaymentFilter(locationState?.paymentFilter)
      setRTOFilter(locationState?.rtoFilter)
      setSearchKey(locationState?.searchKey)
      setSearchTerm(locationState?.searchTerm)
      setInputValue(locationState?.searchTerm)
      setPrevPageNumber(1)
    }
  }, [locationState])


  const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin")


  
  const {
    data: ordersData,
    isLoading,
  } = useQuery<any>(
    ['ordersData', { pageNumber, fromDate: moment(selectedDateRange.start).format("YYYY-MM-DD"), toDate: moment(selectedDateRange.end, "YYYY-MM-DD").format("YYYY-MM-DD"), searchTerm, ...(searchTerm && { searchKey }), rtoFilter, paymentFilter, deliveryFilter, source, loggedInFromAdmin,headless:true,linkId }],
    fetchOrdersData,
  )

  useDeepCompareEffect(() => {
    setSelectedRowKeys([])
  }, [selectedDateRange.start, selectedDateRange.end, searchTerm, searchKey, rtoFilter, paymentFilter, deliveryFilter, pageNumber])

  useEffect(() => {
    if (locationState) {
      setTimeout(() => {
        setPageNumber(locationState?.pageNumber)
        navigate(location.pathname, { replace: true })
      }, 100);
    } else {
      setPageNumber(1)
    }
  }, [selectedDateRange.start, selectedDateRange.end])
  useEffect(() => {
    setTableData(ordersData?.orders)
  }, [ordersData])


  useEffect(() => {
    setViewMoreState({
      pageNumber,
      fromDate: moment(selectedDateRange.start).format('YYYY-MM-DD'),
      toDate: moment(selectedDateRange.end).format('YYYY-MM-DD'),
      searchTerm,
      searchKey,
      rtoFilter,
      paymentFilter,
      deliveryFilter,
      source, 
      path: '/purchaseLink/orders'
    })
  }, [pageNumber, selectedDateRange.start, selectedDateRange.end, searchTerm, rtoFilter, paymentFilter, deliveryFilter, source])

  useEffect(() => {
    if (state) {
        setTimeout(() => {
            setPageNumber(state?.pageNumber)
            navigate(location.pathname, { replace: true })
        }, 100);
    } else {
        setPageNumber(1)
    }
  }, [selectedDateRange.start, selectedDateRange.end])






  const PaymentModeText: any = {
    'PREPAID': '#0033E6',
    'PARTIAL_PAID': '#399F50',
    'CASH_ON_DELIVERY': '#E46A11'
  }

  const PaymentModeBg: any = {
    'PREPAID': '#EBEFFD',
    'PARTIAL_PAID': '#ecfaef',
    'CASH_ON_DELIVERY': '#FDF1E8'

  }
  const PaymentStatusColor: any = {
    'Success': '#1F806E',
    'Pending': '#B53600C9',
    'REFUNDED': '#09C4FF',
    'CAPTURED': '#3E75C8',
    'partial_paid': '#453593'
  }

  const isChecked = (key: any, row: any) => {
    return key === row?.orderDetails.orderId
  }
  const onSelectAll = (pageData: any) => {
    setSelectedRowKeys((keys) => keys.length === pageData?.length ? [] : [...pageData?.map((key: any) => {
      return key?.original?.orderDetails?.orderId
    })])
    setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
  }


  const onSearchKeyChange = (value: string) => {
    setSearchKey(value)
  }

  const onSearch = (value: string) => {
    setSearchTerm(value)
    setPageNumber(1)
  }

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      onSearch('')
      setPageNumber(prevPageNumber)
    }
  }

  const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!searchTerm) {
      setPrevPageNumber(pageNumber)
    }
    if (event.target.value) {
      onSearch(event.target.value)
    }
  }



  const sortPaymentTotal = (rowA: any, rowB: any) => {
    const paymentTotalA = rowA?.original?.orderDetails?.subTotal || 0;
    const paymentTotalB = rowB?.original?.orderDetails?.subTotal || 0;
    return paymentTotalA - paymentTotalB;
  };


  const handleRowSelect = (row: any) => {
    const rowIndex = selectedRows.findIndex((r: any) => {
      return r?.original?.orderDetails?.clientOrderId === row?.original?.orderDetails?.clientOrderId
    });
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, row]);
      setSelectedRowKeys([...selectedRowKeys, row?.original?.orderDetails?.orderId])
    }
    else {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.splice(rowIndex, 1);
      setSelectedRows(updatedSelectedRows);
      setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != row?.original?.orderDetails?.orderId)]);
    }
  };

  const viewMoreHandler = (data: any) => {
    console.log(data, 'checking the data');

    if (data?.orderDetails?.orderId) {
      navigate(`/orders/order-details/${data?.orderDetails?.orderId}`,
        {
          state: {
            orderState: data,
            ...viewMoreState
          }
        }
      )
    }
    // return
  }


  const col: object[] = [
    {
      Header: 'Client Order ID',
      disableSortBy: false,
      tooltip: true,
      accessor: (item: any, index: number) => {
        const randomNumber = Math.random()
        const { orderDetails: { clientOrderId = '', fastrrOrderId = '', orderId = '' } = {} } = item ?? {};
        return (
          <>
            {/*// @ts-ignore */}
            <TextStyle className='min-w-24 tracking-wide cursor-pointer' onClick={() => viewMoreHandler(item)}>
              <div className="flex" data-tip data-tooltip-id={`${randomNumber}`} style={{ alignItems: 'center', height: '50px' }}>
                <div>
                  {(channel === 'woocommerce' ? orderId : clientOrderId) || <span className='ml-7'>-</span>}
                </div>
                <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
                  <DownIconTable />
                </div>
              </div>
              <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
                <div className="max-w-32 h-20 text-clear pr-4" onClick={(e) => {
                  e.stopPropagation();
                }}>
                  <div>Fastrr ID</div>
                  <div className="font-normal text-searchColor">{fastrrOrderId || '-'}</div>
                  <div>Platform ID</div>
                  <div className="font-normal text-searchColor">{orderId || '-'}</div>
                </div>
              </ReactTooltip>
            </TextStyle>
          </>

        )

      }
    },
    {
      Header: 'Date & Time',
      disableSortBy: false,
      accessor: (item: any) => {
        const { orderDetails: { orderDate = '' } = {} } = item ?? {};
        return <>
          {/*// @ts-ignore */}
          <TextStyle>
            <div className='w-max'>
              {orderDate ? moment(orderDate).format('Do MMMM, h:mm a') : '-'}
            </div>
          </TextStyle>
        </>
      }
    },
    { 
      Header: 'Purchase Link',
      disableSortBy: false,
      tooltip: true,
      accessor: (item: any) => {
        return <>
          <TextStyle>
              {item?.orderDetails?.purchaseLinkTitle}
          </TextStyle>
        </>
      }
    }
    ,
    {
      Header: 'Customer',
      disableSortBy: false,
      tooltip: true,
      accessor: (item: any) => {
        const randomNumber = Math.random()
        const { orderDetails: { orderId = '' } = {}, customerDetails: { name = '', lastName = '', phone = '', email = '', shippingAddress = '' } = {} } = item ?? {};
        return <div className="flex justify-start items-center" onClick={(e) => {
          e.stopPropagation()
        }}>
          {/*// @ts-ignore */}
          <TextStyle>
            <div className='block max-w-[180px] overflow-hidden text-ellipsis break-normal whitespace-nowrap' data-tip data-tooltip-id={`${randomNumber}`}>{
              (name || lastName) ?
                `${name ?? ''} ${lastName ?? ''}` :
                '-'
            }</div>

            <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

              <div className="w-fit px-2 text-clear" onClick={(e) => {
                e.stopPropagation()
              }}>
                {/*// @ts-ignore */}
                <TextStyle className='font-bold max-w-60 text-wrap'>{name} {lastName}</TextStyle>
                <div>{phone || '-'}</div>
                <div>{email || '-'}</div>
                <div className='max-w-60 text-wrap'><p>{shippingAddress || '-'}</p></div>
              </div>
            </ReactTooltip>
          </TextStyle>
        </div>
      }
    },

    {
      Header: 'Payment Total',
      disableSortBy: false,
      customSort: sortPaymentTotal,
      accessor: (item: any) => {
        const { orderDetails: { amount = 0 } = {} } = item ?? {};
        return (
          <TextStyle className='min-w-24'>
            <div>{amount ? `₹ ${amount.toFixed(2)}` : '-'}</div>
          </TextStyle>
        )

      }
    },
    {
      Header: 'Payment Status',
      disableSortBy: true,
      accessor: (item: any) => {

        {/*// @ts-ignore */ }
        const { orderDetails: { paymentStatus = '' } = {} } = item ?? {};
        const display = paymentStatus
        return (
          <>
            <span className="capitalize pl-4">
              {display &&
                <ul className='list-disc text-xl mb-4'>
                  <li style={{ color: PaymentStatusColor[display] }}>
                  <PaymentStatusTag>
                    <span>{display?.toLowerCase()}</span>
                  </PaymentStatusTag>
                  </li>
                </ul>
              }
            </span>
          </>)
      }
    },

    {
      Header: <span className='flex items-center relative'>
        Payment Mode <span onClick={() => { setModeFilter(!modeFilter) }} className='cursor-pointer'> <FilterIcon /></span>
        {modeFilter && <div className='absolute' style={{ top: "35px", left: '-10px' }}>
          <FilterNew filters={modeKeys} filterKey={paymentFilter} setFilterKey={setPaymentFilter} multipleSelect={false} setIsOpen={setModeFilter} setPageNumber={setPageNumber} mapping={Paymentmapping} /></div>}

      </span>,
      accessor: 'payment-mode-amount',
      disableSortBy: true,
      Cell: ({ cell: { value }, row }: any) => {
        let paymentMethod = row?.original?.orderDetails?.paymentMethod?.toUpperCase()
        return (
          <TextStyle className='min-w-28'>
            <div style={{ color: `${PaymentModeText[paymentMethod] || PaymentModeText['CASH_ON_DELIVERY']}`, background: `${PaymentModeBg[paymentMethod] || PaymentModeBg['CASH_ON_DELIVERY']}` }} className={`flex justify-center items-center py-0.5 rounded-2xl w-fit px-4`}>
              {paymentMethod === 'PREPAID'
                ? 'Prepaid'
                : row?.original?.orderDetails?.paymentMethod === "partial_paid"
                  ? "Partial paid"
                  : 'COD'
              }
            </div>
          </TextStyle>
        )
      }
    },
    {
      Header: <span className='flex items-center relative'>
        Delivery Method <span onClick={() => { setShowDeliveryFilter(!showDeliveryFilter) }} className='cursor-pointer'> <FilterIcon /></span>
        {showDeliveryFilter && <div className='absolute' style={{ top: "35px", left: '-10px' }}><FilterNew filters={deliveryMethodKeys} filterKey={deliveryFilter} setFilterKey={setDeliveryFilter} multipleSelect={true} setIsOpen={setShowDeliveryFilter} setPageNumber={setPageNumber} mapping={DeliveryMapping} /></div>}

      </span>,
      accessor: 'delivery-method',
      disableSortBy: true,
      Cell: ({ cell: { value }, row }: any) => {
        return <>
          <TextStyle>
            {row?.original?.orderDetails?.shippingMethod}
          </TextStyle>
        </>
      }
    },


    {
      Header: 'Tags',
      disableSortBy: true,
      accessor: (item: any) => {
        const { orderDetails: { tags = "" } = {} } = item ?? {};
        return (
          <div className='flex flex-wrap grid-cols-3 gap-2 w-max'>
            {tags && (
              tags?.split(',').map((element: any) => {
                return (
                  <TagsStyle>
                    {element}
                  </TagsStyle>
                )
              }
              ))}
          </div>
        )

      }
    },
    { 
      Header: 'Channel',
      disableSortBy: false,
      tooltip: true,
      // accessor:"channel",
      accessor: (item: any) => {
        const { orderDetails: { tags = "" } = {} } = item ?? {};
        return (
          <TextStyle>
            {item?.orderDetails?.channel}
          </TextStyle>
        )

      }
    },
    {
      Header: 'Payment Gateway',
      disableSortBy: true,
      accessor: (item: any) => {
        const { orderDetails: { gateway = '' } = {} } = item ?? {};
        return (
          <>
            {/*// @ts-ignore */}
            <TextStyle className='min-w-28'>
              {gateway?.toUpperCase() === 'POTLEE' ? 'SETTLE' : gateway || '-'}
            </TextStyle>
          </>
        )
      }
    },
    {
      Header: 'PG Transaction ID',
      disableSortBy: false,
      accessor: (item: any) => {
        const { orderDetails: { pgTransactionId = '', paymentMethod = '' } = {} } = item ?? {};

        return (
          <>
            {/*// @ts-ignore */}
            <TextStyle>
              {pgTransactionId && paymentMethod.toUpperCase() !== "CASH_ON_DELIVERY" ? pgTransactionId : '-'}
            </TextStyle>
          </>
        )

      }
    },
    // { 
    //   Header: 'Shipment Status',
    //   disableSortBy: false,
    //   tooltip: true,
    //   accessor:"shipmentStatus",
    // },
    {
      Header: 'Source',
      disableSortBy: true,
      accessor: (item: any) => {
        const { orderDetails: { source = '' } = {} } = item ?? {};
        return (
          <TextStyle>
            {source || "N/A"}
          </TextStyle>

        )

      }
    },
    {
      Header: 'Items',
      disableSortBy: false,
      tooltip: 'true',
      accessor: (itemObj: any, index: number) => {
        const randomNumber = Math.random()
        const { orderDetails: { items = [], orderFulfillmentStatus = '' } = {}, customerDetails: { shippingAddress = '' } = {} } = itemObj ?? {};
        const status = orderFulfillmentStatus;
        return <div className="w-max" onClick={(e) => {
          e.stopPropagation()
        }} >
          {/*// @ts-ignore */}
          <div className='flex' data-tip data-tooltip-id={`${randomNumber}`} style={{ alignItems: 'center', height: '50px' }}>
            <div>
              <TextStyle>
                {`${items?.length}`} {items?.length > 1 ? 'items' : 'item'}
              </TextStyle>
            </div>
            <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
              <DownIconTable />
            </div>
          </div>
          <ReactTooltip id={`${randomNumber}`} place='bottom-start' border={'1px solid #EAEAEA'} opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

            <div className="h-fit text-clear max-w-fit" onClick={(e) => {
              e.stopPropagation()
            }}>
              <div className='max-w-60 text-wrap'>{shippingAddress || ''}</div>
              <div>{items?.map((item: any) => (
                <>
                  <div className='text-itemsColor font-bold'>{item?.name}  x{item?.qty}</div>
                  <div className={`ml-8 -mt-1 ${status === "Unfulfilled" ? 'text-pendingRed' : 'text-paidGreen'}`}>
                    <ul className='list-disc text-xl'>
                      <li>
                        <span className='text-clear text-xs relative'>{status}</span>
                      </li>
                    </ul>
                  </div>

                </>)
              )}
              </div>
            </div>
          </ReactTooltip>
        </div>
      }
    },
    // { 
    //   Header: 'Delivery Methods',
    //   disableSortBy: false,
    //   tooltip: true,
    //   accessor:"deliveryMethod",
    // },
    {
      Header: 'Discount Total',
      disableSortBy: true,
      accessor: (item: any) => {
        const { orderDetails: { discountAmount = '' } = {} } = item ?? {};
        return (
          <div className="w-max min-w-24">
            <TextStyle>
              {discountAmount ? `₹ ${` `}${discountAmount.toFixed(2)}` : '-'}
            </TextStyle>
          </div>
        )

      }
    },
    {
      Header: 'Discount Name',
      disableSortBy: true,
      accessor: (item: any) => {
        const { orderDetails: { discountCoupon = '' } = {} } = item ?? {};
        return (
          <div className="flex items-center w-max">
            <TextStyle className="w-max">
              {discountCoupon ? `${discountCoupon}` : '-'}
            </TextStyle>
          </div>
        )

      }
    },


  ]
  
  const handleDownload = () => {
    selectedRows?.length ?
      handleMultipleDownload(selectedDateRange.start?.format('YYYY-MM-DD'), selectedDateRange.end?.format('YYYY-MM-DD'), selectedRowKeys.join(','), loggedInFromAdmin, setIsDataDownloading,true,linkId) :
      handleAllDownload(selectedDateRange.start?.format('YYYY-MM-DD'), selectedDateRange.end?.format('YYYY-MM-DD'), searchKey, searchTerm, rtoFilter, deliveryFilter, paymentFilter, source, loggedInFromAdmin, setIsDataDownloading,true,linkId)
  }
  return (
    <>
      <div className="flex flex-col gap-4">
        {/* <button className='bg-codOrange' onClick={()=>{SendNotifications('info','This is info')}}>Hello</button> */}
        <div>
        <WithCustomDatePicker containerStyle={{
                display: "inline-block",
                position: "absolute",
                right: "9rem",
                top: "1rem",
                marginRight:"2rem",
              }} selectedDateRange={[selectedDateRange.start,selectedDateRange.end]} setSelectedDateRange={(dateRange:any)=>{
                setSelectedDateRange({start:dateRange[0],end:dateRange[1]})
                }}>
                <WithDownloadButton downloadButtonContainerStyle={{
                  position: "absolute",
                  right: "0",
                  top: "1rem",
                  marginRight:"1rem",
                }} DownloadButtonComponent={(props) => <CustomDownloadButton {...props}></CustomDownloadButton>} isDataDownloading={isDataDownloading} setIsDataDownloading={setIsDataDownloading} handleDownload={handleDownload} downloadLabel={selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}>
          <TableContainerTw>
            <FlexRowTw className='justify-between'>
              <FlexRowTw>
                <CustomReactInput addOnBefore={modifiedAddOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={channel === 'woocommerce' ? 'Platform ID' : 'Order ID'} inputValue={inputValue} setSearchTerm={setSearchTerm} />
              </FlexRowTw>
            </FlexRowTw>

            {
             <div style={{marginLeft:"2px"}}>
                  <TabContainer>
                    <Tab
                      isActive={activeTab === "Orders"}
                      onClick={() => {
                        setActiveTab("Orders");
                        navigate(linkId?`/purchaseLink/orders?linkId=${linkId}`:`/purchaseLink/orders`); // Use navigate here
                      }}
                    >
                      Orders
                    </Tab>
                    <Tab
                      isActive={activeTab === "Abandoned Cart"}
                      onClick={() => {
                        setActiveTab("Abandoned Cart");
                        navigate(linkId?`/purchaseLink/abandonedCart?linkId=${linkId}`:`/purchaseLink/abandonedCart`); // Use navigate here
                      }}
                    >
                      Abandoned Cart
                    </Tab>
                  </TabContainer>
                  <CustomReactTable
                    loading={isLoading}
                    data={tableData || []}
                    pageNumber={pageNumber}
                    columns={col}
                    setPageNumber={setPageNumber}
                    totalPages={ordersData?.total}
                    checkboxes={false}
                    onSelectAll={onSelectAll}
                    pageSize={20}
                    selectedRowsKeys={selectedRowKeys}
                    defaultSortBy={defaultSortBy}
                    newState={viewMoreState}
                    handleRowSelect={handleRowSelect}
                    isChecked={isChecked}
                    loadingRows={10}
                    setHoveredRowIndex={setHoveredRowIndex}
                  />
                  </div>
             
            }

          </TableContainerTw>
          </WithDownloadButton>
          </WithCustomDatePicker>

        </div>

        {/* <div className="notification-box flex flex-col items-center justify-center fixed  bottom-20 w-full z-50 p-3"/> */}
      </div>
    </>
  )
}

export default PurchaseLinkOrders
