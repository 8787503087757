import React from "react";
import styled from "styled-components";

// Styled Components
const CardContainer = styled.div`
  position:relative;
  display: flex;
  width:32.5%;
  min-height: 11.4575rem;
  padding: 1.6rem;
  flex-direction: column;
  align-items: flex-start;
  // gap: 0.125rem;
  // flex-shrink: 0;
  border-radius: 0.375rem;
  border: 1px solid #D7D7D7;
  background: #FFF;
  box-shadow: 0px 8px 8px 0px rgba(204, 204, 204, 0.43);
  // margin-right:3.6rem;
   @media (max-width: 991px) {
    width: 100%;
  }


`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .6rem;
`;

const IconWrapper = styled.div`
  display:flex;
  width: 3rem;
  height: 3rem;
  padding: 0.78125rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid rgba(211, 211, 211, 0.83);
  background: rgba(211, 211, 211, 0.00);
`;

const TitleWrapper = styled.div`
  width: 11.25rem;
  height: 0.875rem;
  margin-left:0.5rem;
  flex-shrink: 0;
  color: #454545;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.75rem; /* 75% */
`;



const StatusBadge = styled.div<{ status: string }>`
display: inline-flex;
position:absolute;
  right:0;
  top:0;
padding: 0.25rem 0.75rem;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 0.5rem;
border-radius: 0.3125rem;
background: rgba(35, 128, 23, 0.10);
 
  background-color: ${(props) =>
    props.status === "Completed" ? "rgba(35, 128, 23, 0.10)" :  props.status === "In progress" ? "#EBEFFD": "#FDF1E8"};
    color: ${(props) =>
    props.status === "Completed" ? "#238017" :  props.status === "In progress" ? "#0033E6": "#E46A11"};;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem; /* 166.667% */
letter-spacing: 0.00375rem;
`;

const Description = styled.p`
  color: #AEAEAE;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 114.286% */
`;

const Button = styled.button`
display: flex;
width: 6rem;
height: 2.2rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
flex-shrink: 0;
border-radius: 0.5rem;
background: #6F57E9;
  background-color: #6f57e9;
  color: #FFF;
// font-family: Manrope;
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: normal;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #5a45c9;
  }
`;



// TypeScript Props
interface ConfigurableCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  status: "In progress" | "Completed";
  buttonText: string;
  showNotificationBadge?: boolean;
  notificationContent?: string;
  onClick: () => void;
}

const ConfigurableCard: React.FC<ConfigurableCardProps> = ({
  icon,
  title,
  description,
  status,
  buttonText,
  showNotificationBadge = false,
  notificationContent = "",
  onClick,
}) => {
  return (
    <CardContainer>
      <Header>
        <IconWrapper>
        {icon}
          </IconWrapper>
        <TitleWrapper>
         {title}
        </TitleWrapper>
      </Header>
      <StatusBadge status={status}>{status}</StatusBadge>
      <Description>{description}</Description>
      <Button onClick={onClick}>{buttonText}</Button>
    </CardContainer>
  );
};

export default ConfigurableCard;