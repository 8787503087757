import React, { useState } from "react";
import PurchaseLinkOrdersTable from "./purchaseLinkOrders";
import AbandonedCartTable from "./PurchaseLinkAbandonedCart";
import { useLocation } from "react-router-dom";


// Styled Components

const OverViewContainer = (props: any) => {
  const [activeTab, setActiveTab] = useState("Orders");
  const location=useLocation();
  const queryParams=new URLSearchParams(location.search);
  const linkId=queryParams?.get("linkId") ?? null

  return (
    <>      
      {activeTab === "Orders" && <PurchaseLinkOrdersTable activeTab={activeTab} setActiveTab={setActiveTab} location={location} linkId={linkId} />}
      {activeTab === "Abandoned Cart" && <AbandonedCartTable activeTab={activeTab} setActiveTab={setActiveTab} linkId={linkId} />}
    </>
  );
};

export default OverViewContainer;