import { ExportIcon } from "images/icons";
import React, { FC, CSSProperties } from "react";
import styled from "styled-components";

// Styled wrapper for the table and download button
const TableWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: bold;
    padding: 16px;
    height: 32px;
    margin-left: 16px;
    position:relative;
  }

  .loader {
    /* Customize your loader style here */
  }
`;

interface DownloadButtonProps {
  DownloadButtonComponent: React.FC<{
    onClick: () => void;
    disabled: boolean;
    children: React.ReactNode;
  }>;
  isDataDownloading: boolean;
  setIsDataDownloading: (status: boolean) => void;
  handleDownload: () => void;
  downloadLabel: string;
  children: React.ReactNode;
  downButtonContainerClassNames?:string;
  downloadButtonContainerStyle?: CSSProperties; // Optional style prop for the container
}

const WithDownloadButton: React.FC<DownloadButtonProps> = ({
  DownloadButtonComponent,
  setIsDataDownloading,
  handleDownload,
  downloadLabel,
  isDataDownloading,
  children,
  downloadButtonContainerStyle,
  downButtonContainerClassNames
}) => {
  return (
    <TableWrapper>
      {/* Header with Download Button */}
      <div
        className={downButtonContainerClassNames}
        style={downloadButtonContainerStyle} // Dynamically apply styles
      >
        <DownloadButtonComponent
          onClick={() => {
            setIsDataDownloading(true);
            handleDownload();
          }}
          disabled={isDataDownloading}
        >
          {!isDataDownloading ? (
            <>
              <span className="">
                <ExportIcon />
              </span>
              <span>{downloadLabel}</span>
            </>
          ) : (
            <div className="loader" />
          )}
        </DownloadButtonComponent>
      </div>

      {children}
    </TableWrapper>
  );
};

export default WithDownloadButton;