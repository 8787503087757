import { Button, Col, Divider, notification, Row, Skeleton, Radio, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BackButton, CardContainer, CardHeading, Container, DeleteOrderButton, DetailInfo, DetailTitle, EditOrderDetailsButton, OrderInfo, SummaryDetail } from "./styled";
import { LeftOutlined } from '@ant-design/icons';
import { FlexColContainer, PickrrButton,FlexContainer } from "components/UIElements";
import EditCustomerDetailsModal from "./EditCustomerDetailsModal";
import ErrorModal from "./ErrorModal";
import AdjustQuantityModal from "./AdjustQuantityModal";
import AddCustomItemModal from "./AddCustomItemModal";
import DiscountModal from "./DiscountModal";
import ProductSearch from "./ProductSearch";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { EditOrderUpdate, getOrderDetails } from "./script";
import moment from "moment";
import TransactionsDetails from "./TransactionsDetails";
import RefundModal from "pages/Orders/OrdersTab/Refund/RefundModal";
import UtmDetails from "./UtmDetails";
import { DataContext } from "context/DataProvider";


const EditOrder = ()=>{
    const { state } : any = useLocation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [editCustomerModalVisible,setEditCustomerModalVisible] = useState(false)
    const [editCustomerModalData,setEditCustomerModalData] = useState({})
    const [errorModalVisible,setErrorModalVisible] = useState(false)
    const [adjustQuantityModal,setAdjustQuantityModal] = useState(false)
    const [adjustQuantityModalData,setAdjustQuantityModalData] = useState({})
    const [addCustomItemModal,setAddCustomItemModal] = useState(false)
    const [discountModalVisible,setDiscountModalVisible] = useState(false)
    const [discountModalData,setDiscountModalData] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [variantList, setVariantList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editOrderData,setEditOrderData] = useState<any>({})
    const [orderData, setOrderData] =  useState<any>({})
    const [invoiceGenerated,setInvoiceGenerated] = useState(false)
    const [transactionDetails, setTransactionDetails] = useState<any>()
    const [isRefundStatusModalOpen, setIsRefundStatusModalOpen] = useState<boolean>()
    const [editOrderRefundAmount, setEditOrderRefundAmount] = useState<number | string>()
    const [radioValue, setRadioValue] = useState(1);
    const [totalAmount, setTotalAmount] = useState<number | string>();
    const [refundAmount, setRefundAmount] = useState<number | string>();
    const {sellerId,sellerDomain } = useContext(DataContext)
    const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin")


    let orderId = state?.orderState?.orderDetails?.orderId ?  state?.orderState?.orderDetails?.orderId : window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)
    let orderStatus = state?.orderState?.orderDetails?.orderFulfillmentStatus;
    let gateway = state?.orderState?.orderDetails?.gateway;
    let path = state?.path
    // console.log(state?.path, 'checking the path')


    let isEditOrder = orderData?.editOrder;

    const {data: orderDetails, isLoading , isError: fetchingOrderDetailError} = useQuery(
        ['orderData', {orderId,loggedInFromAdmin}],
        getOrderDetails, {
            enabled: !!orderId  
        }
    );

    useEffect(()=>{
        if(fetchingOrderDetailError){
            notification.error({message : 'Something went wrong'})
            navigate(path ,{state})
        }
    },[])
    useEffect(()=>{
        if(orderDetails){
            setOrderData(orderDetails?.data?.orderDetails)
            setTransactionDetails(orderDetails?.data?.transactionsDetails?.all_transactions)
            setTotalAmount(orderDetails?.data?.transactionsDetails?.total_paid_amount);
            setRefundAmount(orderDetails?.data?.transactionsDetails?.total_refunded_amount)

        }
    },[orderDetails])

    const mutation = useMutation(EditOrderUpdate, {
        onMutate: (variables) => {
          // A mutation is about to happen!
          // Optionally return a context containing data to use when for example rolling back
          return { id: 1 };
        },
        onError: (error : any, variables, context) => {
            setInvoiceGenerated(false)
          // An error happened!
          let sanitizedError = JSON.stringify(error?.response?.data?.error?.message)
            if(typeof sanitizedError === "string" && sanitizedError && sanitizedError.includes('message\\":')) {
              notification.error({
                  message : sanitizedError.match(/"message\\":\\"(.+)\\"}/)[1]
              });
            } else {
              notification.error({
                  message : `Something went wrong.`
              });
            }
        },
        onSuccess: (message, variables, data) => {
            if(invoiceGenerated){
                setInvoiceGenerated(false)
                notification.success({message : `Order Updated Successfully`})
                queryClient.invalidateQueries('orderData');
                setIsEdit(false)
            }
          // navigate('/settings/shipping');
        },
    });

    useEffect(()=>{
        if(mutation?.data){
            setEditOrderData(mutation?.data)
        }
    },[mutation.data])


    useEffect(() => {
        if(editOrderData?.payableAmount < 0){
            setEditOrderRefundAmount(Math.abs(editOrderData?.payableAmount))
        }
    }, [editOrderData?.payableAmount])
    
    const handleEditOrder = () => {
        const postData = {
            "orderId": orderData?.id,
            "operation": "OrderEditBegin",
            "channel": orderData?.channel
        }
        mutation.mutate(postData);
        setIsEdit(true)
    }
    
    const handleAdjustQuantity = (values : any)=>{
        setAdjustQuantityModal(true)
        setAdjustQuantityModalData(values)
    }
    const handleDiscount = (values : any)=>{
        setDiscountModalVisible(true)
        setDiscountModalData(values)
    }

    const handleRemoveDiscount = (values : any)=>{
        const postData ={
            "orderId": editOrderData?.id,
            "operation": "OrderEditRemoveLineItemDiscount",
            "channel": editOrderData?.channel,
            "lineItemRequest": {
                "lineItemId": values?.platformId,
                "discountApplicationId": values?.newDiscountId
            }
        }
        mutation.mutate(postData)
    }
    const handleDeleteItem = (values : any)=>{
        const postData = {
            "orderId": editOrderData?.id,
            "operation": "OrderEditSetQuantity",
            "channel": editOrderData?.channel,
            "lineItemRequest": {
                "lineItemId": values?.platformId,
                "quantity": 0
            }
        }
        mutation.mutate(postData)
    }
    const handleRaiseInvoice = ()=>{
        setInvoiceGenerated(true)
        setTimeout(() => {
            const postData = {
                "orderId": editOrderData?.id,
                "operation": "OrderEditCommit",
                "channel": editOrderData?.channel,
            }
            mutation.mutate(postData)
        }, 100);        
    }

    const handleRadioOnChange = (e: any) => {
        setRadioValue(e.target.value);
    };

    const handleSaveInvoice = (e: any) =>{
        if(radioValue === 1){
             handleRaiseInvoice()   
        }else if (radioValue === 2){
            setIsRefundStatusModalOpen(true)
        }
    }



    return(
        <Container>
            <Col span={24}>
                <BackButton 
                /* @ts-ignore */
                icon = {<LeftOutlined
                    style={{
                        color: '#000B3499 !important',
                        cursor: 'pointer',
                    }}
                    />}
                    onClick={()=>{
                        isEdit ? 
                        setErrorModalVisible(true)
                        : navigate(path,{state})
                    }}
                >
                    BACK
                </BackButton>
            </Col>
            
            <Row gutter={[20,20]}>
                <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                {isEdit ? 
                    <CardContainer>  
                        {mutation.isLoading ?
                            <Skeleton active />
                            : 
                            <>    
                            <Col span={24} style={{display : 'flex', justifyContent : 'space-between'}}>
                                <CardHeading>
                                Edit Order
                                </CardHeading>
                            </Col>
                            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
                                <ProductSearch
                                selectedItems = {selectedItems}
                                setSelectedItems = {setSelectedItems}
                                variantList = {variantList}
                                setVariantList = {setVariantList}
                                type = 'product'
                                editOrderData = {editOrderData}
                                setEditOrderData = {setEditOrderData}
                                />
                            </Col>
                            <Divider/>
                            {editOrderData && editOrderData?.lineItems?.map((orderDetails:any)=>{
                                return(
                                    <Row style={{marginBottom : '20px'}}>
                                        <Col style={{display :'flex'}} span={16}>
                                            <img src={orderDetails?.imageUrl} style={{height : '60px', width : '72px', marginRight : '12px'}}/>
                                            <FlexColContainer>
                                                <span style={{color : '#373737', fontWeight : 500}}>
                                                {orderDetails?.name}
                                                </span>
                                                {/* <FlexContainer style={{justifyContent : 'start'}}>
                                                    <DetailTitle>Size: </DetailTitle>
                                                    <DetailInfo>S (146 x 120 cm),</DetailInfo>
                                                    <DetailTitle>Color: </DetailTitle>
                                                    <DetailInfo>Brown</DetailInfo>
                                                </FlexContainer> */}
                                                <FlexContainer style={{justifyContent : 'start'}}>
                                                    <DetailTitle>SKU: </DetailTitle>
                                                    <DetailInfo> {orderDetails?.sku}</DetailInfo>
                                                </FlexContainer>  
                                                <FlexContainer style={{marginTop : '8px',justifyContent : 'start'}}>
                                                    <EditOrderDetailsButton
                                                    onClick={()=>handleAdjustQuantity(orderDetails)}
                                                    >
                                                    Adjust quantity
                                                    </EditOrderDetailsButton>
                                                    {orderDetails?.temporary && (orderDetails?.newDiscountId === null ?
                                                    <EditOrderDetailsButton
                                                    onClick={()=>handleDiscount(orderDetails)}
                                                    >
                                                    Apply Discount
                                                    </EditOrderDetailsButton>
                                                    :
                                                    <DeleteOrderButton
                                                    onClick={()=>handleRemoveDiscount(orderDetails)}
                                                    >
                                                    Remove Discount
                                                    </DeleteOrderButton>)
                                                    }
                                                    <DeleteOrderButton
                                                    onClick={()=>handleDeleteItem(orderDetails)}
                                                    >
                                                    Remove Item
                                                    </DeleteOrderButton>
                                                </FlexContainer> 
                                            </FlexColContainer>                         
                                        </Col>
                                        <Col style={{display :'flex',flexDirection : 'column'}} span={6}>
                                            <span style={{color : '#373737', fontWeight : 500}}>Qty: <span style={{color:'#616161'}}>{orderDetails?.quantity}</span></span>
                                            <span style={{color : '#373737', fontWeight : 500}}>Discount: <span style={{color:'#616161'}}>{`(-₹${orderDetails?.discount})`}</span></span>
                                        </Col>
                                        <Col style={{display :'flex'}} span={2}>
                                            <span style={{color : '#373737', fontWeight : 600}}>
                                                ₹ {orderDetails?.price}
                                            </span>
                                        </Col>
                                    </Row>  
                                )
                            })
                            }
                            </> 
                        }
                    </CardContainer>
                :
                    <CardContainer>  
                        {isLoading ?
                            <Skeleton active />
                            :  
                            <> 
                            <Col span={24} style={{display : 'flex', justifyContent : 'space-between'}}>
                                <Col style={{padding : '0px'}}>
                                    <span style={{color : '#000B34',fontWeight : 700 , fontSize : '16px',}}>
                                    {`Order Id: ${orderData?.id}`}
                                    </span>
                                    <ul style={{display : 'flex', paddingLeft : '15px'}}>
                                        <OrderInfo>
                                        {`${moment(orderData?.createdAt).format('DD/MM/YYYY hh:mm') }`}
                                        </OrderInfo>
                                        <OrderInfo>
                                        {`${orderData?.channel}`}
                                        </OrderInfo>
                                        <OrderInfo style={{color : '#FC9B3D'}}>
                                        {`${orderData?.status}`}
                                        </OrderInfo>
                                    </ul>
                                </Col>
                                <Col>
                                    <PickrrButton 
                                        onClick={()=>handleEditOrder()}
                                        color="checkout-blue"
                                        disabled = {
                                            orderStatus !== "Unfulfilled"
                                            ? true : false
                                        }
                                    >
                                    Edit Order
                                    </PickrrButton>
                                </Col>
                            </Col>
                            <Divider style={{margin : '12px 0'}}/>
                            <Col style={{marginBottom : '20px'}}>
                                <span style={{color : '#000B34',fontWeight : 700 , fontSize : '16px',}}>
                                Item Details 
                                </span>
                            </Col>
                            {orderData?.lineItems?.map((itemDetails : any)=>{
                                return(
                                <Row style={{marginBottom : '20px'}}>
                                    <Col style={{display :'flex'}} span={14}>
                                        <img src={itemDetails?.imageUrl} style={{height : '60px', width : '72px', marginRight : '12px'}}/>
                                        <FlexColContainer>
                                        <span style={{color : '#373737', fontWeight : 500}}>
                                        {itemDetails?.name}
                                        </span>
                                        {/* <FlexContainer style={{justifyContent : 'start'}}>
                                            <DetailTitle>Size: </DetailTitle>
                                            <DetailInfo>S (146 x 120 cm),</DetailInfo>
                                            <DetailTitle>Color: </DetailTitle>
                                            <DetailInfo>Brown</DetailInfo>
                                        </FlexContainer> */}
                                        <FlexContainer style={{justifyContent : 'start'}}>
                                            <DetailTitle>SKU: </DetailTitle>
                                            <DetailInfo>  {itemDetails?.sku}</DetailInfo>
                                        </FlexContainer>   
                                        </FlexColContainer>                         
                                    </Col>
                                    <Col style={{display :'flex'}} span={6}>
                                        <span style={{color : '#373737', fontWeight : 500}}>Qty: {itemDetails?.quantity} </span>
                                    </Col>
                                    <Col style={{display :'flex'}} span={4}>
                                        <span style={{color : '#373737', fontWeight : 600}}>
                                            ₹ {itemDetails?.price}
                                        </span>
                                    </Col>
                                </Row>  
                                )
                            })
                                
                            }
                            </> 
                        }
                    </CardContainer>
                }
                {
                transactionDetails?.length > 0 && 
                    <CardContainer>
                        {isLoading ? 
                            <Skeleton active /> : 
                            <TransactionsDetails transactionDetails = {transactionDetails} />
                        }
                    </CardContainer>
                }
                {
                orderData?.utmParam  && 
                    <CardContainer>
                        {isLoading ? 
                            <Skeleton active /> : 
                            <UtmDetails UtmDetails = {orderData?.utmParam} />
                        }
                    </CardContainer>
                }
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    {isEdit ?
                    <CardContainer>
                        {mutation.isLoading ?
                            <Skeleton active />
                        : 
                            <>
                            <Col span={24}>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Updated total
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        ₹{editOrderData?.totalAmount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Paid by customer
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500, color : '#02C550'}}>
                                        ₹{editOrderData?.amountPaid?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                            {`${editOrderData?.payableAmount < 0 ? 'Refund' : 'Amount to collect'}`}
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                            ₹{editOrderData?.payableAmount < 0 ? Math.abs(editOrderData?.payableAmount?.toFixed(2)) : editOrderData?.payableAmount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>

                                    <Radio.Group onChange={handleRadioOnChange} value={radioValue}>
                                        <Space direction="vertical">
                                            <Radio value={1}>Raise Invoice</Radio>
                                            {((totalAmount > refundAmount) &&
                                                (gateway?.toLocaleLowerCase() === "cashfree" || 
                                                gateway?.toLocaleLowerCase() === "razorpay" ||
                                                gateway?.toLocaleLowerCase() === "billdesk" || 
                                                gateway?.toLocaleLowerCase() === "phonepe" ||
                                                gateway?.toLocaleLowerCase() === "snapmint" || 
                                                gateway?.toLocaleLowerCase() === "payu"  ||
                                                gateway?.toLocaleLowerCase() === "easebuzz" || 
                                                state?.orderState?.paymentMethod === "partial_cod"
                                                )
                                                && sellerDomain!=="cosmix.in" 
                                                ) 
                                                && 
                                                    <Radio value={2}>Refund & Raise Invoice</Radio>
                                            }
                                        </Space>
                                    </Radio.Group>
                                    <PickrrButton 
                                        color="checkout-blue" 
                                        style={{width : '100%', height : '40px', marginTop: 10}}
                                        onClick ={handleSaveInvoice}
                                        loading = {mutation?.isLoading}
                                    >
                                            Save    
                                    </PickrrButton>



                            </Col>
                            <Divider/>
                            <Col span={24}>
                                <CardHeading>
                                Order Summary
                                </CardHeading>
                                <Col span={24}>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                            Subtotal
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                            ₹{editOrderData?.totalPrice?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Tax
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        ₹{editOrderData?.totalTax?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Shipping Charges
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        ₹{editOrderData?.shippingCharges?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        COD Charges
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        {
                                            (editOrderData?.paymentType === "CASH_ON_DELIVERY" ||
                                            editOrderData?.paymentType === "PARTIAL_PAID" )
                                            ? `₹${editOrderData?.codCharges?.toFixed(2)}` : '-'}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Total Discount
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500, color : '#02C550'}}>
                                        -₹{editOrderData?.totalDiscount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Divider style={{margin : '12px 0px'}} />
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                        Total Amount
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                        ₹{editOrderData?.totalAmount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                </Col>
                            </Col>
                            </>
                        }
                    </CardContainer>
                    :
                    <>
                    <CardContainer>
                        {isLoading ?
                            <Skeleton active />
                            :  
                            <Col span={24}>
                                <CardHeading>
                                Order Summary
                                </CardHeading>
                                <Col span={24}>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                            Subtotal
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                            ₹{orderData?.totalPrice?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Tax
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        ₹{orderData?.totalTax?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Shipping Charges
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        ₹{orderData?.shippingCharges?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        COD Charges
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500}}>
                                        {
                                            (
                                                orderData?.paymentType === "CASH_ON_DELIVERY" ||
                                                orderData?.paymentType === "PARTIAL_PAID"
                                            ) ? `₹${orderData?.codCharges?.toFixed(2)}` : '-'}
                                        </SummaryDetail>
                                    </Row>
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail>
                                        Total Discount
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 500, color : '#02C550'}}>
                                        -₹{orderData?.totalDiscount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                    <Divider style={{margin : '12px 0px'}} />
                                    <Row style={{display : 'flex', justifyContent : 'space-between', marginBottom : '12px'}}>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                        Total Amount
                                        </SummaryDetail>
                                        <SummaryDetail style={{fontWeight : 600, fontSize : '16px'}}>
                                        ₹{orderData?.totalAmount?.toFixed(2)}
                                        </SummaryDetail>
                                    </Row>
                                </Col>
                            </Col>
                        }
                    </CardContainer>
                    {!loggedInFromAdmin &&
                        <CardContainer>
                            {isLoading ?
                                <Skeleton active />
                                :  
                                <>
                                <Col span={24}>
                                    <Row style={{justifyContent : 'space-between'}}>
                                    <CardHeading>
                                    Customer Details 
                                    </CardHeading>
                                    <span onClick={()=>{
                                        setEditCustomerModalData(orderData?.shippingAddress)
                                        setEditCustomerModalVisible(true)
                                    }}
                                    style={{fontWeight : 700, fontSize: "15px",lineHeight: "120%",display: "flex",color:" #0C64DB", textDecoration : 'underline', cursor : 'pointer'}}>
                                        Edit
                                    </span>
                                    </Row>
                                    <Col span={24}>
                                        <SummaryDetail style={{fontWeight : 600}}>
                                        {`${orderData?.shippingAddress?.firstName} ${orderData?.shippingAddress?.lastName}`} 
                                        </SummaryDetail>
                                        <Col style={{padding : '0px'}}>
                                        <SummaryDetail >
                                        {orderData?.shippingAddress?.email}
                                        </SummaryDetail>
                                        <SummaryDetail >
                                        {orderData?.shippingAddress?.phone}
                                        </SummaryDetail>
                                        </Col>
                                    </Col>
                                </Col>
                                <Divider/>
                                <Col span={24}>
                                    <CardHeading style={{fontSize : '15px'}}>
                                    Default Address
                                    </CardHeading>
                                    <SummaryDetail style={{fontWeight : 600}}>
                                    {`${orderData?.shippingAddress?.line1}, ${orderData?.shippingAddress?.line2}`}
                                    </SummaryDetail>
                                    <SummaryDetail style={{fontWeight : 600}}>
                                    {orderData?.shippingAddress?.city} - {orderData?.shippingAddress?.pincode}
                                    </SummaryDetail>
                                    <SummaryDetail style={{fontWeight : 600}}>
                                    {orderData?.shippingAddress?.state}
                                    </SummaryDetail>
                                </Col>
                                </>
                            }                        
                        </CardContainer>
                    }
                    </>
                    }
                </Col>
            </Row>
            <EditCustomerDetailsModal
            isVisible = {editCustomerModalVisible}
            setIsVisible = {setEditCustomerModalVisible}
            editCustomerModalData= {editCustomerModalData}
            setEditCustomerModalData= {setEditCustomerModalData}
            orderData = {orderData}
            />
            <ErrorModal
                isVisible = {errorModalVisible}
                setIsVisible = {setErrorModalVisible}
                setIsEdit = {setIsEdit}
                setEditOrderData = {setEditOrderData}
                setSelectedItems = {setSelectedItems}
                setVariantList = {setVariantList}
            />
            <AdjustQuantityModal
                isVisible = {adjustQuantityModal}
                setIsVisible = {setAdjustQuantityModal}
                adjustQuantityModalData ={adjustQuantityModalData}
                setAdjustQuantityModalData = {setAdjustQuantityModalData}
                editOrderData = {editOrderData}
                setEditOrderData = {setEditOrderData}
            />
            {/* <AddCustomItemModal
                isVisible = {addCustomItemModal}
                setIsVisible = {setAddCustomItemModal}
            /> */}
            <DiscountModal
                isVisible = {discountModalVisible}
                setIsVisible = {setDiscountModalVisible}
                DiscountModalData = {discountModalData}
                editOrderData = {editOrderData}
                setEditOrderData = {setEditOrderData}
            />
            <RefundModal 
                isRefundStatusModalOpen= {isRefundStatusModalOpen}
                setIsRefundStatusModalOpen= {setIsRefundStatusModalOpen}
                activeKey = {orderData?.id}
                // refundDetails = {refundDetails}
                // setRefundDetails = {setRefundDetails}
                editOrderRefundAmount = {editOrderRefundAmount}
                editOrderId = {orderId}
                callBack = {handleRaiseInvoice}
                setIsEdit = {setIsEdit}
            /> 
        </Container>
    )
}

export default EditOrder