import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const CustomDatePicker = styled(RangePicker)`
  &.ant-picker {
    border: 0.08rem solid #EAEAEA;
    border-radius: 0.5rem;
    width: 100%; /* Adjust width */
    height: 100%; /* Adjust height */
    font-size: 0.875rem; /* Adjust font size if needed */

    .ant-picker-input {
      height: 100%; /* Ensures proper height alignment */
    }
    .ant-picker-input > input{
      font-size: .75rem;
    }
    .ant-picker-input > input:focus{
      outline:none;
      border:none !important;
    }
  }
.ant-picker-range-separator{
padding:0px;
}
  .ant-picker-separator {
    margin: 0 0rem; /* Adjust separator spacing */
  }

  .ant-picker-suffix {
    height: 100%; /* Centers the icon vertically */
  }
`



// Styled Components
const CustomRangePickerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1% 2%;
  width: 100%;
  border: .08rem solid #e0e0e0;
  border-radius: .5rem;
  box-shadow: 0rem .25rem 1rem rgba(0, 0, 0, 0.04);


  .calendar-icon {
    color: #929292;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
  }

  .ant-picker {
    border: none;
    box-shadow: none;
  }

  .ant-picker-input > input {
    font-size: 1.15rem;
    font-weight: 500;
    color: #4a4a4a;
  }

  .ant-picker-separator {
    color: #b0b0b0;
    margin: 0 .5rem;
  }

  .ant-picker-suffix {
    margin-left: auto;
  }

  .ant-picker-dropdown {
    border-radius: .5rem;
    box-shadow: 0rem .25rem 1rem rgba(0, 0, 0, 0.1);
  }

  .arrow-icon {
    color: #929292;
    font-size: 1.15rem;
    display: flex;
    align-items: center;
  }
`;

const CustomRangePicker = ({ selectedDateRange, setSelectedDateRange }: any) => {
  const handleRangeChange = (dates: any) => {
    setSelectedDateRange(dates);
  };

  return (
    <CustomRangePickerContainer>
      <span className="calendar-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1rem"
          height="1rem"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M12.8052 2.66406H2.71726C1.92134 2.66406 1.27612 3.26102 1.27612 3.9974V13.3307C1.27612 14.0671 1.92134 14.6641 2.71726 14.6641H12.8052C13.6011 14.6641 14.2463 14.0671 14.2463 13.3307V3.9974C14.2463 3.26102 13.6011 2.66406 12.8052 2.66406Z"
            stroke="#929292"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6429 1.33203V3.9987"
            stroke="#929292"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.87964 1.33203V3.9987"
            stroke="#929292"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.27612 6.66406H14.2463"
            stroke="#929292"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <CustomDatePicker
      allowClear={false}
        value={[
          selectedDateRange[0] ? moment(selectedDateRange[0]) : null,
          selectedDateRange[1] ? moment(selectedDateRange[1]) : null,
        ]}
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Weekly": [moment().subtract(6, "d"), moment()],
          "This Month": [moment().startOf("month"), moment()],

          "Last 30 Days": [moment().subtract(29, "days"), moment()],
        }}
        format="MMM DD, YYYY"
        disabledDate={(current) => {
          return current && current > moment();
        }}
        onChange={handleRangeChange}

        suffixIcon={
          <span >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.91284 7.61328L10.5065 12.3855L15.1001 7.61328"
                stroke="#929292"
                strokeWidth="1.76"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </span>
        }
        separator={<span className="ant-picker-separator">-</span>}
        placeholder={["Start Date", "End Date"]}
      />
    </CustomRangePickerContainer>
  );
};

export default CustomRangePicker;