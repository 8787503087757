let containerId = "";
const initialiseGTM = ( clientContainerId) => {
    containerId= clientContainerId;
    if(containerId){
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", `${containerId}`);
    }
  
  };
  const pushData = (data) => {
    try{
      if(window?.dataLayer)window?.dataLayer?.push(data);
    }catch(error ){
      console.error(error);
    }
  };
  const sendCopilotEvent=(data)=>{
    let obj = {
      event: "checkout_dashboard_copilot",
      domainName:data?.domainName,
      srCompanyId: data?.srCompanyId || " "
    };
      pushData(obj)
  }

  const GTM= {
    initialiseGTM,
    pushData,
    sendCopilotEvent
  }
  export default GTM;