import React from "react";
import styled from "styled-components";

// Styled Components
const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  // width: ${(props) => (props.isOpen ? "100%" : "0")};
  width: ${(props) => (props.isOpen ? "100%" : "0")};
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  overflow: auto;
  transition: width 0.01s ease-in-out;
`;

const DrawerContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: fit-content;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 3;
  transform: ${(props) => (props.isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Make the drawer scrollable */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  position:sticky;
  top:0;
  // background: #ffffff;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

// TypeScript Props Interface
interface SideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

// Higher-Level Side Drawer Component
const WithSideDrawer: React.FC<SideDrawerProps> = ({ isOpen, onClose, title, children }) => {
  return (
    <>
      <Overlay isOpen={isOpen} onClick={onClose} />
      <DrawerContainer isOpen={isOpen}>
        <Header>
          <span>{title}</span>
          <CloseButton onClick={onClose}>×</CloseButton>
        </Header>
        {children}
      </DrawerContainer>
    </>
  );
};

export default WithSideDrawer;