import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const ScrollTrack = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 8px; /* Scrollbar track width */
  height: 100%;
  background-color: #e0e0e0; /* Track background color */
  border-radius: 4px;
`;

const ScrollThumb = styled.div`
  position: absolute;
  right: 0;
  width: 8px; /* Scrollbar thumb width */
  background-color: #7d5cff; /* Thumb color */
  border-radius: 4px;
  cursor: pointer;
`;

const withCustomScroll = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [thumbHeight, setThumbHeight] = useState(0);
    const [thumbTop, setThumbTop] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartY, setDragStartY] = useState(0);
    const [isScrollable, setIsScrollable] = useState(false);

    const updateThumb = () => {
      const content = contentRef.current;
      if (content) {
        const { scrollHeight, clientHeight, scrollTop } = content;

        // Check if scrolling is required
        setIsScrollable(scrollHeight > clientHeight);

        if (scrollHeight > clientHeight) {
          // Calculate thumb size and position
          const calculatedThumbHeight = Math.max(
            (clientHeight / scrollHeight) * clientHeight,
            20
          ); // Minimum thumb height: 20px
          const calculatedThumbTop = (scrollTop / scrollHeight) * clientHeight;

          setThumbHeight(calculatedThumbHeight);
          setThumbTop(calculatedThumbTop);
        }
      }
    };

    const handleScroll = () => {
      updateThumb();
    };

    const handleThumbMouseDown = (event: React.MouseEvent) => {
      event.preventDefault();
      setIsDragging(true);
      setDragStartY(event.clientY - thumbTop);
      document.addEventListener("mousemove", handleThumbMouseMove);
      document.addEventListener("mouseup", handleThumbMouseUp);
    };

    const handleThumbMouseMove = (event: MouseEvent) => {
      if (!isDragging || !contentRef.current) return;

      const content = contentRef.current;
      const { scrollHeight, clientHeight } = content;

      // Calculate new thumb top position
      const newThumbTop = Math.min(
        Math.max(0, event.clientY - dragStartY),
        clientHeight - thumbHeight
      );

      // Calculate new content scroll position
      const newScrollTop = (newThumbTop / clientHeight) * scrollHeight;

      // Apply the calculated scroll position
      content.scrollTop = newScrollTop;

      // Update thumb position
      setThumbTop(newThumbTop);
    };

    const handleThumbMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleThumbMouseMove);
      document.removeEventListener("mouseup", handleThumbMouseUp);
    };

    useEffect(() => {
      const content = contentRef.current;
      if (content) {
        updateThumb();
        content.addEventListener("scroll", handleScroll);
      }
      return () => {
        content?.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <div style={{ position: "relative", height: "100%",width:"100%" }}>
        {/* Pass contentRef to the wrapped component */}
        <Component
          ref={contentRef}
          {...props}
          className={
            props.className ? props.className + " no-scrollbar" : "no-scrollbar"
          }
        />
        {isScrollable && (
          <ScrollTrack>
            <ScrollThumb
              style={{ height: `${thumbHeight}px`, top: `${thumbTop}px` }}
              onMouseDown={handleThumbMouseDown}
            />
          </ScrollTrack>
        )}
      </div>
    );
  };
};

export default withCustomScroll;