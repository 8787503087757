import axios from "axios"
import { GetAuthToken } from 'globalQuries'
import { Product, utmCustomAttributes } from "..";
import axiosInstance from "HTTP/Http";
import UsageLimit from "../UsageLimit";

const auth = GetAuthToken();
type CartData = {
  label?: string,
  img_url?: string,
  sku?: string,
  compareAtPrice?: number,
  product_id: string;
  variant_id: number;
  quantity: number;
  price: number;
  showDescription?: boolean,
  product_description: string;
}
type UsageLimit = {
  infinite_inventory: boolean;
  limit_count: number;
}


type ReturnPolicy = Partial<{
  show_return_policy: boolean;
  generic_return_policy: boolean;
  seller_wise_generic_return_policy: boolean;
  value: string;
}>

type DiscountConfig = {
  discount_id: string;
  discount_mode: string; // Use specific values like "FLAT", "PERCENTAGE" as string literal types if applicable
  discount_application_type: string; // Use specific values like "AUTOMATIC", "MANUAL" as string literal types if applicable
  discount_name: string;
  value: number;
  active: boolean;
}
type UtmConfig = Partial<{
  utm_id: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  customs_att_object: utmCustomAttributes[]
}>


const getUsageConfig = (data: any): UsageLimit => {
  const usageLimit: UsageLimit = {
    "infinite_inventory": false,
    "limit_count": 0
  }

  if (data.status) {
    usageLimit.infinite_inventory = true
    usageLimit.limit_count = data?.config?.usageLimit
  }
  return usageLimit
}

const generateDiscountFromConfig = (discountConfig: DiscountConfig[]): any => {
  const discount: any = {
    config: {
      automatic: null,
      nonAutomatic: [],
    },
    status: false,
  };

  for (const config of discountConfig) {
    if (config.discount_application_type === "AUTO_COUPON") {
      discount.config.automatic = {
        id: config.discount_id,
        discountType: config.discount_mode === "FLAT" ? "₹" : "%",
        discountCode: config.discount_name,
        discountValue: config.value,
      };
      discount.status = config.active; // Use the active status
    } else if (config.discount_application_type === "COUPON") {
      discount.config.nonAutomatic.push({
        id: config.discount_id,
        discountType: config.discount_mode === "FLAT" ? "₹" : "%",
        discountCode: config.discount_name,
        discountValue: config.value,
      });
      discount.status = config.active; // Ensure the active status aligns
      discount.config.couponEnabled = config.active; // Assume couponEnabled is tied to the active flag
    }
  }

  return discount;
};


const getDiscountConfig = (discount: any): DiscountConfig[] => {
  const discountConfig: DiscountConfig[] = []

  for (let key in discount.config) {
    if (key == "automatic") {
      const temp = discount.config[key]
      discountConfig.push({
        "discount_id": temp?.id,
        "discount_mode": temp?.discountType === "₹" ? "FLAT" : "PERCENT",
        "discount_application_type": "AUTO_COUPON",
        "discount_name": temp?.discountCode,
        "value": temp?.discountValue,
        "active": discount.status
      })
    }
    else if (key == "nonAutomatic") {
      const temp = discount.config[key]
      for (let coupon of temp) {
        discountConfig.push({
          "discount_id": coupon.id,
          "discount_mode": coupon.discountType === "₹" ? "FLAT" : "PERCENT",
          "discount_application_type": "COUPON",
          "discount_name": coupon.discountCode,
          "value": coupon.discountValue,
          "active": discount.status && discount.config.couponEnabled

        })
      }
    }
  }


  return discountConfig
}

const generateDataFromUtmConfig = (utmConfig: UtmConfig): any => {
  const data: any = {
    status: false,
    config: {},
  };

  if (utmConfig&&Object.keys(utmConfig).length>0) {
    data.status = true; // Set status to true since utmConfig exists
    const customAttributeEnabled= utmConfig.customs_att_object&&utmConfig.customs_att_object.length>0
    data.config = {
      utmCampaign: utmConfig.utm_campaign,
      utmContent: utmConfig.utm_content,
      utmId: utmConfig.utm_id,
      utmMedium: utmConfig.utm_medium,
      utmSource: utmConfig.utm_source,
      utmTerms: utmConfig.utm_term,
      customAttributeEnabled: customAttributeEnabled, // Enable custom attributes if present
      customAttributes: customAttributeEnabled&&utmConfig.customs_att_object,
    };

  }

  return data;
};


const getReturnPolicyConfig = (data: any): ReturnPolicy => {
  const returnPolicy: ReturnPolicy = {}
  returnPolicy.show_return_policy = data.status
  returnPolicy.generic_return_policy = data?.config?.applyToPreviousLinks
  returnPolicy.seller_wise_generic_return_policy = data?.config?.applyToPreviousLinks
  returnPolicy.value = data?.config?.value
  return returnPolicy
}


const getUtmConfig = (data: any): UtmConfig => {
  const utmConfig: UtmConfig = {}

  if (data.status) {
    const utmObject = data.config
    utmConfig.utm_campaign = utmObject.utmCampaign
    utmConfig.utm_content = utmObject.utmContent
    utmConfig.utm_id = utmObject.utmId
    utmConfig.utm_medium = utmObject.utmMedium
    utmConfig.utm_source = utmObject.utmSource
    utmConfig.utm_term = utmObject.utmTerms
    if (utmObject.customAttributeEnabled && utmObject?.customAttributes?.length > 0) {
      utmConfig.customs_att_object = utmObject?.customAttributes
    }
  }
  return utmConfig
}

const getCartData = (selectedProducts: Product[]): CartData[] => {
  const cartData: CartData[] = []
  for (let product of selectedProducts) {
    for (let variant of product.variants) cartData.push({ product_id: product?.id, variant_id: variant?.value, quantity: variant?.qty, price: variant?.price, product_description: product?.productDescription })
  }

  return cartData
}
const fieldMapping = {
  link_type: "linkType",
  cart_data: "selectedProducts",
  discount_config: "discount",
  utm_config: 'attribution',
  usage_limit: 'usage',
  return_policy:"returnPolicy",
};


const swapFields = (data: any, mapping: any) => {
  const swappedData: any = {};

  // Iterate through the object keys
  for (const [key, value] of Object.entries(data)) {
    // Use the mapping to determine the new key, or keep the original
    const newKey = mapping[key] || key;
    // console.log(newKey,"newKey",mapping[key],key,mapping)
    swappedData[newKey] = value;
  }

  return swappedData;
};

const modifyLinkGetData = (data: any) => {

  let aa = swapFields(data, fieldMapping)


  aa.discount = generateDiscountFromConfig(aa?.discount)
  aa.attribution = generateDataFromUtmConfig(aa?.attribution)
  aa.usage = {
    status: aa?.usage?.infinite_inventory,
    config: {
      usageLimit: (aa?.usage?.infinite_inventory && aa?.usage?.limit_count) || 0
    }
  }

  aa.selectedProducts = aa?.selectedProducts?.reduce((acc: Product[], variant: CartData) => {   
    const variantData = {
      label: variant?.label,
      price: variant?.price,
      qty: variant?.quantity,
      value: variant?.variant_id,
      productId: variant?.product_id,
      sku: variant?.sku,
      compareAtPrice: variant?.price,
      imgUrl: variant?.img_url,
      name: variant?.label
    }
    const product = acc.find((item: Product) => item.id == variant.product_id)
    if (product) product.variants.push(variantData)
    else {
      acc.push({ id: variantData?.productId,showDescription: variant?.showDescription,productDescription: variant?.product_description, name:"",variants: [variantData] })
    }

    return acc
  }
    , [])
  aa.returnPolicy={
      status:aa?.returnPolicy?.show_return_policy,
      config:{
        value:aa?.returnPolicy?.value,
        applyToPreviousLinks:aa?.returnPolicy?.generic_return_policy,
      }
    }
  console.log("aa is",aa)
  return aa
}

const modifyLinkCreationData = (data: any) => {
  type CreateLinkRequest = Partial<{
    link_id:string;
    link_type: string; // Use "CHECKOUT_LINK" or similar predefined values as a string literal type if applicable
    title: string;
    usage_limit: UsageLimit;
    discount_config: DiscountConfig[];
    cart_data: CartData[];
    // return_policy: ReturnPolicy;
    product_description: ProductDescription;
    utm_config: UtmConfig; // Adjust if specific properties exist
    domain: string;
    edd_config_headless: EddConfigHeadless;
    id: string | null | '', 
    active?: boolean
  }>


  type ProductDescription = {
    seller_wise_generic_description: boolean;
    show_product_description: boolean;
    generic_description: boolean;
    product_description: string;
  }

  type EddConfigHeadless = {
    seller_wise_generic_edd: boolean;
    show_standard: boolean;
    pickup_pincode: number;
    flat_charges: number;
    show_edd: boolean;
    product_wise_edd: boolean;
  }

  // Initialize createLinkRequest with default values
  const createLinkRequest: CreateLinkRequest = {};

  // Dynamically update the createLinkRequest object based on data
  if (data.linkType) createLinkRequest.link_type = data.linkType;
  if (data.title) createLinkRequest.title = data.title;
  if(data.id) createLinkRequest.link_id=data.id;
  // createLinkRequest.return_policy = getReturnPolicyConfig(data.returnPolicy)
  createLinkRequest.usage_limit = getUsageConfig(data.usage)
  createLinkRequest.discount_config = getDiscountConfig(data.discount)
  createLinkRequest.utm_config = getUtmConfig(data.attribution)
  createLinkRequest.cart_data = getCartData(data.selectedProducts)
  if(!data.domain?.includes('shiprocket')){
    createLinkRequest.domain = data.domain
  }

  createLinkRequest.active =  data?.active

  return createLinkRequest;
};


export const createLink = async (data: any) => {
  try {
    const createLinkRequest = modifyLinkCreationData(data);
    
    // Assuming this modifies the request body as needed
    const auth: any = GetAuthToken();
    const userToken = localStorage.getItem("userManagementToken");

    // Determine the URL based on whether `data.id` exists
    // const url = data.id
    //   ? `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/headless/purchase_link`
    //   : `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/headless/purchase_link`;

    const url = `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/headless/purchase_link`;

    // Make the API request
    const response = await axios.post(url, createLinkRequest, {
      headers: {
        "X-AUTH": auth,
        "User-Token": userToken,
      },
    });

    // Check for status and return data
    if (response?.data?.status) {
      return response.data.data;
    } else {
      throw new Error(response?.data?.message || "Failed to create link");
    }
  } catch (error) {
    console.error("error is",error)
    if (error.response) {
      const { status, data } = error.response;
      if (status >= 400 && status < 500) {
        throw new Error(data?.message||"An unknown error occurred");
      }
    }
    // Throw the error to be caught by the caller
     throw new Error("An unknown error occurred");
  }
};

export const fetchLinks = async ({ queryKey }: any) => {
  const [unusedArgument,
    { currentPage, fromDate, toDate, searchTerm, searchKey,pageSize }
  ] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/headless/purchase_link/all`,
    {
      params: {
        page: currentPage - 1,
        from: fromDate,
        to: toDate,
        size:pageSize,
        ...(searchTerm && { search: searchTerm }),
        ...(searchKey && { filter: searchKey }),
      }
    }
  )

  if (response?.data) {
    return response?.data;
  } else {
    throw new Error(response?.data?.message || "Failed to get links");
  }
}

export const fetchLinkDetailById = async ({ queryKey }: any) => {
  try{
  const [unusedArgument,
    { id }
  ] = queryKey
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/headless/purchase_link/${id}`
  )
  if (response?.data?.status) {
    return modifyLinkGetData(response.data.data)
  } else {
    throw new Error(response?.data?.message || "Failed to get link details");
  }
}catch (error) {
  console.error("error is",error)
  if (error.response) {
    const { status, data } = error.response;
    if (status >= 400 && status < 500) {
      throw new Error(data?.message||"An unknown error occurred");
    }
  }
  // Throw the error to be caught by the caller
   throw new Error("An unknown error occurred");
}
}
// export const fetchOldRatesDetail = async({queryKey}) => {
//     const[unusedArgument, {prevDateValue,sellerId,paymentGateway,paymentMode}] = queryKey 
//     const response = await axios.get(`${process.env.REACT_APP_FASTRR_FINANCE}/api/vi1/commission/historical/rates?date=${prevDateValue?.format('YYYY-MM-DD')}&seller_id=${sellerId}&payment_gateway=${paymentGateway}&payment_mode=${paymentMode}`,
//     {
//         headers : {
//             "X-AUTH": auth ,
//         }
//     })

//     return response?.data?.result
// }