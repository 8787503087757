import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Upload, message, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { GetAuthToken } from "globalQuries";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getGeneralDetails, updateSellerCheckoutConfig } from "components/Settings/GeneralSettings/script";

const Container = styled.div`
  padding: 20px;
  height:70.4375rem;
  font-family: Arial, sans-serif;
  overflow:"scroll";
`;

const Title = styled.h2`
width: 12.4375rem;
height: 1.125rem;
flex-shrink: 0;
color: #212122;
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem; /* 171.429% */
margin-bottom:20px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
`;

const UploadBox = styled.div`
  width: 7rem;
  height: 6rem;
  flex-shrink: 0;
  border: 2px dashed #d9d9d9;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  color: #6f57e9;
  font-size: 16px;
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items:center;
`;

const Label = styled.label`
color: #212122;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 112.4%; /* 0.843rem */
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;

`;

const ColorInputContainer = styled.div`
  width: 32rem;
  height: 48px;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid #D3D3D3;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
`;

const Input = styled.input`
  outline: none !important;
  border: none !important;
  width: 100%;
  height: 100%;
   &:focus {
      border:none !important;
      outline: none !important;
    };
`;

const ColorPreview = styled.div<{ color: string }>`
width: 1.125rem;
height: 1.125rem;
flex-shrink: 0;
border-radius: 0.22063rem;
  
  background-color: ${(props) => props.color || "#FFFFFF"};
  border: 1px solid #d9d9d9;
`;

const ToggleSwitch = styled.input.attrs({ type: 'checkbox' })`
width: 2.5625rem;
height: 1.1875rem;
flex-shrink: 0;
  background-color: ${(props) => (props.checked ? '#12B87B' : '#A5A5A5')};
  border-radius: 20px;
  position: relative;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 1.7px;
    left: ${(props) => (props.checked ? '23px' : '1.7px')};
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: left 0.3s;
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
`;

const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? "#6F57E9" : "#333")};
  border-bottom: ${(props) => (props.active ? "3px solid #6F57E9" : "none")};
`;

const BannerInfo = styled.p`
  font-size: 12px;
  color: #6f57e9;
  background-color: #f0f0ff;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 15px;

`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  height: 2rem;
  padding: 0.625rem 1rem;
  align-self:flex-end;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #6F57E9;
  color: #FFF;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem; /* 85.714% */
  padding: 10px 20px;
  cursor: pointer;
`;

export type LayoutConfig = {
  theme_color: string;
  logo_url: string;
  bannerEnabled: boolean;
  pre_login: {
    background_color: string;
    text_color: string;
    text: string;
  };
  post_login: {
    background_color: string;
    text_color: string;
    text: string;
  };
  post_loginSame: boolean;
};

const CreateLinkLayoutComponent = ({setIsDrawerOpen}:any) => {
  const { control, handleSubmit, setValue, watch } = useForm<LayoutConfig>({
    defaultValues: {
      theme_color: "",
      logo_url: "",
      bannerEnabled: false,
      pre_login: {
        background_color: "",
        text_color: "",
        text: "",
      },
      post_login: {
        background_color: "",
        text_color: "",
        text: "",
      },
      post_loginSame: false,
    },
  });

  const [activeTab, setActiveTab] = useState<"pre_login" | "post_login">(
    "pre_login"
  );
  const logo_url = watch("logo_url");
  const bannerEnabled = watch("bannerEnabled");
  const post_loginSame = watch("post_loginSame");
  const pre_login = watch("pre_login");
  const post_login = watch("post_login");

  const [savingSettings, setSavingSettings] = useState(false)


  const [loading, setLoading] = useState(false);
  const { data: configData, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  useEffect(() => {
    if (configData) {
      setValue("logo_url", configData?.logo_url)
      setValue("theme_color", configData?.ui_config?.theme_color?.primary)
      setValue("pre_login", { ...pre_login, ...configData?.banner_details?.pre_login })
      setValue("post_login", { ...post_login, ...configData?.banner_details?.post_login })
    }
  }, [configData])

  // Sync post_login with pre_login only when `post_loginSame` is checked
  useEffect(() => {
    if (post_loginSame) {
      setValue("post_login", pre_login);
    }
    if (post_loginSame) {
      setValue("post_login", watch("pre_login"));
    }
  }, [post_loginSame, pre_login, setValue]);

  // useEffect(() => {
  //   if (post_loginSame) {
  //     setValue("post_login", pre_login);
  //   }
  // }, [post_loginSame, pre_login, setValue]);

  // useEffect(() => {
  //   if (post_loginSame) {
  //     setValue("post_login", { ...pre_login });
  //   }
  // }, [post_loginSame, pre_login, setValue]);


  useEffect(() => {
    if (post_loginSame) {
      setValue("post_login", { ...watch("pre_login") });
    }
  }, [post_loginSame, setValue, watch]);

  const handleFileUpload = async (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt300Kb = file.size < 300000;
    if (!isLt300Kb) {
      message.error("Image must smaller than 300 KB!");
      return false;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/upload/img/`,
        formData,
        {
          headers: {
            Authorization: GetAuthToken(),
          },
        }
      );

      if (response.data.success) {
        notification.success({ message: "Uploaded Successfully!" });
        setValue("logo_url", response.data.data.url);
      } else {
        notification.error({ message: response.data.message || "Upload failed!" });
      }
    } catch (error) {
      notification.error({ message: "An error occurred during upload." });
    } finally {
      setLoading(false);
    }

    return false;
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!
      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      setSavingSettings(false)
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      queryClient.invalidateQueries('generalSettings');
      setIsDrawerOpen(false);
      setSavingSettings(false)
      // navigate('/settings/shipping');
    },
  });

  const onSubmit: SubmitHandler<LayoutConfig> = (data) => {
    try {
      setSavingSettings(true)
      mutation.mutate({
        ...(data.theme_color && {
          ui_config: {
          theme_color: {
            primary:data.theme_color
          }
        }}),

        banner_details: {
          pre_login: data.pre_login, 
          post_login: data.post_login
        }
      });
      setIsDrawerOpen(false)
    } catch (e) { }
    finally {
      setIsDrawerOpen(false)
      setSavingSettings(false)
    }
  };

 

  return (
    <Container>
      <Title>Personalised Branding</Title>
      <form 
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} 
        >
        {/* Logo Upload */}
        <UploadContainer>
          <Controller
            name="logo_url"
            control={control}
            render={({ field }) => (
              <Upload
                showUploadList={false}
                beforeUpload={handleFileUpload}
                accept="image/jpeg,image/png"
              >
                <UploadBox>
                  {logo_url ? (
                    <img
                      src={logo_url}
                      alt="Logo"
                      style={{
                        width: "150px",
                        height: "40px",
                        objectFit: "contain",
                      }}
                    />
                  ) : loading ? (
                    <LoadingOutlined />
                  ) : (
                    <div>
                      <PlusOutlined />
                      <div>Upload Logo</div>
                    </div>
                  )}
                </UploadBox>
              </Upload>
            )}
          />
          <p style={{
            width: "14.375rem",
            height: "1.21344rem",
            flexShrink: 0,
            color: "#333",
            textAlign: "left",
            fontSize: "0.625rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}>
            Your logo will be adjusted to fit within a container with a height
            of 40 pixels and a width of 150 pixels.
          </p>
        </UploadContainer>

        {/* Theme Colour */}
        <Label>Theme Colour</Label>
        <ColorInputContainer>
          <Controller
            name="theme_color"
            control={control}
            render={({ field }) => (
              <>
                <Input {...field} placeholder="Enter theme colour" />
                <ColorPreview color={field.value} />
              </>
            )}
          />
        </ColorInputContainer>

        {/* Banner */}
        {/* <Label>Banner</Label>
        <Controller
          name="bannerEnabled"
          control={control}
          render={({ field }) => (
            <ToggleSwitch
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        /> */}

        {(
          <>
            <Tabs>
              <Tab
                active={activeTab === "pre_login"}
                onClick={() => setActiveTab("pre_login")}
              >
                Pre-login banner
              </Tab>
              <Tab
                active={activeTab === "post_login"}
                onClick={() => setActiveTab("post_login")}
              >
                Post-login banner
              </Tab>
            </Tabs>

            {/* Banner Info */}
            
              <BannerInfo>
                {
                  activeTab === "pre_login" 
                  ? 'Banner will be displayed on phone number verification screen'
                  : 'Banner will be displayed on payment screen'
                }
              </BannerInfo>
            

            {/* Background Colour */}
            <Label htmlFor="background_color">Background colour</Label>
            <ColorInputContainer>
              <Controller
                key ={activeTab}
                name={`${activeTab}.background_color` as const}
                control={control}
                render={({ field }) => (
                  <>
                    <Input 
                      {...field} placeholder="Enter background colour" />
                    <ColorPreview color={field.value} />
                  </>
                )}
              />
            </ColorInputContainer>

            <Label>Text Colour</Label>
            <ColorInputContainer>
              <Controller
                key ={activeTab}
                name={`${activeTab}.text_color` as const}
                control={control}
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Enter text colour" />
                    <ColorPreview color={field.value} />
                  </>
                )}
              />
            </ColorInputContainer>

            <Label>Text on Banner</Label>
            <ColorInputContainer>
            <Controller
              key ={activeTab}
              name={`${activeTab}.text` as const}
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Enter text on banner" />
              )}
            />
            </ColorInputContainer>

            <CheckboxContainer>
              <Controller
                name="post_loginSame"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      type="checkbox"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <label style={{ marginLeft: "8px" }}>
                      Keep post-login banner same as pre-login banner
                    </label>
                  </>
                )}
              />
            </CheckboxContainer>
          </>
        )}
        <div style={{display:"flex",width:"100%",flexDirection:"row-reverse",marginTop:"1.5rem"}}>
        <SubmitButton type="submit">Save</SubmitButton>
        </div>
      </form>
    </Container>
  );
};

export default CreateLinkLayoutComponent;