import React, { memo } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { Product, Variant } from '.';
import { TrashIcon } from 'images/icons';
import CustomInputField from 'components/UIElements/CustomInputField';
import withCustomScroll from 'components/UIElements/WithCustomScroll';




const ListContainer = styled.div`
  width: 100%;
  max-width: 800px;
  max-height:300px;
  margin-right: .75rem;
  font-family: Arial, sans-serif;
  overflow-y:auto;
  position:relative;
`;

const ListItem = styled.div`
  display: flex;

  transition: background-color 0.3s;
  width: 40.92838rem;
  height:5rem;
flex-shrink: 0;
margin-bottom:.5rem;
border-radius: 0.25rem;
  border-bottom: 1px solid #f0f0f0;
background: #FEFEFE;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center; /* Align image and text vertically */
  flex: 4;
`;

const ItemImage = styled.img`
width: 2.59363rem;
height: 2.5625rem;
flex-shrink: 0;
border-radius: 0.375rem;
border: 1px solid #EEE;
background: url(<path-to-image>) lightgray 50% / cover no-repeat, url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

const ItemText = styled.span`
margin: .65rem 1rem;
color: #5F5D5D;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 1.5rem; /* 200% */
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center; /* Align labels and inputs vertically */
  justify-content: space-between;
`;

const InputContainer = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; /* Center align the label and input */
  height:5rem;
  margin-left: 12px;
`;

const inputFieldStyle: CSSProp = css`
width: 4.11825rem;
height: 1.875rem;
flex-shrink: 0;
margin-bottom:.2rem;
border-radius: 0.5rem;
border: 1px solid #D3D3D3;
color: #212121;
font-size: 0.6875rem;
font-style: normal;
font-weight: 400;
line-height: 112.4%; /* 0.77275rem */
`;

const labelStyle: CSSProp = css`
width: 3.13494rem;
color: #212122;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: 112.4%; /* 0.7025rem */
`;




const InputLabel = styled.label`
  font-size: 12px;
  color: #555;
  margin-bottom: 4px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px;

  display: flex;
  align-items: center; /* Center the trash can icon vertically */
  justify-content: center;

  &:hover {
    color: #e74c3c;
  }
`;


interface SelectedProductsListProps {
  control: any;
  name: string;
  items:Product[];
}

const SelectedProductsList: React.FC<SelectedProductsListProps> = ({ control, name,items}) => {
  const { replace } = useFieldArray({
    control,
    name, // Ensure this matches the form's field name
    keyName: 'id', // Define a unique key name for each item
  });
 
  

  // Function to remove a specific variant
  const removeVariant = (productId: string, variantId: number) => {
    const updatedFields = items.map((product: Product) => {
      if (product.id !== productId) return product; // Keep other products as is

      return {
        ...product,
        variants: product.variants.filter((variant) => variant.value !== variantId),
      };
    });

    // Filter out products with no variants left
    const nonEmptyProducts = updatedFields.filter(
      (product: Product) => product.variants.length > 0
    );

    // Replace the fields array to trigger a re-render
    replace(nonEmptyProducts);
  };

  const ScrollableContainer=withCustomScroll(ListContainer)
  // Synchronize fields with parent `data` when it change

  return (
    <ScrollableContainer>
    {items.map((product: Product, productIndex: number) =>
      product?.variants?.map((variant: Variant, variantIndex: number) => (
        <ListItem key={`${product.id}-${variant.value}`}>
          {/* Item Image and Name */}
          <ItemInfo>
            <ItemImage src={variant.imgUrl} alt={variant.name} />
            <ItemText>
              {variant.name} (ID: {variant.sku||""})
            </ItemText>
          </ItemInfo>

          {/* Input Fields */}
          <InputGroup>
            <InputContainer>
            <CustomInputField
                  name={`${name}[${productIndex}].variants[${variantIndex}].qty`}
                  label="Qty"
                  placeholder=""
                  type="number"
                  min={1}
                  control={control}
                  rules={{
                    required: 'Quantity is required',
                    validate: (value) => {
                      if (value <= 0) {
                        return 'Invalid value';
                      }
                      if (!Number.isInteger(Number(value))) {
                        return 'Invalid value';
                      }
                      return true; // Validation passed
                    }
                  }}
                  labelStyle={labelStyle}
                  inputStyle={inputFieldStyle}
                  errorStyle={{...labelStyle,color: "#ff4d4f",bottom: "0"}}
                />
            </InputContainer>
            <InputContainer>
            <CustomInputField
                  name={`${name}[${productIndex}].variants[${variantIndex}].price`}
                  label="Price"
                  placeholder="Price"
                  type="number"
                  control={control}
                  disabled={true}
                  rules={{
                    required: 'Price is required',
                    validate: (value) => {
                      const numberValue = Number(value);

                      if (isNaN(numberValue)) {
                        return 'Value must be a number';
                      }
                    
                    
                      // Check if the value is greater than 0
                      if (numberValue <= 0) {
                        return 'Invalid Value';
                      }
                             
                      return true; // Validation passed
                    },
                  }}
                  labelStyle={labelStyle}
                  inputStyle={inputFieldStyle}
                  errorStyle={{...labelStyle,color: "#ff4d4f",bottom:0}}
                />
            </InputContainer>
            <InputContainer>
            {/* <CustomInputField
                  name={`${name}[${productIndex}].variants[${variantIndex}].compareAtPrice`}
                  label="Compare at price"
                  placeholder=""
                  type="number"
                  disabled={true}
                  control={control}
                  rules={{
                    required: 'Price is required',
                    validate: (value) => {
                      const numberValue = Number(value);

                      if (isNaN(numberValue)) {
                        return 'Value must be a number';
                      }
                    
                    
                      // Check if the value is greater than 0
                      if (numberValue <= 0) {
                        return 'Invalid Value';
                      }
                             
                      return true; // Validation passed
                    },
                  }}
                  labelStyle={{...labelStyle,width:"5.7rem"}}
                  inputStyle={inputFieldStyle}
                  errorStyle={{...labelStyle,width:"5.7rem",color: "#ff4d4f",bottom:"0"}}
                /> */}
            </InputContainer>
          </InputGroup>

          {/* Delete Button */}
          <DeleteButton
            onClick={() => removeVariant(product.id, variant.value)}
          >
            {TrashIcon("0.82rem","1rem")}
          </DeleteButton>
        </ListItem>
      ))
    )}
  </ScrollableContainer>
);
};

export default memo(SelectedProductsList);