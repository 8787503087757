import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100vw;
  height:100vw;
  flex-shrink: 0;
  padding: 40px;
  border-radius: 6px 0px 0px 6px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(170, 170, 170, 0.16), 0px 10px 20px 0px rgba(194, 194, 194, 0.16);
`;

const Heading = styled.h1`
    width: 725px;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 8px;
    color: #4A494E;
text-align: center;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 46px; /* 127.778% */
`;

const SubHeading = styled.p`
  color: #4A494E;
text-align: center;
margin:30px 0px;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 125% */
`;

const CardsContainer = styled.div`
  display: flex;
width: 811px;
padding: 20px;
justify-content: center;
align-items: center;
gap: 45px;
`;

const Card = styled.div`
display: flex;
width: 318px;
height: 366px;
padding: 35px;
flex-direction: column;
align-items: flex-start;
gap: 2px;
flex-shrink: 0;
border-radius: 5px;
border: 1px solid #D7D7D7;
/* Shadow Dark */
box-shadow: 0px 30px 60px 0px rgba(25, 55, 102, 0.25);

`;

const CardImage = styled.div`
  margin-bottom: 16px;
`;

const CardHeading = styled.h3`
height: 20px;
align-self: stretch;
color: #707070;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 12px; /* 50% */
  margin: 18px 0px;
`;

const CardDescription = styled.p`
width: 268px;
color: #AEAEAE;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 19px; /* 118.75% */
 margin-bottom: 25px;
`;

const CardButton = styled.button`
display: flex;
height: 40px;
padding: 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #6F57E9;
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
  cursor: pointer;
`;

const LinkSelection = () => {
const navigate=useNavigate()
const setDefaultLinkPreferenceAndNavigate=(linkPreference:string)=>{
    localStorage.setItem("linkPreference",linkPreference)
    navigate("/purchaseLink/create/1")
}

  return (
    <Container>
      <Heading>Choose Your Link Type</Heading>
      <SubHeading>Select a link type for quick buying journey.</SubHeading>
      <CardsContainer>
        <Card>
          <CardImage>
           <img
    src={require("../../../../src/images/productLinkimage.svg").default}
    alt="Product Preview Link"

  />
          </CardImage>
          <CardHeading>Product preview link</CardHeading>
          <CardDescription>
            Quick link to product visuals, descriptions and variant selections
            to make instant purchase decisions
          </CardDescription>
          <CardButton onClick={()=>setDefaultLinkPreferenceAndNavigate("PRODUCT_PAGE")}>Create Preview Link</CardButton>
        </Card>
        <Card>
        <CardImage>
           <img
    src={require("../../../../src/images/cardLinkImage.svg").default}
    alt="Product Preview Link"

  />
          </CardImage>
          <CardHeading>Quick cart link</CardHeading>
          <CardDescription>
            Directs customers straight to the shopping carts to complete
            purchase in just seconds
          </CardDescription>
          <CardButton onClick={()=>setDefaultLinkPreferenceAndNavigate('CHECKOUT_LINK')}>Create Cart Link</CardButton>
        </Card>
      </CardsContainer>
    </Container>
  );
};

export default LinkSelection;