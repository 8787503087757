import React from 'react';
import ProductTableModal from './ProductTableModal';

type SelectProductsModalProps = {
  isModalOpen: boolean; // Use boolean instead of Boolean
  toggleModal: (show: boolean) => void; // Proper type for state setter
  control:any,
  name:string,
  linkType:string,
};

const SelectProductsModal: React.FC<SelectProductsModalProps> = ({
  isModalOpen,
  toggleModal,
  control,
  name,
  linkType,
}) => {
  return (
    <ProductTableModal
      isOpen={isModalOpen}
      onClose={() => toggleModal(false)}
      control={control}
      name={name}
      isProductPreviewLink={linkType==='PRODUCT_PAGE'}

    />
  );
};

export default SelectProductsModal;