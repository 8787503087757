import { CustomSelectTickImage, DownIcon, SearchIcon } from "images/icons";
import React, { useEffect, useRef, useState } from "react";
export const CustomSelectTw = ({ addOnBefore, onSearchKeyChange, searchKey }: any) => {
    const [selectedOption, setSelectedOption] = useState('All');
    const [isOpen, setIsOpen] = useState(false);


    function useOutsideAlerter(ref: any) {
        useEffect(()=>{
            if(searchKey){
               const defaultOption= addOnBefore.find((option:any)=>{
                    return option.value==searchKey
                })
                if(defaultOption?.value){
                    setSelectedOption(defaultOption?.label)
                }
            }
        },[])
        useEffect(() => {
          function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsOpen(false)
            //   setCheckedList(filterKey)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref, searchKey]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    return (
        <div className="relative flex" ref={wrapperRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="h-10 w-44 shadow-sm border rounded-r-md border-l-0 text-searchColor border-outline  bg-white focus:outline-none text-sm "
            >
                <div className="flex justify-between items-center pl-5 pr-3 ">
                    {selectedOption ? selectedOption : "All"}
                    {DownIcon(isOpen)}
                </div>
            </button>
    
            {isOpen && (
                <div className="absolute top-full bg-white border border-outline rounded-md shadow-md w-44 h-fit text-sortByColor z-10">
                    {addOnBefore?.map((option: any) => (
                        <div
                            key={option.value}
                            onClick={() => {
                                onSearchKeyChange(option.value)
                                setSelectedOption(option.label)
                                setIsOpen(false)
                            }}
                            className={`py-2 px-6 cursor-pointer flex items-center justify-between hover:bg-selectHover hover:text-rechargeText`}
                        >
                            <span>{option.label} </span>{searchKey == option.value && <CustomSelectTickImage/>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default CustomSelectTw
