import { TickIcon } from "images/icons";
import React from "react";
import Select, { GroupBase, StylesConfig, components } from "react-select";
import Placeholder from "react-select/dist/declarations/src/components/Placeholder";

// Custom styles for Select component


const CustomSelect = ({
  field,
  options,
  groupedOptions,
  optionStyle,
  onChange,
  isSearchable,
  placeholder,
  style,
  selectContainerStyle,
  defaultValue
}: {
  field?: any;
  options: { label: string; value: string }[];
  groupedOptions?: GroupBase<any>[];

  optionStyle?: React.CSSProperties;
  isSearchable?: boolean;
  onChange?: any;
  placeholder?:string;
  style?:any;
  selectContainerStyle?:any;
  defaultValue?:any;
}) => {
  const customSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
    container: (provided) => ({
      ...provided,
     
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#FFF",
      borderColor:state.isFocused?"#6F57E9":"#D3D3D3",
      borderStyle:"solid",
      borderWidth:"1px",
      boxShadow: "none",
      fontSize: "0.75rem",
      fontWeight: "400",
      "&:hover": {
        borderColor: "#6F57E9",
      },

    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#bfbfbf",
      fontSize: "14px",

    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",

    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#d9d9d9",
      "&:hover": {
        color: "#6F57E9",
      },
      width:"30px",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "rgba(111, 87, 233, 0.15)" : "#FFF",
      color: state.isFocused ? "#6F57E9" : "#000",
      cursor: "pointer",
   
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "10px 0", // Add padding at the top and bottom of the options
      maxHeight: "200px", // Set a max height for scrollable options
      overflowY: "auto", // Enable vertical scrolling if content overflows
      borderRadius: "8px", // Round the edges of the menu list
    }),
  };

  const CustomOption = (props: any) => {
    console.log("CustomOption Props:", props);
    const { data, isSelected, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          color: "#212121",
          fontSize: "0.75rem",
          fontWeight: "400",
          ...optionStyle,
        }}
        className={`flex items-center justify-between py-2 px-4 cursor-pointer hover:bg-selectHover hover:text-purpleText`}
      >
        <span style={{ flexGrow: 1 }}>{data.label}</span>
        {isSelected && <div>{TickIcon("#6F57E9")}</div>}
      </div>
    );
  };

  console.log("Field:", field);
  console.log("Options:", options);

  return (
    <div style={selectContainerStyle||{"display":"flex"}}>
    <Select
      {...field}
      isSearchable={isSearchable}
      options={groupedOptions || options}
      components={{ Option: CustomOption }}
      placeholder={placeholder}
      styles={style||customSelectStyles}
      value={options.find((option) => option.value === (field?.value || defaultValue)) || null} 
      onChange={(selectedOption: any) => {
        console.log("Selected Option:", selectedOption);
        if (field?.onChange) field.onChange(selectedOption?.value);
        if (onChange) onChange(selectedOption);
      }}
    />
    </div>
  );
};

export default CustomSelect;