import React from "react";
import styled, { css, CSSProp } from "styled-components";
import { useFieldArray, Controller } from "react-hook-form";
import { Product, Variant } from ".";
import { TrashIcon } from "images/icons";
import CustomInputField from "components/UIElements/CustomInputField";
import withCustomScroll from "components/UIElements/WithCustomScroll";



interface ProductSelectionProps {
  control: any;
  name: string;
  items: Product[];
  linkType?:string
}

const inputFieldStyle: CSSProp = css`
max-width: 6rem;
width: 100%;
height: 1.875rem;
flex-shrink: 0;
margin-bottom:.2rem;
border-radius: 0.5rem;
border: 1px solid #D3D3D3;
color: #212121;
font-size: 0.6875rem;
font-style: normal;
font-weight: 400;
line-height: 112.4%; /* 0.77275rem */
text-align:center;
`;

const labelStyle: CSSProp = css`
width: 3.13494rem;
color: #212122;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: 112.4%; /* 0.7025rem */
`;


const Container = withCustomScroll(styled.div`
width:100%;
border-radius: 6px 0px 0px 6px;
background: #FFF;
  padding: 20px;
  background-color: #fff;
`)

const ProductDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0px;
`;

const ProductImage = styled.img`
width: 2.59363rem;
height: 2.5625rem;
flex-shrink: 0;
border-radius: 0.375rem;
border: 1px solid #EEE;
background: url(<path-to-image>) lightgray 50% / cover no-repeat, url(<path-to-image>) lightgray 50% / cover no-repeat;
  margin-right: 10px;
`;

const ProductName = styled.div`
  flex-grow: 1;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 16px;
`;

const PriceLabel = styled.label`
  margin-right: 10px;
`;

const PriceInput = styled.input`
  width: 80px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
`;

const TrashButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #e74c3c;
  font-size: 20px;

  &:hover {
    color: #c0392b;
  }
`;

const CheckboxContainer = styled.div`
  margin-bottom: 10px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
  accent-color: #6F57E9;
`;

const TextInput = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 20px;
  resize: none;
`;
const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 16px;

  display: flex;
  align-items: center; /* Center the trash can icon vertically */
  justify-content: center;

  &:hover {
    color: #e74c3c;
  }
`;


const InputLabel = styled.label`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 4px;
  padding-left:.3rem;
  color: #212122;
`;

const ProductSelection: React.FC<ProductSelectionProps> = ({ control, name, items, linkType }) => {

  console.log("item are", items)
  const { replace } = useFieldArray({
    control,
    name,
    keyName: 'productId', // Define a unique key name for each item
  });

  const removeVariant = (productId: string, variantId: number) => {
    const updatedFields = items.map((product: Product) => {
      if (product.id !== productId) return product; // Keep other products as is

      return {
        ...product,
        variants: product.variants.filter((variant) => variant.value !== variantId),
      };
    });

    // Filter out products with no variants left
    const nonEmptyProducts = updatedFields.filter(
      (product: Product) => product.variants.length > 0
    );

    // Replace the fields array to trigger a re-render
    replace(nonEmptyProducts);
  };

  return <Container>
    {
      items.map((product: Product, productIndex) => <>
        {
          product.variants.map((variant: Variant, variantIndex) => <div key={variantIndex}>
            <ProductDetails key={variantIndex}>
              <ProductImage src={variant.imgUrl} alt="Product" />
              <ProductName>
                {variant.label} (ID: {variant.value})
              </ProductName>
              <PriceContainer>
                <CustomInputField
                  name={`selectedProducts[${productIndex}].variants[${variantIndex}].price`}
                  value={product.variants[variantIndex].price}
                  label="Price"
                  placeholder="Price"
                  disabled={true}
                  type="number"
                  control={control}
                  rules={{
                    required: 'Price is required',
                    validate: (value) => {
                      const numberValue = Number(value);

                      if (isNaN(numberValue)) {
                        return 'Value must be a number';
                      }


                      // Check if the value is greater than 0
                      if (numberValue <= 0) {
                        return 'Invalid Value';
                      }

                      return true; // Validation passed
                    },
                  }}
                  labelStyle={labelStyle}
                  inputStyle={inputFieldStyle}
                  errorStyle={{ ...labelStyle, color: "#ff4d4f", bottom: 0 }}
                />

                {/* <CustomInputField
                  name={`selectedProducts[${productIndex}].variants[${variantIndex}].compareAtPrice`}
                  label="Compare at price"
                  value={product.variants[variantIndex].compareAtPrice}
                  disabled={true}
                  placeholder=""
                  type="number"
                  control={control}
                  rules={{
                    required: 'Price is required',
                    validate: (value) => {
                      const numberValue = Number(value);

                      if (isNaN(numberValue)) {
                        return 'Value must be a number';
                      }


                      // Check if the value is greater than 0
                      if (numberValue <= 0) {
                        return 'Invalid Value';
                      }

                      return true; // Validation passed
                    },
                  }}
                  labelStyle={{ ...labelStyle, width: "6.3rem" }}
                  inputStyle={inputFieldStyle}
                  errorStyle={{ ...labelStyle, width: "5.7rem", color: "#ff4d4f", bottom: "0" }}
                /> */}
                {linkType !== "PRODUCT_PAGE" && 
                <DeleteButton onClick={() => removeVariant(product.id, variant.value)}>   {TrashIcon()}</DeleteButton>
              }


              </PriceContainer>
            </ProductDetails>
            <div style={{
              height: "1px",
              marginBottom: "16px",
              background: 'linear-gradient(0deg, #ECECEC 0%, #ECECEC 100%), linear-gradient(0deg, #ECECEC 0%, #ECECEC 100%), #ECECEC'
            }
            }></div>

          </div>)
        }
        <CheckboxContainer>
          {/* <CheckboxLabel>
            <Controller
              name={`selectedProducts.${productIndex}.showDescription`}
              control={control}
              render={({ field }) => (
                <CheckboxInput {...field} type="checkbox" />
              )}
            />
            Show product description
          </CheckboxLabel> */}

          {/* {product?.showDescription && ( */}
            <InputLabel>Product Description</InputLabel>
            <Controller
              name={`selectedProducts.${productIndex}.productDescription`}
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Enter product description"
                  rows={2}
                />
              )}
            />
          {/* )} */}
        </CheckboxContainer>
      </>)
    }
  </Container>


};

export default ProductSelection;