import { Button, Select } from "antd";
import styled from "styled-components";

export const NewContainer = styled.div`
  .ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical {
    background: #fff;
  }
  .ant-menu-item a,
  .ant-menu-item a:hover {
    color: #263f97;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 1px solid rgba(0, 11, 52, 0.1);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover a {
    color: #0c3784 !important;
    font-weight: 700;
    background-color: #ffff;
  }
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active {
    color: #0c3784 !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    font-weight: 700;
    color: #263f97;
    margin: auto;
    background-color: #ffff;

    .ant-menu-title-content {
      border-bottom: 4px solid #0c64db;
    }
  }
  li {
    padding-left: 2px !important;
    span {
      font-size: 12px;
      color: #0c3784;
    }
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected svg {
    color: #263f97;
    fill: #263f97;
  }
  .ant-menu-dark .ant-menu-item > span > a {
    color: #000 !important;
  }
  .menu-icon {
    color: #263f97;
  }
  .ant-menu-root.ant-menu-inline {
    background: #fff;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fff;
  }
  .ant-menu-item a {
    color: #0c3784;
  }

  a {
    color: #263f97;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  .menu-left-alignment {
    padding-left: 48px;
  }
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin: 0px;
  }
  .ant-menu-inline .ant-menu-item {
    margin: 0px;
  }
  @media only screen and (max-width: 968px) {
    .responsive_settings {
      margin-top: 24px;
      margin-left: 16px;
    }
  }
`;

export const CustomerCriteriaContainer = styled.div<{ width: string }>`
  .customer_radio {
    .ant-radio-wrapper {
      width: ${(props) => (props.width === "95" ? "95%" : "45%")};
      margin-bottom: 5px;
    }
  }
`;

export const ProductCriteriaContainer = styled.div`
  .ant-input {
    width: 100%;
    border-radius: 5px;
  }
`;
export const CriteriaContainer = styled.div`
  color: #38446d;

  .ant-input {
    border-radius: 5px;
  }

  .mt-20 {
    margin-top: 20px;
  }
  .mt-35 {
    margin-top: 35px;
  }
`;
export const HeadingText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
`;
export const MessageText = styled.div`
  font-size: 14px;
  color: #0c64db;
  line-height: 22px;
  margin-bottom: 6px;
`;
export const FastrrButton = styled(Button)`
  background: rgba(12, 100, 219, 0.1);
  border-radius: 10px;
  border: none;
  width: 125px;
  height: 35px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #0c64db;
`;
export const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0c64db;
  cursor: pointer;
`;
export const InfoText = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
  margin-bottom: 40px;
  color: #0c64db;
`;
export const CustomSelect = styled(Select)<{ height: string }>`
  width: 100%;

  .ant-select-selector {
    border-radius: 5px !important;
    height: ${(props) =>
      props.height === "small" ? "30px !important" : "38px !important"};
  }
  .ant-select-selection-placeholder {
    color: #0c3784;
  }
  .ant-select-item-option-content {
    color: #38446d;
  }
`;
export const CriteriaContent = styled.div`
  padding: 10px 0px 0px 20px;
`;

export const SwitchText = styled.span`
  font-style: normal;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #292a2c;
`;