import { toast } from 'react-toastify';
import { downloadFile } from 'components/UIElements/function';
import axiosInstance from '../../HTTP/Http';
import { ToastErrorCross, ToastSuccessTick } from 'images/icons';

export const CheckoutStageMapping = {
  cart: 'Cart',
  otp_requested: 'OTP Requested',
  otp_verified: 'OTP Verified',
  address: 'Address',
  shipping_calculated: 'Shipping Calculated',
  payment_initiated: 'Payment Initiated',
  payment: 'Payment',
  order_placed: 'Order Placed',
  address_selected: 'Address Selected',
  order_screen:'Order Screen'
};

export const fetchAbandonedData = async ({ queryKey }) => {
  const [_, { pageNumber, fromDate, toDate, searchTerm, searchKey, checkoutFilter, source,loggedInFromAdmin,headless,linkId}] = queryKey;
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/abandon-carts`,
    {
      params: {
        page: pageNumber - 1,
        from: fromDate, 
        to: toDate,
        source: source,
        // size: 10,
        ...(searchTerm && {key: searchKey}),
        ...(searchTerm && {value: searchTerm}),
        ...(checkoutFilter?.length && {key: 'latestStage'}), 
        ...(checkoutFilter?.length && {value: checkoutFilter.join(',')}),
        ...(headless&&{isHeadless:true}),
        ...(linkId&&{link_id:linkId}),
      }
    }
  );
  return response.data.data;
};

export const downloadAbdCsvData = async (fromDate, toDate, searchTerm, searchKey, checkoutFilter, source,loggedInFromAdmin,setIsDataDownloading,isHeadless,linkId) => {
  try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=ACART`,
      {
        params:{
          from: fromDate,
          to: toDate,
          source: source,
          ...(searchTerm && {key: searchKey}),
          ...(searchTerm && {value: searchTerm}),
          ...(checkoutFilter?.length && {key: 'latestStage'}), 
          ...(checkoutFilter?.length && {value: checkoutFilter.join(',')}),
          ...(isHeadless&&{isHeadless:isHeadless}),
          ...(linkId&&{link_id:linkId})

        }
      }
    );
    setIsDataDownloading(false)  
    downloadFile(response.data, 'abandoned.csv');
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  }catch(error){
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }
  
};


export const handleSingleDownload = async (rowId, fromDate, toDate,loggedInFromAdmin) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=cartId&type=ACART`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: rowId  
          }
      }
  )
  downloadFile(response.data, 'abandoned.csv')
}


export const handleMultipleDownload = async  (fromDate, toDate, keys,loggedInFromAdmin,selectedRowKeys,setIsDataDownloading ,isHeadless=false,linkId=null,source=null) => {
  try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=cartId&type=ACART`,
        {
            params: {
                from: fromDate,
                to: toDate,
                value: keys,
                ...(source&&{source: source}),
                ...(isHeadless&&{isHeadless:isHeadless}),
                ...(linkId&&{link_id:linkId}),
            }
        }
    )
    downloadFile(response.data, 'abandoned.csv')
    setIsDataDownloading(false)
    toast.success(`Download successful! ${selectedRowKeys.length} records downloaded`, {
      icon: ToastSuccessTick
    });
  }catch(error){
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }
  
}