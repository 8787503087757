// @ts-nocheck
import React, { useState, useEffect, useContext, useRef, ChangeEvent } from 'react';
import { Tabs, DatePicker, Row, Col, Empty, Drawer, Checkbox, Button, Select } from 'antd';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip'


import { useQuery } from 'react-query';
import { DataContext } from 'context/DataProvider';
import { render } from '@testing-library/react';
import DrawerView from './DetailsDrawer';
import { FlexRow } from 'components/Settings/Discount/styled';
import CustomSelectTw from 'UiComponents/Select/dropdown';
import { SortByCard, ToolTipCard } from 'UiComponents/Cards';
import { CustomReactInput } from 'UiComponents/Select';
import CustomReactTable from 'UiComponents/Table';
import { CustomButton, CustomDownloadButton, FlexRowTw, TextStyle } from 'pages/Orders/OrdersTab/styles';
import { CopyIcon, DownIconTable, ExportIcon, FilterIcon, ToastInfoIcon, UpDownIcon } from 'images/icons';
import { Ndata } from 'UiComponents/SideBar/data';
import Filter from 'UiComponents/CustomFilter/Filter';

import "../../../../src/pages/Loader.css"
import { toast } from 'react-toastify';
import { CustomDatePicker } from 'components/UIElements';
import { CheckoutStageMapping, downloadAbdCsvData, fetchAbandonedData, handleMultipleDownload} from 'pages/AbandonedCart/script';
import FilterNew from 'pages/AbandonedCart/FilterNew';
import WithDownloadButton from 'HOC/WithCustomDownloadButton';
import styled from 'styled-components';

import { useNavigate } from "react-router-dom";
import WithCustomDatePicker from 'HOC/WithCustomDatePicker';


const TabContainer = styled.div`
  display: flex;
  gap: 40px;
  border-bottom: 2px solid #ddd;
  margin: 20px 0px;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  font-size: 16px;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) => (props.isActive ? "#6f57e9" : "#555")};
  border-bottom: ${(props) =>
    props.isActive ? "3px solid #6f57e9" : "3px solid transparent"};
  padding: 10px 0;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #6f57e9;
  }
`;

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text); toast.info('Copied to Clipboard', {
    icon: ToastInfoIcon, position: "top-center",
    style: { width: 'fit-content' }
  })
}

const AbandonedCartNew = ({ activeTab, setActiveTab ,linkId }: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [defaultSortBy, setDefaultSortBy] = useState([{ id: '', desc: false }]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [displayData, setDisplayData] = useState<any>();

  const [abandonCartStats, setAbandonCartStats] = useState<any[]>([]);
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [drawerData, setDrawerData] = useState<any>({})
  const [drawerKey, setDrawerKey] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [searchKey, setSearchKey] = useState<string>('cartId');
  const [prevPageNumber, setPrevPageNumber] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checkoutFilter, setCheckoutFilter] = useState<any[]>([])
  const [source, setSource] = useState<string>('fastrr')
  const [isViewCartDetails, setIsViewCartDetails] = useState<boolean>(false);
  const [viewMoreState, setViewMoreState] = useState<any>()
  const [displayFilter, setDisplayFilter] = useState<boolean>(false)
  const [displaySort, setDisplaySort] = useState<boolean>(false)
  // const filterKeys = ['Address Selected', 'Shipping Selected', 'Payment Initiated']
  const filterKeys = ['Payment Initiated']
  const loggedInFromAdmin = localStorage.getItem("loggedInFromAdmin")
  const [filter, setFilter] = useState<any>([])
  const [inputValue, setInputValue] = useState<any>('')
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)
  const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    start: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const mapping: any = {
    //'Address Selected': 'ADDRESS',
    //'Shipping Selected': 'SHIPPING_CALCULATED',
    'Payment Initiated': 'PAYMENT_INITIATED',
    'Order Screen': 'ORDER_SCREEN'
  }




  const {
    data: abandonedData,
    isLoading,
    isSuccess,
  } = useQuery<any>(
    ['abandonedCart', { pageNumber, fromDate: moment(selectedDateRange.start).format("YYYY-MM-DD"), toDate: moment(selectedDateRange.end).format("YYYY-MM-DD"), searchTerm, ...(searchTerm && { searchKey }), checkoutFilter, source, loggedInFromAdmin ,headless:true,linkId}],
    fetchAbandonedData
  );
  const disabledDates = (current: any) => {
    return current > moment()
  }

  useEffect(() => {
    setDisplayData(abandonedData?.abandonCarts)
    // console.log(abandonedData, 'this is abandoned data')
    if (abandonedData?.abandonCartStats) {
      let keyList = Object.keys(abandonedData?.abandonCartStats);
      setAbandonCartStats(
        keyList
          .map((el) => {
            return {
              key: camelCaseToString(el),
              value: abandonedData?.abandonCartStats[el],
            };
          })
          .reverse()
      );
    }
  }, [abandonedData]);


  useEffect(() => {
    setPageNumber(1);
    setSelectedRowKeys([]);
    setCheckoutFilter([])
  }, [selectedDateRange.start, selectedDateRange.end]);

  const onSelectAll = (pageData: any) => {
    setSelectedRowKeys((keys) => keys.length === pageData?.length ? [] : [...pageData?.map((key: any) => {
      return key?.original?.cart
    })])
    setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
  }

  const camelCaseToString = (text: any) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  // const copyUrlcopyUrl = (cartId, data) => {
  //     setIsCopy(true)
  //     setCartId(cartId)
  //     setTimeout(() => {
  //         setIsCopy(false)
  //         setCartId('')
  //     }, 3000)
  //     navigator.clipboard.writeText(data)
  // }


  const viewMoreHandler = (result: any, cartView: any) => {
    setDrawerVisible(true)
    setDrawerData(result)
    setDrawerKey(Math.random())
    setIsViewCartDetails(cartView)
  }

  function filterByLatestStage(data, filterKey) {
    return data.filter(obj => obj.latestStage === filterKey);
  }
  const navigate = useNavigate(); // Use navigate instead of history
  const TagsValue = ['Cart Id', 'Date & Time', 'Cart Details', 'Cart Value', 'Communication Sent', 'Last Notified']
  const [sortValue, setSortValue] = useState<any>()

  const Filterdata: Ndata = {
    Source: [
      {
        key: 1,
        name: 'Fastrr',
        count: 20
      },
      {
        key: 2,
        name: 'Online - website',
        count: 1
      }
    ],
    "Order Status": [
      {
        key: 3,
        name: 'Open',
        count: 20
      },
      {
        key: 4,
        name: 'Archived',
        count: 20
      },
      {
        key: 5,
        name: 'Cancelled',
        count: 20
      }
    ],
    "Payment Gateway": [
      {
        key: 6,
        name: 'Setu',
        count: 20
      },
      {
        key: 7,
        name: 'Fastrr',
        count: 20
      },
      {
        key: 8,
        name: 'RazorPay',
        count: 20
      }
    ]

  }
  const columns = [
    {
      Header: <span>Cart ID </span>,
      accessor: 'cart',
      disableSortBy: true,
      Cell: ({ cell: { value }, row }: any) => {
        const randomNumber = Math.random()
        return <>
          <div data-tip data-tooltip-id={`${randomNumber}`}>
            {/* <div> */}
            <TextStyle className='hover:text-rechargeText cursor-pointer' onClick={() => window.open(row?.original?.abandonLink)}>{value}</TextStyle>
          </div>
          {/*                     <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='top-start' opacity={1} noArrow clickable={true} style={{ background: "white", borderRadius: '', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
                        <div className="max-w-32 h-5 text-black" onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <div className='hover:cursor-pointer'>View more</div>
                        </div>
                    </ReactTooltip> */}
        </>
      },
      align: 'left',
    },
    { 
      Header: 'Purchase Link',
      disableSortBy: false,
      tooltip: true,
      accessor: (item: any) => {
        return <>
          <TextStyle>
              {item?.purchaseLinkTitle}
          </TextStyle>
        </>
      }
    },
    {
      Header: <span>Date & Time</span>,
      accessor: 'time',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original } }: any) => (
        <TextStyle>
          <div className='w-max'> {moment(original?.timeInitiated).format('Do MMMM , h:mm a')}</div>
        </TextStyle>
      ),
      align: 'left',
    },
    {
      Header: 'Customer Name',
      accessor: 'custPhone',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original, index } }: any) => {
        const randomNumber = Math.random()
        return <>
          <div data-tip data-tooltip-id={`${randomNumber}`} className='flex h-[50px] items-center'>
            <TextStyle>
              {!loggedInFromAdmin ?
                <>
                  <span>
                    {original?.custName || <div className='ml-10'>-</div>}<br />
                  </span>
                </>
                :
                <span className="capitalize">
                  Hidden
                </span>
              }
            </TextStyle>
            <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
              <DownIconTable />
            </div>
          </div>
          <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-end' noArrow offset={0} opacity={1} clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
            <div className="max-w-60 h-fit text-clear">
              <TextStyle className='flex items-center'>{original?.custEmail}<span onClick={() => copyToClipboard(original?.custEmail || "")} className="cursor-pointer ml-1"><CopyIcon /></span></TextStyle>
              <TextStyle className='flex items-center'>{value ? value : ""}{value ? <span onClick={() => copyToClipboard(original?.value || "")} className="cursor-pointer ml-1"><CopyIcon /></span> : null}</TextStyle>
              <TextStyle className='max-w-48 text-wrap'>{original?.address}</TextStyle>
            </div>
          </ReactTooltip>

        </>
      },
      align: 'left',
    },
    {
      Header: <span>Cart Details</span>,
      accessor: 'items',
      disableSortBy: true,
      Cell: ({ cell: { value, index }, row }: any) => {
        const randomNumber = Math.random()
        const status = row?.original?.orderDetails?.orderFulfillmentStatus
        return <>
          <div data-tip data-tooltip-id={`${randomNumber}`} className="flex h-[50px] items-center">
            <TextStyle>
              <div className='w-max'>
                {row?.original?.items && `${row?.original?.items?.length} item${row?.original?.items?.length > 1 && 's'}`}<br />
              </div>
            </TextStyle>
            <div style={{ visibility: row.index === hoveredRowIndex ? 'visible' : "hidden" }}>
              <DownIconTable />
            </div>
          </div>
          <ReactTooltip id={`${randomNumber}`} place='bottom-start' border={'1px solid #EAEAEA'} opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

            <div className="h-fit text-clear max-w-44" onClick={(e) => {
              e.stopPropagation()
            }}>
              {/* <div>{row?.original?.address}</div> */}
              <div>{row?.original?.items?.map((item: any) => (
                <>
                  <div className='text-itemsColor font-bold'>{item?.name}  x{item?.qty}</div>
                </>)
              )}
              </div>
              <div>Subtotal: ₹{row?.original?.subTotal}</div>
              <div>Shipping Charges : ₹{row?.original?.shippingCharges}</div>
              <div>Coupon Discount : ₹{row?.original?.couponDiscount}</div>
              <div className='text-primary'>Total Amount : ₹{row?.original?.amount}</div>

              <div></div>

            </div>
          </ReactTooltip>
        </>
      },
      align: 'left',
    },
    {
      Header: <span>Cart Value</span>,
      accessor: 'amount',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original } }: any) => (
        <span className='w-max cursor-pointer' onClick={() => { viewMoreHandler(original, true) }}>
          <TextStyle>
            ₹ {value?.toFixed(2)}
          </TextStyle>
        </span>

      ),
      align: 'left',
    },
    {
      Header: <span>
        <span className='flex items-center relative'>
          Checkout Stage <span onClick={() => { setFilterOpen(!filterOpen) }}> <FilterIcon /></span>
          {filterOpen && <div className='absolute' style={{ top: "35px", left: '-10px' }}><FilterNew filters={filterKeys} filterKey={checkoutFilter} setFilterKey={setCheckoutFilter} multipleSelect={true} setIsOpen={setFilterOpen} setPageNumber={setPageNumber} mapping={mapping} /></div>}
        </span>
      </span>,
      accessor: 'latestStage',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <span style={{ textTransform: 'capitalize' }}>
          <TextStyle type={value?.toLowerCase()}>{CheckoutStageMapping[value?.toLowerCase()]}</TextStyle>
        </span>
      ),
      align: 'left',
    },
    {
      Header: 'Last Notified',
      accessor: 'whatsappCount',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original } }: any) => (
        <span style={{ textTransform: 'capitalize' }}>
          {original?.lastNotified || <div className='ml-8'>-</div>}
        </span>
      ),
      align: 'left',
    },
    {
      Header: <span>Channel</span>,
      accessor: 'channel',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <span style={{ textTransform: 'capitalize', fontSize: '14px', textAlign: 'left' }}>
          {value ? value : 'N/A'}
        </span>
      ),
      align: 'left',
    },
  ];

  const onSearchKeyChange = (value: any) => {
    setSearchKey(value)
  }

  const onSourceKeyChange = (value: any) => {
    setSource(value)
    setPageNumber(1)
  }

  const onSearch = (value: any) => {
    setSearchTerm(value)
    setPageNumber(1)
  }

  const customAddOnBefore = [
    { value: 'cartId', label: 'Cart ID' },
    { value: 'custName', label: 'Customer Name' },
    { value: 'custPhone', label: 'Customer Phone' },
    { value: 'custEmail', label: 'Email' }
  ];

  const addOnBefore = [
    { value: 'all', label: 'All' },
    { value: 'fastrr', label: 'Fastrr' },
    { value: 'online-store', label: 'Online Store' },
    { value: 'others', label: 'Others' }
  ];

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      onSearch('')
      setPageNumber(prevPageNumber)
    }
  }

  const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!searchTerm) {
      setPrevPageNumber(pageNumber)
    }
    if (event.target.value) {
      onSearch(event.target.value)
    }
  }
  const isChecked = (key: any, row: any) => {
    return key === row?.cart
  }

   const handleDownload = () => {
      selectedRows?.length ?
              handleMultipleDownload(selectedDateRange.start?.format('YYYY-MM-DD'), selectedDateRange.end?.format('YYYY-MM-DD'), selectedRowKeys.join(','), loggedInFromAdmin, selectedRowKeys, setIsDataDownloading,true,linkId) :
                                               downloadAbdCsvData(selectedDateRange.start?.format('YYYY-MM-DD'), selectedDateRange.end?.format('YYYY-MM-DD'), searchTerm, searchKey, checkoutFilter, source, loggedInFromAdmin, setIsDataDownloading,true,linkId)
    }

  const handleRowSelect = (row: any) => {
    const rowIndex = selectedRows.findIndex((r: any) => {
      return r?.original?.cart === row?.original?.cart
    });
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, row]);
      setSelectedRowKeys([...selectedRowKeys, row?.original?.cart])
    }
    else {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.splice(rowIndex, 1);
      setSelectedRows(updatedSelectedRows);
      setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != row?.original?.cart)]);
    }
  };


  return (
    <div className="flex flex-col gap-4">
        <WithCustomDatePicker containerStyle={{
              display: "inline-block",
              position: "absolute",
              right: "9rem",
              top: "1rem",
              marginRight:"2rem",
        }} selectedDateRange={[selectedDateRange.start,selectedDateRange.end]} setSelectedDateRange={(dateRange:any)=>{
                setSelectedDateRange({start:dateRange[0],end:dateRange[1]})
                }}>
          <WithDownloadButton downloadButtonContainerStyle={{
            position: "absolute",
            right: "0",
            top: "1rem",
            marginRight:"1rem",
          }} DownloadButtonComponent={(props) => <CustomDownloadButton {...props}></CustomDownloadButton>} isDataDownloading={isDataDownloading} setIsDataDownloading={setIsDataDownloading} handleDownload={handleDownload} downloadLabel={selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}>
      <div className='rounded-md bg-white h-auto rounded-lg p-5'>
        <FlexRowTw className='flex justify-between'>
          <FlexRowTw>
            <CustomReactInput addOnBefore={customAddOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={'Cart ID'} inputValue={inputValue} setSearchTerm={setSearchTerm} />
          </FlexRowTw>
          {/* <div className='flex gap-2'> */}
          {/* <CustomButton className='pl-2 w-20' onClick={() => { setDisplayFilter(!displayFilter) }}><div className='flex'><FilterIcon /><span>Filter</span></div></CustomButton> */}
          {/* {displayFilter && <div className='absolute w-96 top-96 right-4 rounded-lg'><Filter filterData={Filterdata} setFilter={setFilter} setDisplayFilter={setDisplayFilter} /></div>} */}
          {/* <CustomButton className="pl-2 w-20" onClick={() => { setDisplaySort(!displaySort) }}><div className='flex pl-2'><div className='mr-2 mt-1'><UpDownIcon /></div><span>Sort</span></div></CustomButton> */}
          {/* {displaySort && <div className='absolute top-96 right-6'>
                                    <SortByCard TagsValue={TagsValue} setDisplaySort={setDisplaySort} setSortValue={setSortValue} sortValue={sortValue} setDefaultSortBy={setDefaultSortBy} defaultSortBy={defaultSortBy} />
                                </div>} */}
          {/* </div> */}
        </FlexRowTw>
        {/* {selectedRowKeys?.length > 0 &&
                            <div className="flex items-center gap-3 font-bold text-idColor mt-4 h-10 w-max ml-8">
                                <span>
                                    {selectedRowKeys.length} selected
                                </span>


                                <CustomButton onClick={() => {
                                    selectedRows?.length ?
                                        handleMultipleDownload(fromDate, toDate, selectedRowKeys.join(','), loggedInFromAdmin) :
                                        downloadAbdCsvData(fromDate, toDate, searchTerm, searchKey, checkoutFilter, source, loggedInFromAdmin)
                                }} className='pl-4'>
                                    <ExportIcon />
                                    Export
                                </CustomButton>
                            </div>
                        } */}
       <div style={{marginLeft:"2px"}}>
            <TabContainer>
              <Tab
                isActive={activeTab === "Orders"}
                onClick={() => {
                  setActiveTab("Orders");
                  navigate(linkId?`/purchaseLink/orders?linkId=${linkId}`:`/purchaseLink/orders`)
                }}
              >
                Orders
              </Tab>
              <Tab
                isActive={activeTab === "Abandoned Cart"}
                onClick={() => {
                  console.log('link id is',linkId)
                  setActiveTab("Abandoned Cart");
                  navigate(linkId?`/purchaseLink/abandonedCart?linkId=${linkId}`:`/purchaseLink/abandonedCart`); // Use navigate here
                }}
              >
                Abandoned Cart
              </Tab>
            </TabContainer>
           
              <CustomReactTable
                loading={isLoading}
                data={displayData || []}
                pageNumber={pageNumber}
                columns={columns}
                setPageNumber={setPageNumber}
                totalPages={abandonedData?.total}
                checkboxes={false}
                onSelectAll={onSelectAll}
                pageSize={20}
                selectedRowsKeys={selectedRowKeys}
                defaultSortBy={defaultSortBy}
                newState={viewMoreState}
                handleRowSelect={handleRowSelect}
                isChecked={isChecked}
                setHoveredRowIndex={setHoveredRowIndex}

              />
            </div>
    
      </div>
      </WithDownloadButton>
      </WithCustomDatePicker>

    </div>
  );
};

export default AbandonedCartNew;

